import React, { Component } from 'react';

import {
  Modal,
  Button,
  Header,
  Icon,
} from 'semantic-ui-react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import { TUTORIALS } from '@/constants/tutorials';

const INITIAL_STATE = {
  open: false,
  activeSlide: 0,
};

class TutorialModal extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  updateHasSeenTutorial = () => {
    const {
      db,
      tutorialName,
    } = this.props;

    if (db && db.settings && db.settings.tutorials) {
      this.props.getUser().collection("settings").doc("tutorials").update({ [tutorialName]: new Date() });
    } else {
      this.props.getUser().collection("settings").doc("tutorials").set({ [tutorialName]: new Date() });
    }
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  closeGuide = () => {
    const {
      db,
      tutorialName,
    } = this.props;

    let hasSeenTutorialBefore = db && tutorialName &&
      db.settings &&
      db.settings.tutorials &&
      db.settings.tutorials[tutorialName] &&
      db.settings.tutorials[tutorialName];

    if (!hasSeenTutorialBefore) {
      this.updateHasSeenTutorial();
    };

    this.setState({
      open: false,
      activeSlide: 0,
    });
  };

  getSliderCard = (page, idx) => (
    <div
      key={'card_' + idx}
      style={{
        padding: "2em",
        color: "black",
      }}
    >
      {page.image &&
        <img
          alt=""
          style={{
            objectFit: "contain",
            width: "50vw",
            maxHeight: "45vh",
            marginBottom: "2em",
            marginRight: "auto",
            marginLeft: "auto",
          }}
          src={page.image}
        />
      }

      <Header
        as='h1'
        style={{
          fontWeight: "500",
          fontSize: "230%",          
          textAlign: "center",
        }}
      >
        {page.header}
      </Header>

      {page.text && Array.isArray(page.text) && page.text.map((x) => (
        <p
          key={'tutorialParagraph_' + x}
          style={{
            color: "black",
            marginTop: "1em",
            marginLeft: "1.5em",
            marginRight: "1.5em",
            whiteSpace: "normal",
            fontSize: "130%",
            lineHeight: "130%",
            textAlign: "center"
          }}
        >
          {x}
        </p>
      ))}
        {page.useChild && this.props.children}
    </div>
  );

  prevSlide = () => {
    this.slider.slickPrev();
  };

  nextSlide = () => {
    const {
      tutorialName,
    } = this.props;

    const {
      activeSlide
    } = this.state;

    let cards = TUTORIALS.filter((x) => x.name === tutorialName);
    cards = cards && cards[0] && cards[0].cards;

    if (activeSlide === (cards.length - 1)) {
      this.closeGuide()
    } else {
      this.slider.slickNext()
    }
  }

  onClickContinue = (page) => {
    if (page === 5) {
      this.props.onClickDiscardWelcome();
    } else {
      this.slider.slickNext();
    };
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      afterChange: current => this.setState({ activeSlide: current })
    };

    const {
      open,
      activeSlide,
    } = this.state;

    const {
      db,
      tutorialName,
      trigger,
      hidden,
    } = this.props;

    if (hidden) {
      return <div />
    }

    let hasSeenTutorialBefore = db &&
      db.settings &&
      db.settings.tutorials &&
      db.settings.tutorials[tutorialName] &&
      db.settings.tutorials[tutorialName];

    let cards = TUTORIALS.filter((x) => x.name === tutorialName);
    cards = cards && cards[0] && cards[0].cards;

    let hasLoadedData = db && db.settings && db.settings.tutorials && db.settings.tutorials.show_tutorials === true;

    if (!hasLoadedData || !cards) {
      return <div></div>
    };

    return (
      <React.Fragment>
        {trigger && React.cloneElement(trigger, { onClick: this.toggleOpen })}

        <Modal
          basic
          open={cards && (open || !hasSeenTutorialBefore)}
          dimmer="blurring"
          style={{
            position: 'absolute',
            top: '1em',
            bottom: '1em',
            left: '1em',
            right: '1em',
            height: 'auto',
            width: 'auto',
            backgroundColor: '#ffffff',
            zIndex: 9,
          }}
        >
          <Modal.Content
            style={{ height: "100%" }}
          >
            <div
              style={{
                marginTop: "2em"
              }}
            >
              <Slider ref={c => (this.slider = c)} className="welcome-slider" {...settings}>
                {cards && cards.map((x, idx) => this.getSliderCard(x, idx))}
              </Slider>
            </div>

            <div
              style={{
                position: "absolute",
                bottom: 25,
                marginLeft: 30,
                marginRight: 30,
                width: "calc(100% - 60px)",
                zIndex: 99,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{
                    marginLeft: -25,
                    backgroundColor: activeSlide === 0 ? "transparent" : "#868D8E",
                    color: activeSlide === 0 ? "transparent" : "white",
                  }}
                  disabled={activeSlide === 0}
                  onClick={() => this.prevSlide()}
                  size="small"
                >
                  Tillbaka
                </Button>

                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {cards && cards.map((x, idx) =>
                    <Icon
                      key={"navigationDot_" + idx}
                      style={{
                        marginTop: "2em",
                        color: (activeSlide === idx) ? "#000000" : "#D6D9D9"
                      }}
                      name="circle"
                      size="tiny"
                    />
                  )}
                </div>

                <Button
                  style={{
                    backgroundColor: "#6B9D9F",
                    color: "white",
                  }}
                  onClick={() => this.nextSlide()}
                  size="small"
                >
                  {(activeSlide === cards.length - 1) ? "Stäng" : "Nästa"}
                </Button>
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  };
};

export default TutorialModal; 
