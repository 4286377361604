import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import {
  firestoreConnect,
} from 'react-redux-firebase';

import { 
  Checkbox,
  Header, 
  Segment,
  Icon,
} from 'semantic-ui-react';

import MenuTop from "@/components/menus/MenuTop";

import {
  MAIN_APP_VERSION,
  BUILD_DATE, 
} from "@/constants/settings";

import "./style.css"

const INITIAL_STATE = {
  handlingMessagingToken: null,
};

const MenuItem = ({ id, title, description, onClick, currentValueHelper, children }) => (
  <Segment
    basic
    style={{
      width: "100%",
      padding: "1.25em",
      marginTop: 5,
      marginBottom: 5,
      backgroundColor: "white",
    }}
    key={`div_${id}`}
    onClick={() => onClick && onClick(id)}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div 
        style={{ 
          width: "80%", 
          marginLeft: 5,  
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Header
          as='h4'
          key={`title_${id}`}
          style={{
            marginBottom: '0em',
            fontWeight: 500,
          }}
        >
          {title}
        </Header>

        <Header
          as='h5'
          color='grey'
          style={{
            marginTop: '0em', 
            marginBottom: '0em', 
            fontWeight: 'normal'
          }}
        >
          {description}
        </Header>       
      </div>

      <div 
        style={{ 
          width: "10%",
          textAlign: "right",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: 5,
          }}
        >
          {!children && onClick && currentValueHelper && <Checkbox toggle checked={currentValueHelper(id)} />}
          {children && children}
      </div>
    </div>
  </Segment>
)

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection('users').doc(this.props.userId);

  onClickSubMenuItem = (id) => {
    let category = id.split("/")[0];
    let value = id.split("/")[1];

    let currentValue = this.getCurrentDbValue(id);

    category && value && this.getUser().collection("settings").doc(category).set({
      [value]: !currentValue,
    }, { merge: true })
  };

  onClickGoBack = () => {
    this.props.history.goBack();
  };

  getCurrentDbValue = (id) => {
    const db = this.getDb();
    let settings = db && db.settings;    

    let category = id.split("/")[0];
    let value = id.split("/")[1];

    let newValue = settings && category && value && settings[category] && settings[category][value];

    return typeof(newValue) === "boolean" ? newValue : true;
  };

  getMessagingToken = () => {
    this.setState({ handlingMessagingToken: "running" });
    
    this.props.firebase &&
    this.props.firebase.messaging &&
    this.props.firebase.messaging().requestPermission()
      .then(async (res) => {
        let newValue = await this.props.firebase.messaging().getToken()

        this.getUser().collection("settings").doc("tokens").set({
          mobile_messaging: this.props.firestore.FieldValue.arrayUnion(newValue),
          latest_update: new Date(),
        }, { merge: true })

        this.setState({ handlingMessagingToken: "done" });
      })
      .catch((err) => {
        console.log("Unable to get permission to notify.", err);
        this.setState({ handlingMessagingToken: "error" });
      });

    navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
  };    

  render() {
    const {
      handlingMessagingToken,
    } = this.state;

    const db = this.getDb();
    let profile = db && db.profile;
    let settings = db && db.settings;

    let isDeveloper = profile && profile.plan && profile.plan.plan_type && profile.plan.plan_type === "developer";

    let weatherStation = settings && settings.weather && settings.weather.name && settings.weather.name;
    let weatherStationDistance = settings && settings.weather && settings.weather.dist && settings.weather.dist.toFixed(1);
    weatherStation = weatherStationDistance ? weatherStation + " som ligger " + weatherStationDistance + " km från din gård." : weatherStation;

    return (
      <Page>
        <div
          style={{
            width: "calc(100vw - 20)",
            marginTop: 70,
            marginLeft: 10,
            marginRight: 10,              
          }}
        >
            
          <MenuTop caption="Inställningar" onClickGoBack={this.onClickGoBack} />

          <Header
            as='h3'
            style={{
              fontWeight: 500,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Kartor
          </Header>

          {/* <MenuItem 
            id="view/marker_scale"
            title="Markörstorlek"
            description="Här kan du skala storleken på markörerna/pluttarna på kartan."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          /> */}

          <MenuItem 
            id="view/show_missing_data"
            title="Visa saknad data"
            description="Vill du att saknad data ska markeras med bleka punkter på kartan? Om denna funktion inte är aktiverad så kommer Freja ta bort punkter utan data från kartan."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />

          <MenuItem 
            id="view/show_areal_map"
            title="Visa flygbild"
            description={'Vill du att en flygbild ska visas på kartan?'}
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />    

          <Header
            as='h3'
            style={{
              fontWeight: 500,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Notiser
          </Header>

          <MenuItem 
            id="notifications/activate"
            title="Tillåt notiser på mobilen"
            description="Om något blivit fel kan du prova att tillåta notiser igen. Notera att notiser för närvarande bara fungerar om du använder Google Chrome på en mobil med Android."
            onClick={this.getMessagingToken}
          >
            {!handlingMessagingToken && <Icon name="mobile alternate" color='grey' />}
            {handlingMessagingToken  === "running" && <Icon name="spinner" color='grey' loading />}
            {handlingMessagingToken  === "done" && <Icon name="check" color='green' />}
            {handlingMessagingToken === "error" && <Icon name="close" color='red' />}
          </MenuItem>

          <MenuItem 
            id="notifications/show_new_data"
            title="Hämtad data"
            description="Visa notiser om ny data på mobilen."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />

          <MenuItem 
            id="notifications/show_guides"
            title="Guider"
            description="Visa notiser om aktiviteter och påminnelser kopplade guider på mobilen."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />  

          <MenuItem 
            id="notifications/show_reminders"
            title="Aktiviteter"
            description="Påminn om aktiviteter som planerade idag som en notis på mobilen."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />        

          <MenuItem 
            id="notifications/show_messages"
            title="Meddelanden"
            description="Visa nya meddelanden från din rådgivare som en notis på mobilen."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />        

          <MenuItem 
            id="notifications/show_comments"
            title="Kommentarer"
            description="Visa nya kommentarer från din rådgivare som en notis på mobilen."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />

          <MenuItem 
            id="notifications/show_warnings"
            title="Varningar"
            description="Visa notiser om varningar på mobilen."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />

          <Header
            as='h3'
            style={{
              fontWeight: 500,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Övrigt
          </Header>

          <MenuItem 
            id="weather/name"
            title="Väderstation att hämta data från"
            description={weatherStation}
          />

          <MenuItem 
            id="tutorials/show_tutorials"
            title="Startguider"
            description="Visa startguider under hjälpmenyn och vid första användning av appen."
            onClick={this.onClickSubMenuItem}
            currentValueHelper={this.getCurrentDbValue}
          />        
          
          <MenuItem 
            id="app/version"
            title="Appversion"
            description={"Huvudversion: " + MAIN_APP_VERSION + ". Byggdatum: " + BUILD_DATE + "."}
          />

          {isDeveloper &&
            <React.Fragment>
              <Header
                as='h3'
                style={{
                  fontWeight: 500,
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Användare
              </Header>

              <MenuItem 
                id="view/beta_mode"
                title="Betafunktioner"
                description="Visa funktioner som är under utveckling."
                onClick={this.onClickSubMenuItem}
                currentValueHelper={this.getCurrentDbValue}
              />
              
              <MenuItem 
                id="view/developer_mode"
                title="Utvecklarfunktioner"
                description="Visa funktioner för felsökning."
                onClick={this.onClickSubMenuItem}
                currentValueHelper={this.getCurrentDbValue}
              />
            </React.Fragment>
          } 
        </div>
      </Page>
    )
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "settings" },
      ],
      storeAs: 'settings'
    },
  ]),
  withFirestoreData,
  withRouter
)(SettingsPage);