import React, { Component } from "react";

import MapStatsBasic from "@/components/map/MapStatsWidget/MapStatsBasic";

import {
  SHOW_BASIC_STATS_FOR_LAYERS,
  SHOW_ADVANCED_STATS_FOR_LAYERS,
} from "@/constants/viewLayersAnalysis"

const INITIAL_STATE = {};

class MapStatsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const {
      layer,
      geoJsonData,
      markers,
      polygonImage,
    } = this.props;
    
    const hasStatsToShow = SHOW_ADVANCED_STATS_FOR_LAYERS.includes(layer);

    let showBasicStats = false;

    SHOW_BASIC_STATS_FOR_LAYERS.forEach((x) => {
      showBasicStats = layer.includes(x) ? true : showBasicStats;
    });

    showBasicStats = layer.includes("_percent") ? false : showBasicStats;
    showBasicStats = layer.includes("_texture") ? false : showBasicStats;

    if (
      (geoJsonData || polygonImage || markers) && 
      !this.props.selectedMarker && 
      !hasStatsToShow && 
      (!showBasicStats && !layer.includes("vraMap"))
      ){
      return null
    };

    return (
      <div>
        {(!this.props.selectedMarker && showBasicStats) && 
          <MapStatsBasic 
            {...this.props} 
            field={this.props.field} 
            fieldInfo={this.props.fieldInfo} 
          />
        }
      </div>
    );
  };
};

export default MapStatsWidget;