import React, { Component } from "react";

import { getColorScales, getDataStats } from "@/components/fieldData/FieldDataColorScales";

import FieldDataDateMenu from "@/components/fieldData/FieldDataDateMenu";
import FieldDataLayerMenu from "@/components/fieldData/FieldDataLayerMenu";
import FieldDataSubLayerMenu from "@/components/fieldData/FieldDataSubLayerMenu";
import FieldDataTopMenu from '@/components/fieldData/FieldDataTopMenu';

import MapColorScaleBar from "@/components/map/MapColorScaleBar";
import MapLegendGroups from "@/components/map/MapLegendGroups";
import MapStatsWidget from "@/components/map/MapStatsWidget";
import MapToolbar from "@/components/map/MapToolbar";
import MapWithCircleMarkers from "@/components/map/MapWithCircleMarkers";

import { CROP_CODES } from "@/constants/cropCodes";

import { DATA_LAYERS, getCurrentDataLayer } from "@/constants/viewLayersAnalysis";
import { fetchData, populateCacheWrapper, getAvailableLayerIds } from "@/helpers/dataFetcher";
import { ANALYSIS_TO_CACHE, LAYERS_TO_CACHE } from "@/helpers/dataFetcher";

import { DateInput } from 'semantic-ui-calendar-react';

class FieldDataViewer extends Component {
  state = {
    geoJsonData: null,
    geoJsonFieldKey: null,
    statJson: null,
    polygonImage: null,
    selectedGroup: null,
    selectedIdx: null,
    legendVisible: true,
    colorScaleType: "e",
    markerScale: 1.0,
    numberOfGroups: 5,
    scale: null,
    showMapLegendGroups: false,
    showMapLegendStats: false,
    showDatePicker: false,
    loadingData: false,
  };

  componentDidMount() {
    fetchData({ ...this.props, downloadNewData: true, setParentState: this.setParentState });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      db,
      fieldId,
      layer,
      layerId,
    } = this.props;

    const {
      geoJsonData,
      selectedIdx,
    } = this.state;

    let hasChangedLayer = false;
    let payload = { ...this.props, downloadNewData: true, setParentState: this.setParentState };

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let dataLayer = getCurrentDataLayer(layer);

    // Get the collection name
    let dbDataDocName = dataLayer && dataLayer.dbDataDocName;
    dbDataDocName = typeof(dbDataDocName) === "string" ? dbDataDocName : dbDataDocName && dbDataDocName[layer];

    let dbDataColName = dbDataDocName && dbDataDocName.split("/")[0];

    if (
      fieldId !== prevProps.fieldId ||
      layer !== prevProps.layer ||
      layerId !== prevProps.layerId
    ) {

      let downloadNewData = true;

      if (fieldId === prevProps.fieldId) {
        // Same field

        if (layer && prevProps.layer && (layer.split("_")[0] === prevProps.layer.split("_")[0])) {
          // Same family of layer

          if (layerId === prevProps.layerId) {
            // Same date, year or type of crop
            downloadNewData = false;
          };

          if (layer === "soilMapUploaded_samples" || prevProps.layer === "soilMapUploaded_samples") {
            downloadNewData = true;
          };

          if (layer === "soilMapAnalysis_samples" || prevProps.layer === "soilMapAnalysis_samples") {
            downloadNewData = true;
          };
        };
      };

      if (!geoJsonData) {
        downloadNewData = true;
      }

      downloadNewData && this.setState({
        selectedMarker: null,
        selectedGroupMarkers: null,
        geoJsonData: null,
        polygonImage: null,
        statJson: null,
        markers: null,
        loadingData: true,
        dataLayerMissing: false,
      });

      // Layer has been changed so skip checking for changes in database
      hasChangedLayer = true;

      fetchData({ ...payload, downloadNewData: downloadNewData });
      downloadNewData && this.smartPreCache(fieldId, layer, layerId);
    };

    let dbCollection = dbDataColName && db && fieldId && db[dbDataColName + "_" + fieldId];
    let prevDbCollection = dbDataColName && prevProps.db && fieldId && prevProps.db[dbDataColName + "_" + fieldId];

    // Data has finished loading
    if (!geoJsonData && !hasChangedLayer && (dbCollection !== prevDbCollection || selectedIdx !== prevState.selectedIdx)) {
      fetchData(payload);
      this.smartPreCache(fieldId, layer, layerId);
    };
  };

  smartPreCache = (fieldId, layer, layerId) => {
    const {
      dataType,
    } = this.props;

    let currentLayer = getCurrentDataLayer(layer);

    if (currentLayer && dataType === "layers") {
      LAYERS_TO_CACHE && LAYERS_TO_CACHE.forEach((cacheLayer) => {
        let cacheLayerInfo = getCurrentDataLayer(cacheLayer);

        let placeHolderLayerId = null;
        placeHolderLayerId = cacheLayerInfo && cacheLayerInfo.layerIdTypes.includes("dates") ? "latest" : placeHolderLayerId;

        layer !== cacheLayer && populateCacheWrapper({
          ...this.props,
          fieldId,
          layer: cacheLayer,
          layerId: cacheLayerInfo.layerIdTypes[0] === currentLayer.layerIdTypes[0] ? layerId: placeHolderLayerId,
        });
      });    
    };
    
    if (currentLayer && dataType === "analysis") {
      ANALYSIS_TO_CACHE && ANALYSIS_TO_CACHE.forEach((cacheLayer) => {
        layer !== cacheLayer && populateCacheWrapper({
          ...this.props,
          fieldId,
          layer: cacheLayer,
          layerId,
        });
      });    
    };    
  };

  setParentState = (newState) => {
    this.setState(newState);
  };

  toggleShowMapLegendGroups = () => {
    let currentValue = this.state.showMapLegendGroups;
    this.setState({ showMapLegendGroups: !currentValue });
  };

  toggleShowMapLegendStats = () => {
    let currentValue = this.state.showMapLegendStats;
    this.setState({ showMapLegendStats: !currentValue });    
  };

  setSelectedGroup = newIndex => {
    let currentIndex = this.state.selectedGroup;

    this.setState({
      selectedGroup: newIndex !== currentIndex ? newIndex : null,
      selectedMarker: null
    });
  };  

  openLayer = (type, idx) => {
    const {
      db,
      fieldId,
    } = this.props;

    let availableDates = getAvailableLayerIds(db, fieldId, type);

    if (availableDates && availableDates.includes(idx)) {
      this.props.openLayer(type, idx)
    } else {
      this.props.openLayer(type, availableDates && availableDates.length > 0 ? availableDates[availableDates.length - 1] : null);
    };

    this.setState({ showDatePicker: false });
  };

  onChangeDate = (mode='increment') => {
    const { 
        db,
        fieldId,
        layer,
        layerId,
    } = this.props;

    let availableDates = getAvailableLayerIds(db, fieldId, layer);

    let currentDate = (availableDates && layerId) ? layerId : availableDates[availableDates.length - 1];
    let currentDateIndex = availableDates && availableDates.indexOf(currentDate);

    var newDate = null;
    var noDates = availableDates.length - 1;
  
    switch(mode) {
      case 'decrement':
          if (currentDateIndex > 0) {
            newDate = availableDates[currentDateIndex - 1];
            this.openLayer(layer, newDate);
          }
          break;
  
      case 'increment':
        if (currentDateIndex < noDates) {
          newDate = availableDates[currentDateIndex + 1];
          this.openLayer(layer, newDate);
        }
        break;
  
      default:
        break;
    };
  };

  togglePercentage = () => {
    const {
      layer,
      layerId,
    } = this.props;

    let openLayerType = layer && DATA_LAYERS && Object.keys(DATA_LAYERS).filter((x) => DATA_LAYERS[x].aliases.includes(layer));
    let subDataLayer = DATA_LAYERS && openLayerType && DATA_LAYERS[openLayerType] && DATA_LAYERS[openLayerType];

    let percentMapTypes = subDataLayer && subDataLayer.percentMaps && Object.keys(subDataLayer.percentMaps).map((x) => x);

    if (percentMapTypes.includes(layer)) {
      // Switching from absolute to percentage
      this.openLayer(subDataLayer.percentMaps[layer], layerId)
    } else {
      this.openLayer(layer.split("_percent")[0], layerId)
    }
  };

  getDatePicker = () => {
    const {
      db,
      fieldId,
      layer,
    } = this.props;

    let availableDates = getAvailableLayerIds(db, fieldId, layer);

    if (!this.props.layerId) {
      return null
    }

    if (!availableDates) {
      return null
    }

    if (!availableDates.includes(this.props.layerId)) {
      return null
    }     

    return (
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100vw",
          zIndex: 9999,
        }}
      >
        <DateInput
          inline
          name='date'
          dateFormat="YYYY-MM-DD"
          value={this.props.layerId}
          marked={availableDates}
          markColor="yellow"
          enable={availableDates}
          onChange={(e, d) => this.openLayer(layer, d.value)}
          localization='sv'
        />
      </div>
    )
  };

  toggleDatePicker = () => {
    let currentValue = this.state.showDatePicker;
    this.setState({ showDatePicker: !currentValue });
  };  

  render() {
    const {
      db,
      field,
      fieldInfo,
      fieldId,
      layer,
      layerId,
      processedYieldMap,
      processedSentinelMap,
      position,
      lockedMap,
    } = this.props;

    const {
      geoJsonData,
      geoJsonFieldKey,      
      statJson,
      selectedGroup,
      legendVisible,
      colorScaleType,
      numberOfGroups,
      scale,
      polygonImage,
      showMapLegendGroups,
      showMapLegendStats,
      showDatePicker,
      loadingData,
    } = this.state;

    let availableDates = getAvailableLayerIds(db, fieldId, layer);

    let selectedMarker = this.props.selectedMarker ? this.props.selectedMarker : this.state.selectedMarker;
    let markerScale = this.props.markerScale ? this.props.markerScale : this.state.markerScale;

    let fieldSize = field && field.field_size && field.field_size;

    let currentYear = layerId && new Date(layerId).getFullYear() && new Date(layerId).getFullYear();

    let currentCropCode = currentYear &&
      fieldInfo &&
      fieldInfo.crop_code &&
      fieldInfo.crop_code[currentYear] &&
      fieldInfo.crop_code[currentYear];

    let cropShortText = currentCropCode && CROP_CODES.find(x => x.value === currentCropCode);
    cropShortText = cropShortText && cropShortText.shorttext;
    cropShortText = layer.includes("soilMap") ? null : cropShortText;

    let openLayerType = layer && DATA_LAYERS && Object.keys(DATA_LAYERS).filter((x) => DATA_LAYERS[x].aliases.includes(layer));
    let subDataLayer = DATA_LAYERS && openLayerType && DATA_LAYERS[openLayerType] && DATA_LAYERS[openLayerType];    

    let colorScales = geoJsonData && getColorScales(layer, geoJsonData, geoJsonFieldKey, colorScaleType, numberOfGroups, scale );
    let dataStats = colorScales && geoJsonData && getDataStats(layer, colorScales, geoJsonData, geoJsonFieldKey);
    
    let selectedMarkers = (geoJsonData && selectedGroup !== null) ? 
      dataStats && dataStats.groups[selectedGroup] && dataStats.groups[selectedGroup].samples : 
      geoJsonData && geoJsonData.features;
    
    selectedMarkers = selectedMarkers && selectedMarkers.map(sample => ({...sample, tooltip: ''}))
    
    let estimatedTotalHarvest = dataStats && fieldSize && dataStats.mean * fieldSize;

    if (!field) {
      return <div></div>
    };
    
    let toolbar = {
      leftButtonFunc: this.props.closeField,
      // helpButtonFunc: showHelpButton ? this.toggleShowHelpModal: null,
      // menuButtonFunc: isSatelliteImage ? this.toggleSubMenu : null,
      rightButtons: [
        {
          id: 'statistics', 
          icon: 'area graph', 
          active: showMapLegendStats, 
          visible: layer && !["trueColor", "soilMapSweden_texture"].includes(layer) && !layer.includes("percent"),
          onClick: () => this.toggleShowMapLegendStats(),
        },           
        {
          id: 'percent', 
          icon: 'percent', 
          active: layer.includes("percent"), 
          visible: subDataLayer && subDataLayer.percentMaps,
          onClick: () => this.togglePercentage(),
        },
        {
          id: 'help', 
          icon: 'question circle', 
          active: false, 
          visible: false,
          onClick: () => {},
        }, 
        {
          id: 'latest', 
          icon: 'calendar check outline', 
          active: false, 
          visible: [
              'greenmass', 
              'greenmass_percent', 
              'lai', 
              'lai_percent', 
              'psri', 
              'psri_percent', 
              "moisture", 
              "moisture_percent",
              "trueColor",
            ].includes(layer) &&
            availableDates && availableDates.length > 0,
          onClick: () => this.openLayer(layer, null),
        }, 
        {
          id: 'date', 
          icon: 'calendar alternate outline', 
          active: showDatePicker, 
          visible: [
              'greenmass', 
              'greenmass_percent', 
              'lai', 
              'lai_percent', 
              'psri', 
              'psri_percent', 
              "moisture", 
              "moisture_percent",
              "trueColor",
            ].includes(layer) &&
            availableDates && availableDates.length > 0,
          onClick: () => this.toggleDatePicker(),
        },                 
      ]
    };

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        {showDatePicker && this.getDatePicker()}

        <FieldDataTopMenu
          field={field}
          closeField={this.props.closeField}
          toolbar={toolbar}
        />        

        <FieldDataLayerMenu
          db={db}
          fieldId={fieldId}
          layer={layer}
          layerId={layerId}
          dataType="layers"
          openLayer={this.openLayer}
        />

        {!loadingData && !showMapLegendGroups && !showMapLegendStats &&
          <FieldDataSubLayerMenu
            db={db}
            fieldId={fieldId}
            layer={layer}
            layerId={layerId}
            dataType="layers"
            openLayer={this.openLayer}
          />
        }

        {!loadingData && availableDates && !showMapLegendGroups && !showMapLegendStats &&
          <FieldDataDateMenu
            availableDates={availableDates}
            currentDate={layerId}
            onChangeDate={this.onChangeDate}
          />        
        }

        <MapWithCircleMarkers
          field={field && field}
          markers={selectedMarkers && selectedMarkers}
          allMarkers={geoJsonData && geoJsonData.features}
          colorScale={colorScales && colorScales.colorScale}
          markerScale={position ? 0.5 * markerScale : markerScale}
          polygonImage={polygonImage}
          onMarkerClick={this.selectMarker}
          selectedMarker={selectedMarker}
          geoJsonFieldKey={geoJsonFieldKey}
          lockedMap={lockedMap}
          loadingData={loadingData}
          loadingDataText="Freja hämtar data..."
        >
          {!loadingData && !showMapLegendGroups && !showMapLegendStats &&
            <MapToolbar
              date={layerId}
              cropShortText={cropShortText}
            />
          }

          {!loadingData && !showMapLegendGroups && !showMapLegendStats &&
            <MapColorScaleBar 
              layer={layer}
              layerId={layerId}
              colorScale={colorScales}
              numberOfGroups={numberOfGroups}
              dataStats={dataStats}
              toggleShowMapLegendGroups={this.toggleShowMapLegendGroups}
            />
          }

          {!loadingData && showMapLegendGroups && !showMapLegendStats &&
            <MapLegendGroups
              field={field && field}
              layer={layer}
              dataStats={dataStats}
              polygonImage={polygonImage}
              selectedGroup={selectedGroup}
              onSelectGroup={this.setSelectedGroup}
              numberOfGroups={numberOfGroups}
              toggleShowMapLegendGroups={this.toggleShowMapLegendGroups}
            />
          }

          {!loadingData && showMapLegendStats &&
            <MapStatsWidget
              layer={layer}
              layerId={layerId}
              field={field && field}
              statJson={statJson}
              dataStats={dataStats}
              polygonImage={polygonImage}
              estimatedTotalHarvest={estimatedTotalHarvest}
              toggleLegendVisible={this.toggleLegendVisible}
              legendVisible={legendVisible}
              selectedGroup={selectedGroup}
              colorScales={colorScales}
              fieldSize={fieldSize}
              processedYieldMap={processedYieldMap}
              setSelectedGroup={this.props.setSelectedGroup}
              selectedMarker={selectedMarker}
              fieldId={fieldId}
              selectMarker={this.selectMarker}
              processedSentinelMap={processedSentinelMap}
              onToggleShowMapLegendStats={this.toggleShowMapLegendStats}
            />
          }
        </MapWithCircleMarkers>
      </div>
    );
  }
}

export default FieldDataViewer;
