import React, { Component } from "react";

import { 
  Image, 
  Icon, 
  Popup,
  Segment,
  Header,
  Loader,
} from "semantic-ui-react";

import {
  isLoaded,
} from "react-redux-firebase";

import FieldCartoon from "@/components/misc/FieldCartoon";

import ImageHectar from "@/assets/icons/hectar.svg"
import ImageFarm from "@/assets/icons/menuPrimary/my-farm.svg"
import ImageYieldMaps from "@/assets/icons/yield-maps.svg"

import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";

class MenuFieldList extends Component {

  openField = (newFieldId, availableToUser) => {
    availableToUser && this.props.openField(newFieldId);
    !availableToUser && this.props.openUnlockField(newFieldId);
  };

  getNotificationDot = () => (
    <div 
      style={{ 
        position: "relative", 
        top: "-1.7em", 
        right: "-1.7em", 
        width: "0.5em", 
        height: "0.5em", 
        borderRadius: "50%", 
        backgroundColor: "#C36126" 
      }} 
    />
  );

  getDataIcons = ( dataTypes, newDataAvailable, showNewDataDots ) => (
    <div style={{ marginTop: 12, display: "flex" }}>
      {dataTypes && dataTypes.includes("sentinel") && 
        <Popup
          trigger={
            <div 
              style={{
                backgroundColor: "#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
                marginRight: "0.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={FIELD_DATA_LAYERS.greenmass.icon} 
              />

              {showNewDataDots && newDataAvailable.sentinel && newDataAvailable.sentinel.length > 0 && this.getNotificationDot()} 
            </div>
          }
          content={'Detta skifte har' + ((newDataAvailable && newDataAvailable.sentinel && newDataAvailable.sentinel.length > 0)  ? ' nya ' : ' ') + 'satellitbilder tillgängliga.'}
        />
      }


      {dataTypes && 
        (dataTypes.includes("soilMap") || dataTypes.includes("soilMapSweden") || dataTypes.includes("soilMapSwedish") || dataTypes.includes("soilMapUploaded")) && 
        <Popup
          trigger={
            <div 
              style={{
                backgroundColor: "#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
                marginRight: "0.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={FIELD_DATA_LAYERS.soilMapSweden.icon} 
              />
              
              {showNewDataDots && newDataAvailable.soilMapSweden && newDataAvailable.soilMapSweden.length > 0 && this.getNotificationDot()} 
              {showNewDataDots && newDataAvailable.soilMapUploaded && newDataAvailable.soilMapUploaded.length > 0 && this.getNotificationDot()} 
            </div>
          }
          content={'Detta skifte har' + ((newDataAvailable && newDataAvailable.soilMap && newDataAvailable.soilMap.length > 0) ? ' nya ' : ' ') + 'markkartor tillgängliga.'}
        />
      }

      {dataTypes && dataTypes.includes("yieldMap") && 
        <Popup
          trigger={
            <div 
              style={{
                backgroundColor: "#F8EED0",
                borderRadius: 5,
                width: "2.4em",
                height: "2.4em",
              }}
            >
              <Image 
                style={{ 
                  marginTop: "0.5em",
                  marginLeft: "0.5em",
                  width: "1.4em", 
                  height: "1.4em",
                  filter: "invert(0%)",
                }}
                src={FIELD_DATA_LAYERS.yieldMap.icon} 
              />

              {showNewDataDots && newDataAvailable.yieldMap && newDataAvailable.yieldMap.length > 0 && this.getNotificationDot()} 
            </div>
          }
          content={'Detta skifte har' + ((newDataAvailable && newDataAvailable.yieldMap && newDataAvailable.yieldMap.length > 0)  ? ' nya ' : ' ') + 'skördekartor tillgängliga.'}
        />      
      }
    </div>
  )

  getFieldCards = (fieldId, idx, currentFieldId, onlyAvailable, showNewDataDots) => {
    const {
      db,
      selectedFieldIds,
      compact,
      additionalInformation,
      disableHover,
    } = this.props;

    let field = db && db.fields &&  db.fields[fieldId] && db.fields[fieldId];
    let position = field && field.position;
    
    let availableToUser = field && field.available_to_user;
    availableToUser = typeof(availableToUser) === "boolean" ? availableToUser : true;

    let disabled = field && field.disabled;
    disabled = typeof(disabled) === "boolean" ? disabled : false;    

    let isCompact = compact && typeof(compact) === "boolean" ? compact : false;
    let isSelected = selectedFieldIds && selectedFieldIds.includes(fieldId);
    
    let size = field && field.field_size && field.field_size;
    size = size && size.toFixed(1) + " ha"
    
    let name = field && field.name && field.name;
    let dataTypes = field && field.data_types && field.data_types;     
    let newDataAvailable = field && field.new_data_available && field.new_data_available;

    if ((availableToUser === false && onlyAvailable === true) || (availableToUser === true && onlyAvailable === false)) {
      return null
    };

    if (disabled) {
      return null
    };

    let cartoonColor = availableToUser ? "#e0ba8c" : "#888888";

    let backgroundColor = availableToUser ? (currentFieldId === fieldId ? "#e0ba8c" : "#ffffff") : "#868D8E";
    backgroundColor = isSelected ? "#e0ba8c" : backgroundColor;

    let className = (availableToUser && currentFieldId !== fieldId) ? "fieldCardOnHover" : null;
    className = className && disableHover ? null : className;

    return (
      <React.Fragment key={`fieldData_fieldLayers_${fieldId}`}>
        <Segment
          className={className}
          onClick={e => this.openField(fieldId, availableToUser)}
          style={{            
            backgroundColor: backgroundColor,
            padding: "0.5em",
            marginTop: 0,
            marginBottom: 10,
            cursor: disableHover ? null : "pointer",
            height: isCompact ? "8em" : "8.5em",
            boxShadow: "none",
          }}
        >
          <div
            style={{
              display: "flex", 
              width: "100%",
            }}
          >
            <div
              style={{ 
                width: isCompact ? null : "30%",
                maxWidth: "6em",
              }}
            >
              <div
                style={{
                  backgroundColor: availableToUser ? "#F8EED0" : "transparent",
                  borderRadius: 5,
                  width: "6em",
                  height: "6em",
                  marginLeft: "0.25em",
                  marginRight: "1em",
                  marginTop: "0.75em",
                }}
              >
                {position && 
                  <FieldCartoon
                    style={{
                      margin: 0,
                      padding: 0,
                      marginTop: "1em",
                      marginLeft: "1em",
                      height: "4em",
                      width: "4em",
                      zIndex: 20,
                    }}            
                    stroke={cartoonColor}
                    color="white"
                    position={position}
                  />
                }    
              </div>
            </div>

            <div
              style={{
                marginLeft: 20,
                width: isCompact ? null : "70%",
                marginTop: isCompact ? "auto" : null,
                marginBottom: isCompact ? "auto" : 0,
              }}
            >
              <div 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Header
                  style={{ 
                    fontWeight: 500,
                    color: availableToUser ? "black" : "white",
                    paddingTop: isCompact ? "5px" : "10px",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  {name && name}
                </Header>

                {!isCompact && currentFieldId !== fieldId && 
                  <Icon 
                    style={{ 
                      marginTop: 15, 
                      textAlign: "right" 
                    }} 
                    name="chevron right" 
                    size="small" 
                  />
                }         
              </div>

              {!isCompact && size && 
                <p 
                  style={{ 
                    marginBottom: 10, 
                    color: availableToUser ? "grey" : "white", 
                  }}
                >
                  <Image 
                    inline 
                    src={ImageHectar} 
                    style={{ 
                      width: "15px", 
                      filter: availableToUser ? null : "brightness(0) invert(100%)" 
                    }} 
                  /> 
                  {"  " + size}
                </p>
              }

              {additionalInformation && additionalInformation[fieldId] && additionalInformation[fieldId].noYieldMaps &&
                <p 
                  style={{ 
                    marginBottom: 10, 
                    color: availableToUser ? "grey" : "white", 
                  }}
                >
                  <Image 
                    inline 
                    src={ImageYieldMaps} 
                    style={{ 
                      width: "15px", 
                      filter: availableToUser ? null : "brightness(0) invert(100%)" 
                    }} 
                  /> 
                  {"  " + additionalInformation[fieldId].noYieldMaps.toFixed(0) + " st."}
                </p>
              }

              {additionalInformation && additionalInformation[fieldId] && additionalInformation[fieldId].reason &&
                <div style={{ marginTop: 15 }}>
                  <Icon 
                    style={{ 
                      textAlign: "right" 
                    }} 
                    name="warning sign" 
                    color="red"
                    size="small" 
                  />
                    {additionalInformation[fieldId].reason}
                  </div>
                }       

              {!availableToUser && <p style={{ marginTop: 15, color: "white", }}>Klicka här för att låsa upp skiftet.</p>}       
              {!isCompact && availableToUser && dataTypes && this.getDataIcons(dataTypes, newDataAvailable, showNewDataDots)}
            </div>
          </div>
        </Segment>
    </React.Fragment>
    )
  };

  getFarmCard = (fieldId) => {
    const {
      db,
    } = this.props;
    
    let fieldIds = db && isLoaded(db) && db.fields && 
      Object.keys(db.fields)
      .map((x) => x)
      .filter((x) => ( typeof(db.fields[x].available_to_user) !== "boolean") || (typeof(db.fields[x].available_to_user) === "boolean" && db.fields[x].available_to_user === true))

    let totalArea = db && fieldIds && fieldIds
      .map(x => db.fields[x].field_size && db.fields[x].field_size)
      .reduce((partial_sum, a) => partial_sum + a, 0);
    totalArea = totalArea ? totalArea : null;

    let name = db && db.profile && db.profile.farm && db.profile.farm.name;
    let size = totalArea && totalArea.toFixed(0) + " ha";
    let noFields = fieldIds && fieldIds.length + " st";

    return (
      <React.Fragment>
        <Segment
          className={"farm" !== fieldId ? "fieldCardOnHover" : null}
          onClick={e => this.props.openField("farm")}
          style={{
            backgroundColor: "farm" === fieldId ? "#e0ba8c" : "#ffffff",
            padding: "15px",
            paddingTop: 5,
            paddingRight: 12,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0,
            cursor: "pointer",
            height: "105px",
          }}
        >
          <div
            style={{
              display: "flex", 
            }}
          >
            <div style={{ width: "30%" }}>
              <Image 
                className="fieldCartoon"
                style={{
                  margin: 0,
                  padding: 0,
                  paddingLeft: "2px",
                  marginTop: "15px",
                  height: "60px",
                  width: "60px",
                  zIndex: 20,
                  filter: "farm" === fieldId ? "brightness(100)" : "brightness(0) invert(75%) sepia(54%) saturate(323%) hue-rotate(5deg) brightness(98%) contrast(94%)",
                }}
                src={ImageFarm} 
              />
            
            </div>
            <div
              style={{
                marginLeft: 20,
                width: "70%",
              }}
            >
              <div 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  
                }}
              >
                <Header
                  style={{ 
                    fontWeight: 500,
                    paddingTop: "10px",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  {name && name}
                </Header>

                {"farm" !== fieldId && <Icon style={{ marginTop: 15, textAlign: "right" }} name="chevron right" size="small" />}
              </div>

              {noFields && <p style={{ color: "grey", marginBottom: 8 }}><Image inline src={ImageYieldMaps} style={{ width: "15px" }} /> {noFields}</p>}    
              {size && <p style={{ marginTop: -8, color: "grey" }}><Image inline src={ImageHectar} style={{ width: "15px" }} /> {size}</p>}    
            </div>
          </div>
        </Segment>
    </React.Fragment>
    )
  };  

  getLoaderScreen = () => (
    <Segment
      style={{
        height: "100%",
      }}            
    >
      <Loader 
        active
        inline="centered"
        indeterminate 
        size="massive"
        style={{
          marginTop: "100%",
        }}
      >
        <div
          style={{
            fontSize: "80%", 
            marginTop: "0.5em",
            color: "grey",
          }}
        >
          Öppnar skiften...
        </div>
      </Loader>            
    </Segment>   
  )

  render() {
    const {
      db,
      fieldId,
      showNewDataDots,
      hideFarmCard,
      hideUnLockedFields,
      hideLockedFields,
      showOnlyFieldIds,
    } = this.props;

    let fieldIds = db && db.fields && Object.keys(db.fields);
    fieldIds = fieldIds && showOnlyFieldIds && Array.isArray(showOnlyFieldIds) ? fieldIds.filter((x) => showOnlyFieldIds.includes(x)) : fieldIds;
    
    fieldIds && fieldIds.sort(function(a, b) { 
      let sortedArray = [db.fields[b].name, db.fields[a].name].sort()
      if (sortedArray[0] === db.fields[b].name) {
        return 1.0
      } else {
        return -1.0
      }
    });

    let farmCard = this.getFarmCard(fieldId);
    let fieldItemsAvailable = fieldIds && fieldIds.map((x, idx) => this.getFieldCards(x, idx, fieldId, true, showNewDataDots));
    let fieldItemsUnAvailable = fieldIds && fieldIds.map((x, idx) => this.getFieldCards(x, idx, fieldId, false, showNewDataDots));
    fieldItemsUnAvailable = fieldItemsUnAvailable && this.props.openUnlockField ? fieldItemsUnAvailable : null;

    if (!fieldIds) {
      return this.getLoaderScreen()
    }

    return (
      <div
        style={{
          width: "calc(100vw - 20)",
          marginTop: 60,
          marginLeft: 10,
          marginRight: 10,
        }}      
      >
        {typeof(hideFarmCard) === "boolean" && hideFarmCard === true ? null : farmCard && farmCard}
        {typeof(hideUnLockedFields) === "boolean" && hideUnLockedFields === true ? null : fieldItemsAvailable && fieldItemsAvailable}
        {typeof(hideLockedFields) === "boolean" && hideLockedFields === true ? null : fieldItemsUnAvailable && fieldItemsUnAvailable}
      </div>
    );
  }
}

export default MenuFieldList;
