import React, { Component } from 'react';

import { 
  Segment,
  Image,
} from 'semantic-ui-react';

import { GEOTAG_TYPES } from "@/constants/geotags";

const AddGeoTagIcon = (props) => (
  <Segment
    onClick={() => props.onClick(props.type)} 
    style={{
      display: "flex",
      marginBottom: "1em",
      padding: "1em",
      marginLeft: "0.75em",
      marginRight: "0.75em",
      fontSize: "150%",
      textAlign: "center",   
      backgroundColor: props.selected ? "#F0BD38" : null,   
      color: props.selected ? "white" : null,
    }}
  >
    <Image 
      size='mini'
      src={props.isSubType ? props.mainTypeIcon : props.typeObject.icon} 
    /> 

    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "1em",
      }}
    >
      {props.typeObject.title}
    </div>
  </Segment>
);

const INITIAL_STATE = {};
  
class GetGeoTagType extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onSelectType = (type) => {
    const {
      isSelectingSubType,
    } = this.props;

    !isSelectingSubType && this.props.updateTag({ mainType: type });
    isSelectingSubType && this.props.updateTag({ subType: type });
  };

  render () {
    const {
      newTag,
      marginTop,
      isSelectingSubType,
    } = this.props;

    let mainType = newTag && newTag.mainType;
    let subType = newTag && newTag.subType;

    let allTypes = mainType && isSelectingSubType ? 
      GEOTAG_TYPES[mainType] && GEOTAG_TYPES[mainType].subTypes :
      GEOTAG_TYPES;

    let mainTypeIcon = mainType && GEOTAG_TYPES[mainType] && GEOTAG_TYPES[mainType].icon;

    return (
      <div 
        style={{
          margin: 0,
          marginTop: marginTop ? marginTop : null,
          padding: 0, 
        }}
      >
        {Object.keys(allTypes).map(x => (
          <AddGeoTagIcon
            key={x} 
            type={x}
            typeObject={allTypes[x]}
            onClick={this.onSelectType}
            mainTypeIcon={mainTypeIcon}
            isSubType={isSelectingSubType}
            selected={mainType === x || subType === x}
          />
        ))}
      </div>
    )
  };
};

export default GetGeoTagType;
