import moment from 'moment';

import {
    WEATHER_FIRST_YEAR,
    SEASON_FIRST_DATE,
    SEASON_LAST_DATE,
  } from "@/constants/settings";

import { getCurrentYear } from "./dates";

export const fetchWeatherData = (weatherData, fileBucketRef, setParentState) => {
  setParentState({
    irradiationData: null,
    rainfallData: null,
    temperatureData: null,
  });

  if (weatherData && weatherData.daily_irradiation_json) {
    fileBucketRef.child(weatherData.daily_irradiation_json).getDownloadURL()
    .then(url => {
      window.fetch(url)
        .then(async res => {
          setParentState({ irradiationData: await res.clone().json() });
        })
        .catch(error => console.log("Cannot get download URL: ", error.toString()))
    })
    .catch(error => console.log("Cannot get download URL: ", error.toString()))
  };

  if (weatherData && weatherData.daily_rainfall_data_json) {
    fileBucketRef.child(weatherData.daily_rainfall_data_json).getDownloadURL()
    .then(url => {
      window.fetch(url)
        .then(async res => {
          setParentState({ rainfallData: await res.clone().json() });
        })
        .catch(error => console.log("Cannot get download URL: ", error.toString()))
    })
    .catch(error => console.log("Cannot get download URL: ", error.toString())) 
  };

  if (weatherData && weatherData.daily_temperature_json) {
    fileBucketRef.child(weatherData.daily_temperature_json).getDownloadURL()
    .then(url => {
      window.fetch(url)
        .then(async res => {
          setParentState({ temperatureData: await res.clone().json() });
        })
        .catch(error => console.log("Cannot get download URL: ", error.toString()))
    })
    .catch(error => console.log("Cannot get download URL: ", error.toString()))
  };
};  

export const extractYears = (data) => {
  let years = [];
  let dates = data && Object.keys(data).map((x) => x);

  dates && dates.forEach((x) => {
    let currentDate = moment(x, "YYYY-MM-DD");
    let currentYear = currentDate && currentDate.format("YYYY");

    let startDate = moment(currentYear + SEASON_FIRST_DATE, "YYYY-MM-DD").toDate();
    let endDate = moment(currentYear + SEASON_LAST_DATE, "YYYY-MM-DD").toDate();

    if (currentDate <= startDate || currentDate >= endDate) {
      return;
    };

    if (years && !years.includes(currentYear)) {
      years = [...years, currentYear]
    };
  });

  return years.filter((x) => parseInt(x) >= parseInt(WEATHER_FIRST_YEAR)).sort()
};   

export const extractDatesAndDataComparison = (weatherDict, year) => {
  let currentYear = getCurrentYear();

  let filteredDates = weatherDict && Object.keys(weatherDict).filter((date) => date.includes(currentYear));
  filteredDates = filteredDates && filteredDates.map((date) => year + "-" + date.split("-")[1] + "-" + date.split("-")[2]);

  let output = {};

  filteredDates && filteredDates.forEach((date) => {
    output = {...output, [date]: weatherDict[date]}
  });

  return extractDatesAndData(output, year);
}

export const extractDatesAndData = (data, layerId, year=null, removeNegative=true, returnDates=false, returnSeries=false) => {
  let modifiedYear = year ? year : layerId && layerId.split("-")[0];

  let modifiedLayerId = layerId && layerId.split("-").length === 1 ? modifiedYear + '-12-31' : layerId;
  modifiedLayerId = modifiedLayerId && modifiedYear + "-" + modifiedLayerId.split("-")[1] + "-" + modifiedLayerId.split("-")[2];

  let dates = data && modifiedYear && Object.keys(data).filter((x) => x.includes(modifiedYear));

  let startDate = moment(modifiedYear + SEASON_FIRST_DATE, "YYYY-MM-DD").toDate();
  let currentDate = moment(modifiedLayerId, "YYYY-MM-DD").toDate();
  let endDate = moment(modifiedYear + SEASON_LAST_DATE, "YYYY-MM-DD").toDate();

  if (currentDate <= startDate) {
    return [0.0, 0.0];
  };

  if (currentDate >= endDate) {
    currentDate = endDate;
  };

  if (returnDates) {
    return [startDate, currentDate]
  }

  dates = dates && dates.filter((x) => moment(x, "YYYY-MM-DD").toDate() >= startDate && moment(x, "YYYY-MM-DD").toDate() <= currentDate);
  dates = removeNegative ? dates && dates.filter((x) => data[x] > 0.0) : dates;

  let dailyData = dates && dates.map((x) => data[x]);
  let accData = [];

  dailyData && dailyData.reduce(function(a, b, i) {
    if (b > 0.0) {
      return (accData[i] = a + b);
    } else {
      return (accData[i] = a);
    }
  }, 0.0);

  let output = accData.slice(-1)[0] ? accData.slice(-1)[0] : 0.0;

  if (returnSeries) {
    return dailyData
  } else {
    return isNaN(output) ? 0.0 : output;
  };
};


export const getDateArray = () => {
  let startDate = new Date(WEATHER_FIRST_YEAR + SEASON_FIRST_DATE);
  let endDate = new Date(WEATHER_FIRST_YEAR + SEASON_LAST_DATE);

  let arr = [];
  let dt = new Date(startDate);

  while (dt <= endDate) {
      arr.push(moment(new Date(dt)).format("DD/MM"));
      dt.setDate(dt.getDate() + 1);
  };

  return arr;
};