import React, { Component } from 'react';

import { 
  Menu,
  Image,
} from 'semantic-ui-react';

import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";

import { hasLayerData } from "@/helpers/dataFetcher";

const INITIAL_STATE = {};

class FieldDataLayerMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const { 
      db,
      layer,
      layerId,
      fieldId,
    } = this.props;

    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100vw",
          zIndex: 8000,
        }}
      >
        <Menu
          pointing
          fluid
          secondary
          icon='labeled'
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            margin: 0,
            borderRadius: 0,
            border: "0px !important",
            textAlign: "center",
            backgroundColor: "#363e40",
            width: "100vw",
          }}
          widths={FIELD_DATA_LAYERS && Object.keys(FIELD_DATA_LAYERS).map((x) => x).length}
        >
          {Object.keys(FIELD_DATA_LAYERS).map(x => {

            let layerName = FIELD_DATA_LAYERS[x].name;
            let isSelected = FIELD_DATA_LAYERS[x].aliases.includes(layer);
            let hasProcessedData = hasLayerData(db, fieldId, x);

            return (
              <Menu.Item
                as="div"
                style={{
                  color: hasProcessedData ? (isSelected ? "rgb(0, 0, 0, 0.85)" : "white") : "grey",
                  background: isSelected ? "#F8EED0" : "transparent",
                  borderRadius: 0,
                  borderTop: isSelected ? "4px solid rgba(234, 204, 116, 1.0)" : null,
                  fontSize: "70%",
                  fontWeight: 500,
                }}
                key={x}
                active={isSelected}
                onClick={() => hasProcessedData && this.props.openLayer(layerName, layerId)}
              >
                <Image
                  centered
                  src={FIELD_DATA_LAYERS[x].icon}
                  style={{
                    width: "2.25em",
                    filter: hasProcessedData ? (
                      isSelected ?
                      "brightness(0) invert(35%)" :
                      "brightness(0) invert(85%)"
                    ) : "brightness(0) invert(50%)",
                    marginBottom: 6,
                  }}
                />
                
                {FIELD_DATA_LAYERS[x].caption}
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
    )
  }
};

export default FieldDataLayerMenu;

