import React, { Component } from "react";

import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";

import {
  Icon,
  Grid,
  Header,
} from "semantic-ui-react";

const INITIAL_STATE = {};

class MapLegendGroups extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }


  groupLabel = (group, numberOfGroups) => {
    const { 
      layer, 
      statJson, 
    } = this.props;

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let DATA_LAYERS = FIELD_DATA_LAYERS;
    let dataLayer = DATA_LAYERS && Object.keys(DATA_LAYERS).find((x) => DATA_LAYERS[x].aliases.includes(layer));
    let dataLayerType = dataLayer && DATA_LAYERS[dataLayer] && DATA_LAYERS[dataLayer].dataType && DATA_LAYERS[dataLayer].dataType[layer];

    let isFloatLayer = dataLayerType === "float";
    let isPercentLayer = dataLayerType === "percent";
    let isDifferenceLayer = dataLayerType === "difference";

    let label = '';

    // Soil maps
    if (isFloatLayer && dataLayer === "soilMap" && layer !== "soilMap_texture") {
      label = group.max
      ? group.min.toFixed(0) + "% till " + group.max.toFixed(0) + "%"
      : group.min ? group.min.toFixed(0) + "%" : "";
    };

    if (isFloatLayer && dataLayer === "soilMap" && layer === "soilMap_texture") {
      if (group.idx === 0) {
        label = "Lättare jord"
      } else if (group.idx === (numberOfGroups - 1)) {
        label = "Tyngre jord"
      } else {
        label = null
      };
    };


    // Greenmass
    if (isFloatLayer && dataLayer === "greenmass") {
      if (group.idx === 0) {
        label = "Glesare grönmassa"
      } else if (group.idx === (numberOfGroups - 1)) {
        label = "Tätare grönmassa"
      } else if (label === 2) {
        label = "Genomsnittlig grönmassa"        
      } else {
        label = null
      };
    };
    
    // Moisture
    if (isFloatLayer && dataLayer === "moisture") {
      if (group.idx === 0) {
        label = "Torrare jord"
      } else if (group.idx === (numberOfGroups - 1)) {
        label = "Blötare jord"
      } else if (label === 2) {
        label = "Genomsnittlig fuktighet"        
      } else {
        label = null
      };
    };  

    // yieldMap
    if (isFloatLayer && ["yieldMap", "yieldStatistics"].includes(dataLayer)) {
      label = group.max
      ? group.min.toFixed(1) + " - " + group.max.toFixed(1) + " ton/ha"
      : group.min ? group.min.toFixed(1) + " ton/ha" : "";
    };

    if (isPercentLayer || isDifferenceLayer) {
      label = group.max
        ? group.min.toFixed(0) + "% till " + group.max.toFixed(0) + "%"
        : group.min ? group.min.toFixed(0) + "%" : "";
    };    

    // Analysis
  
    switch (layer) {
      case "yieldAnalysis_potential":
      case "yieldAnalysis_potentialWinterWheat":

        let yieldPotentialStats = statJson && 
          statJson.yield_potential && 
          statJson.yield_potential_winter_wheat && 
          (layer === "yieldAnalysis_potentialWinterWheat" ? statJson.yield_potential_winter_wheat : statJson.yield_potential);
        
        let upperCI = yieldPotentialStats && yieldPotentialStats.high_ci && yieldPotentialStats.high_ci; 
        let maxValue = upperCI && upperCI[group.idx];
        label = maxValue && ("Upp till " + maxValue.toFixed(1) + " ton/ha");
        break;

      case "yieldAnalysis_clustering":
      case "greenmassAnalysis_clustering":
      case "moistureAnalysis_clustering":
      case "greenmassAnalysis_clustering_perYear":
      case "moistureAnalysis_clustering_perYear":
        label = "Område " + (1.0 + group.idx).toFixed(0); 
        break;         

      case "greenmassAnalysis_segmentationMedian":
        label = ["Glest", "Normalt", "Tätt"][parseInt(group.idx)];
        break;

      case "moistureAnalysis_segmentationMedian":
        label = ["Torrt", "Normalt", "Blött"][parseInt(group.idx)];
        break;

      case "yieldAnalysis_segmentationMedian":
        label = ["Låg skörd", "Snittskörd", "Hög skörd"][parseInt(group.idx)];
        break;            

      case "yieldAnalysis_segmentationVariance":
        label = [
          "Normal variation",
          "Underpresterar",
          "Överpresterar",
          "Stor variation"
        ][parseInt(group.idx)];
        break;

      case "greenmassAnalysis_segmentationVariance":
        label = [
          "Normal variation",
          "Ofta glesare",
          "Ofta tätare",
          "Stor variation"
        ][parseInt(group.idx)];
        break;

      case "moistureAnalysis_segmentationVariance":
        label = [
          "Normal variation",
          "Ofta torrare",
          "Ofta blötare",
          "Stor variation"
        ][parseInt(group.idx)];
        break;

      default:
        break;
    };

    return label;
  };

  render() {
    const {
      field,
      onSelectGroup,
      selectedGroup,
      dataStats,
      numberOfGroups,
      polygonImage,
    } = this.props;
    
    let fieldSize = field && field.field_size && field.field_size;
    fieldSize = fieldSize ? fieldSize : (field && field.field_size_image ? field.field_size_image : 0.0);
    
    let totSamples = dataStats && dataStats.groups && dataStats.groups.reduce((tot, x) => x.samples.length + tot, 0);

    if (polygonImage) {
      return null;
    }

    return (
      dataStats && (
        <div
          style={{
            position: "absolute",
            bottom: 60,
            left: 0,            
            zIndex: 500,
            background: "rgba(0, 0, 0, 0.8)",
            borderRadius: 8,
            padding: "1em",
          }}
        >
          <Grid>
            <Grid.Row
              style={{
                margin: 10,
                marginBottom: -10,
                padding: 0,
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Header
                as="h4"
                style={{
                  marginTop: 0,
                  color: "white",
                }}
              >
                Områden
              </Header>

              <Icon
                name="close"
                style={{
                  color: "white",
                  marginRight: "0.5em",
                  cursor: "pointer"
                }}
                onClick={this.props.toggleShowMapLegendGroups}
              />
            </Grid.Row>

            {dataStats.groups.map((group, groupIdx) => {

                return (
                  <Grid.Row
                    key={"legend_row_" + groupIdx}
                    onClick={() => {
                      onSelectGroup(groupIdx);
                    }}
                    className="opacityHover"
                    style={{
                      cursor: "pointer",
                      opacity: groupIdx === selectedGroup ? 1 : "",
                      color: "white",
                      paddingBottom: null,
                      marginBottom: null,
                    }}
                  >
                    <Grid.Column width={5}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div
                          style={{
                            background:
                              selectedGroup !== null
                                ? groupIdx === selectedGroup
                                  ? group.color
                                  : "grey"
                                : group.color,
                            height: 40,
                            width: 40,
                          }}
                        />
                      </div>
                    </Grid.Column>

                    <Grid.Column width={11}>
                      <Grid.Row>
                        <Header
                          as="h4"
                          style={{
                            color: this.groupLabel(group, numberOfGroups) ? "white" : "transparent",
                          }}
                        >
                          {this.groupLabel(group, numberOfGroups) && this.groupLabel(group, numberOfGroups)}
                          {!this.groupLabel(group, numberOfGroups) && "..."}
                        </Header>
                      </Grid.Row>

                      <Grid.Row>
                        <Icon name="percent" />
                        {((100.0 * group.samples.length) / totSamples).toFixed(0)}
                        <Icon style={{ marginLeft: "1em" }} name="chart area" />
                        {((fieldSize * group.samples.length) / totSamples).toFixed(1) + " ha"}
                      </Grid.Row>

                    </Grid.Column>

                  </Grid.Row>
                );
              })}
          </Grid>
        </div>
      )
    );
  }
}

export default MapLegendGroups;
