import React from 'react';

import { 
  Loader, 
  Image,
} from 'semantic-ui-react';

import illustration from "@/assets/illustrations/onboarding_guide_1.png"

function LoadingScreen() {
  return (
    <div 
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",        
        backgroundColor: '#f7f4f0',        
      }}
    >
      <div
        style={{
          width: "85%",
        }}
      >
        <Image
          style={{
            display: "block",
            marginBottom: "2em",
          }}
          src={illustration}
        />
          
        <Loader
          active
          inline
          indeterminate
          size='medium'        
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}        
        />
      </div>
    </div>
  )
}

export default LoadingScreen;
