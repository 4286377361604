import React, { Component } from 'react';

import { 
  Menu,
  Icon,
} from 'semantic-ui-react';

const INITIAL_STATE = {};

class FieldDataLayerMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render() {
    const { 
      mode
    } = this.props;

    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100vw",
          zIndex: 8000,
        }}
      >
        <Menu
          pointing
          fluid
          secondary
          icon='labeled'
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            margin: 0,
            borderRadius: 0,
            border: "0px !important",
            textAlign: "center",
            backgroundColor: "#363e40",
            width: "100vw",
          }}
          widths={2}
        >
          <Menu.Item
            as="div"
            style={{
              color: mode === "map" ? "rgb(0, 0, 0, 0.85)" : "white",
              background: mode === "map" ? "#F8EED0" : "transparent",
              borderRadius: 0,
              borderTop: mode === "map" ? "4px solid rgba(234, 204, 116, 1.0)" : null,
              fontSize: "70%",
              fontWeight: 500,
            }}
            active={mode === "map"}
            onClick={() => this.props.openLayer("map")}
          >
            <Icon
              centered
              name="map outline"
              style={{
                width: "2.25em",
                color: mode === "map" ? "black" : "white",
                marginBottom: 6,
              }}
            />
            
            Karta
          </Menu.Item>

          <Menu.Item
            as="div"
            style={{
              color: mode === "list" ? "rgb(0, 0, 0, 0.85)" : "white",
              background: mode === "list" ? "#F8EED0" : "transparent",
              borderRadius: 0,
              borderTop: mode === "list" ? "4px solid rgba(234, 204, 116, 1.0)" : null,
              fontSize: "70%",
              fontWeight: 500,
            }}
            active={mode === "list"}
            onClick={() => this.props.openLayer("list")}
          >
            <Icon
              centered
              name="unordered list"
              style={{
                width: "2.25em",
                color: mode === "list" ? "black" : "white",
                marginBottom: 6,
              }}
            />
            
            Lista
          </Menu.Item>          
        </Menu>
      </div>
    )
  }
};

export default FieldDataLayerMenu;

