import React, { Component } from 'react';
import { groupLabelGenerator } from "./groupLabelGenerator";

class MapColorScaleBar extends Component {

  render () {

    const {
      layer,
      colorScale,
      dataStats,
    } = this.props;

    let barLabelMin = dataStats && groupLabelGenerator({...this.props, group: dataStats.groups[0], onlyMin: true});
    let barLabelMax = dataStats && groupLabelGenerator({...this.props, group: dataStats.groups[dataStats.groups.length - 1], onlyMax: true});

    
    let gradient = {}
    colorScale && colorScale.colorScale && colorScale.colorLimits.forEach((x, idx) => {
      let noColors = colorScale.colorLimits.length - 1;
      let percentIndex = parseInt((100.0 * idx / noColors).toFixed(0));
      let colors = colorScale.colorScale(x)._rgb;

      gradient = {...gradient,
        [percentIndex]: {
          r: colors[0],
          g: colors[1],
          b: colors[2],
        }
      }
    })

    const gradientColors = Object.keys(gradient).map(key => {
      const rgb = gradient[key];
      return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}) ${key}%`;
    }).join(',');    

    let hideColorBar = [
      'trueColor',
      'yieldAnalysis_potential',
      'yieldAnalysis_potentialWinterWheat',
      'yieldAnalysis_clustering',
      'yieldAnalysis_segmentationMedian',
      'yieldAnalysis_segmentationVariance',
      'greenmassAnalysis_clustering',
      'greenmassAnalysis_clustering_perYear',
      'moistureAnalysis_clustering',
      'moistureAnalysis_clustering_perYear',
    ];

    if (hideColorBar.includes(layer)) {
      return null
    }    

    return (
        <div
          style={{
            position: "absolute",
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            left: "30vw",
            bottom: "70px",
            padding: 12,
            border: "none !important",
          }}
          onClick={() => this.props.toggleShowMapLegendGroups()}
        >
          <div 
            style={{
              color: "white",
              fontSize: "0.8em",
              position: "absolute",
              left: 5,
              top: 2,
            }}
          >
            {barLabelMin && barLabelMin}
          </div>
          <div 
            style={{
              color: "white",
              fontSize: "0.8em",
              position: "absolute",
              right: 5,
              top: 2,
            }}    
          >
            {barLabelMax && barLabelMax}
          </div>

          <div
            style={{
              display: "inline-block",
              marginTop: 10,
              width: "40vw",
              height: "20px",
              background: `linear-gradient(to right, ${gradientColors})`
            }}
          />

        </div>
    );
  }
};

export default MapColorScaleBar;

