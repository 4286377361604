import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  firestoreConnect,
} from "react-redux-firebase";

import {
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import { getSafeFileName } from "@/helpers/dataHandling";
import { positionToGeoPoint } from "@/helpers/db";

import { GEOTAG_TYPES } from "@/constants/geotags";

import AddGeoTagGuide from "@/components/geotags/AddGeoTagGuide";
import AddReminderGuide from "@/components/reminders/AddReminderGuide";

import ActionDone from "@/components/modals/ActionDone";
import Page from "@/components/misc/Page";

const INITIAL_STATE = {
  showActionDone: false,
  actionDoneInfo: null,
  error: null,
  showWorkingDimmer: false,
  workingDimmerText: ''
};

class AddPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  getDb = () => this.props.firestoreData;

  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  showActionDone = (actionDoneInfo) => {
    this.setState({
      showActionDone: true,
      actionDoneInfo: actionDoneInfo
    })
  };

  getWorkingDimmer = (text = 'Arbetar...') => (
    <Dimmer active inverted>
      <Loader style={{ paddingBottom: '8em' }} />
      <p style={{ color: "#000000" }}>{text}</p>
    </Dimmer>
  );

  updateGeoTag = (tagId, newTag, imagesToUpload, imagesToRemove) => {
    const {
      userId,
      trueUserId,
    } = this.props;

    let allPromises = [];
    let filePathsToAdd = [];
    let filePathsToRemove = [];

    let db = this.getDb();
    let fileBucketRef = this.props.firebase.storage().ref();

    this.setState({
      showWorkingDimmer: true,
      workingDimmerText: 'Uppdaterar observation...'
    });

    imagesToUpload && imagesToUpload.forEach((imageToUpload) => {
      let safeFileName = getSafeFileName(imageToUpload.name);
      let filePath = "users/" + userId + "/observations/" + Date.now() + "_" + safeFileName;

      filePathsToAdd.push(filePath);
      allPromises.push(fileBucketRef.child(filePath).put(imageToUpload));
    });

    imagesToRemove && imagesToRemove.forEach((imageToRemove) => {
      let filePath = "users" + imageToRemove.split("/users")[1].split("?alt=")[0];
      filePath = filePath && filePath.replaceAll("%2F", "/");

      filePathsToRemove.push(filePath);
      fileBucketRef.child(filePath).delete();
    })

    let currentTag = db && tagId && db.observations && db.observations[tagId];

    let newPhotos = filePathsToAdd;
    newPhotos = currentTag && currentTag.photos && currentTag.photos.length > 0 ?
      [...currentTag.photos.filter((x) => !filePathsToRemove.includes(x)), ...newPhotos] :
      newPhotos;

    let formattedNewTag = {
      entries: [
        {
          amount: newTag && newTag.amount ? newTag.amount : '',
          date: new Date(),
          note: newTag && newTag.note ? newTag.note : '',
          radius: newTag && newTag.radius ? newTag.radius : '',
          user_id: trueUserId,
        }
      ],
      position: newTag && newTag.position && positionToGeoPoint(
        newTag.position,
        this.props.firestore.GeoPoint
      ),
      header: newTag.header ? newTag.header : '',
      tag_main_type: newTag && newTag.mainType,
      tag_sub_type: newTag && newTag.subType ? newTag.subType : '',
      user_id: trueUserId,
      field_id: newTag && newTag.fieldId ? newTag.fieldId : currentTag.field_id,
      photos: newPhotos,
      date_latest_update: new Date(),
    };

    allPromises.push(this.getUser().collection("observations").doc(tagId).update(formattedNewTag));

    Promise.all(allPromises)
      .then((results) => {
        this.setState({
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'success',
          text: 'Observation uppdaterad!',
          pushUrl: "/geotags/" + formattedNewTag.fieldId + "/details/" + tagId,
          pushState: { returnTo: "home" },
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.toString(),
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'failure',
          text: 'Tyvärr, något gick fel! Försök igen eller kontakta supporten.',
          errorMessage: error.toString(),
          pushUrl: "/",
        })
      });
  };

  saveNewGeoTag = (newTag, imagesToUpload) => {
    const {
      userId,
      trueUserId,
    } = this.props;

    let allPromises = [];
    let filePaths = [];

    let fileBucketRef = this.props.firebase.storage().ref();

    this.setState({
      showWorkingDimmer: true,
      workingDimmerText: 'Sparar observation...'
    });

    imagesToUpload && imagesToUpload.forEach((imageToUpload) => {
      let safeFileName = getSafeFileName(imageToUpload.name);
      let filePath = "users/" + userId + "/observations/" + Date.now() + "_" + safeFileName;

      filePaths.push(filePath);
      allPromises.push(fileBucketRef.child(filePath).put(imageToUpload));
    });

    const formattedNewTag = {
      entries: [
        {
          amount: newTag.amount ? newTag.amount : '',
          date: new Date(),
          note: newTag.note ? newTag.note : '',
          radius: newTag.radius ? newTag.radius : '',
          user_id: trueUserId,
        }
      ],
      position: positionToGeoPoint(
        [newTag.position.lat, newTag.position.lng],
        this.props.firestore.GeoPoint
      ),
      header: newTag.header ? newTag.header : '',
      tag_main_type: newTag.mainType,
      tag_sub_type: newTag.subType ? newTag.subType : '',
      user_id: trueUserId,
      field_id: newTag.fieldId,
      photos: filePaths ? filePaths : [],
      date_created: new Date(),
      date_latest_update: new Date(),
    };

    allPromises.push(this.getUser().collection("observations").add(formattedNewTag));

    Promise.all(allPromises)
      .then((results) => {
        let observationId = results && results.length > 0 &&
          results[results.length - 1] && results[results.length - 1].id;

        this.setState({
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.saveReminder(newTag, observationId)

        this.showActionDone({
          outcome: 'success',
          text: 'Observation skapad!',
          pushUrl: "/geotags/" + newTag.fieldId + "/details/" + observationId,
          pushState: { returnTo: "home" },
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.toString(),
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'failure',
          text: 'Tyvärr, något gick fel! Försök igen eller kontakta supporten.',
          errorMessage: error.toString(),
          pushUrl: "/",
        })
      });
  };

  saveReminder = (newTag, observationId) => {
    if (!newTag || !newTag.reminder || !newTag.reminder.condition) {
      return;
    };

    const db = this.getDb();

    let fieldId = newTag && newTag.fieldId;
    let fieldName = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name;

    let mainType = newTag && newTag.mainType;
    let subType = newTag && newTag.subType;

    subType = mainType && subType && GEOTAG_TYPES[mainType] && GEOTAG_TYPES[mainType].subTypes && GEOTAG_TYPES[mainType].subTypes[subType];

    mainType = mainType && GEOTAG_TYPES[mainType].title;
    subType = subType && subType.title;

    let observationType = mainType && subType && mainType + "(" + subType + ")";
    observationType = observationType && observationType.toLowerCase();

    let payload = {
      ...newTag.reminder,
      field_id: fieldId,
      field_name: fieldName ? fieldName : '',
      observation_id: observationId,
      observation_type: observationType ? observationType : '',
    };

    this.getUser().collection("reminders").doc(observationId).set(payload);
  };

  saveNewReminder = (newReminder) => {
    const {
      trueUserId,
    } = this.props;

    this.setState({
      showWorkingDimmer: true,
      workingDimmerText: 'Sparar aktivitet...'
    });

    const formattedNewReminder = {
      user_id: trueUserId,
      date_created: new Date(),
      date_latest_update: new Date(),
      ...newReminder
    };

    this.getUser().collection("reminders").add(formattedNewReminder)
      .then(() => {

        this.setState({
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'success',
          text: 'Aktivitet sparad!',
          pushUrl: "/reminders",
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.toString(),
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'failure',
          text: 'Tyvärr, något gick fel! Försök igen eller kontakta supporten.',
          errorMessage: error.toString(),
          pushUrl: "/",
        })
      });
  };

  updateReminder = (newReminder, reminderId) => {
    const {
      trueUserId,
    } = this.props;

    this.setState({
      showWorkingDimmer: true,
      workingDimmerText: 'Uppdaterar aktivitet...'
    });

    const formattedNewReminder = {
      user_id: trueUserId,
      date_created: new Date(),
      date_latest_update: new Date(),
      ...newReminder
    };

    this.getUser().collection("reminders").doc(reminderId).set(formattedNewReminder)
      .then(() => {

        this.setState({
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'success',
          text: 'Aktivitet uppdaterad!',
          pushUrl: "/reminders",
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.toString(),
          showWorkingDimmer: false,
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'failure',
          text: 'Tyvärr, något gick fel! Försök igen eller kontakta supporten.',
          errorMessage: error.toString(),
          pushUrl: "/",
        })
      });
  };

  render() {
    const {
      match: {
        params: {
          addType,
          addId,
          addStep,
        }
      },
    } = this.props;

    const {
      showWorkingDimmer,
      workingDimmerText,
      showActionDone,
      actionDoneInfo
    } = this.state;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    return (
      <React.Fragment>
        <Page
          style={{
            paddingBottom: "12em"
          }}
        >
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            {addType === "addObservation" &&
              <AddGeoTagGuide
                db={db}
                fileBucketRef={fileBucketRef}
                guideMode="addObservation"
                tagId={addId}
                addStep={addStep}
                getUser={this.getUser}
                saveNewGeoTag={this.saveNewGeoTag}
              />
            }

            {addType === "editObservation" &&
              <AddGeoTagGuide
                db={db}
                fileBucketRef={fileBucketRef}
                guideMode="editObservation"
                tagId={addId}
                addStep={addStep}
                getUser={this.getUser}
                updateGeoTag={this.updateGeoTag}
              />
            }

            {addType === "addReminder" &&
              <AddReminderGuide
                db={db}
                guideMode="addReminder"
                reminderId={addId}
                saveNewReminder={this.saveNewReminder}
              />
            }

            {addType === "editReminder" &&
              <AddReminderGuide
                db={db}
                guideMode="editReminder"
                reminderId={addId}
                updateReminder={this.updateReminder}
              />
            }

          </div>
        </Page>

        {showWorkingDimmer && this.getWorkingDimmer(workingDimmerText)}

        {showActionDone &&
          <ActionDone
            isOpen={true}
            info={actionDoneInfo}
            hideModal={() => this.setState({ showActionDone: false })}
          />
        }
      </React.Fragment>
    )
  }
}

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },
    {
      collection: 'users',
      doc: `${props.userId}`,
      subcollections: [
        { collection: "observations" },
      ],
      storeAs: 'observations'
    },
  ]),
  withFirestoreData,
  withRouter
)(AddPage);