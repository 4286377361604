import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import {
  firestoreConnect,
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import {
  Button, 
} from "semantic-ui-react";

import FieldsWidget from "@/components/home/FieldsWidget";
import HomeBarWidget from "@/components/home/HomeBarWidget";

import LoaderModal from "@/components/modals/LoaderModal";

import TutorialModal from "@/components/modals/TutorialModal";

import WeatherForecastAndSummaryWidget from "@/components/weather/WeatherForecastAndSummaryWidget";
import WeatherSeasonSummaryWidget from "@/components/weather/WeatherSeasonSummaryWidget";

const INITIAL_STATE = {
  handlingMessagingToken: null,
  messagingToken: null,
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    const {
      db,
      isAdminUser,
    } = this.props;

    let profileLog = db && db.profile && 
      db.profile.log && isLoaded(db.profile.log) 
      && db.profile.log;

    !isAdminUser && profileLog && !profileLog.date_first_login_mobile &&
      this.getUser().collection("profile").doc("log")
      .update({
        date_first_login_mobile: new Date(),
    });

    !isAdminUser && 
      this.getUser().collection("profile").doc("log")
      .update({
        date_latest_login_mobile: new Date(),
    });    
  };

  getMessagingToken = () => {
    this.setState({ handlingMessagingToken: "running" })

    this.props.firebase &&
    this.props.firebase.messaging &&
    this.props.firebase.messaging().requestPermission()
      .then(async res => {
        let newValue = await this.props.firebase.messaging().getToken();
        console.log(newValue)

        this.getUser().collection("settings").doc("tokens").set({
          mobile_messaging: this.props.firestore.FieldValue.arrayUnion(newValue),
          latest_update: new Date(),
        }, { merge: true })

        this.setState({ handlingMessagingToken: "done" })
      })
      .catch((err) => {
        this.setState({ handlingMessagingToken: "error" })
        console.log("Unable to get permission to notify.", err);
      });

    navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
  };  

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  render() {
    const {
      isImpersonatingUser,
    } = this.props;
    
    const {
      handlingMessagingToken,
    } = this.state;

    const db = this.getDb();
    let weatherData = db && db.fieldsWeather;

    let hasLoadedData = isLoaded(db) && !isEmpty(db);
    let fileBucketRef = this.props.firebase.storage().ref();

    let buttonIcon = null;
    buttonIcon = handlingMessagingToken === "running" ? "spinner" : buttonIcon;
    buttonIcon = handlingMessagingToken === "error" ? "close" : buttonIcon;
    buttonIcon = handlingMessagingToken === "done" ? "check" : buttonIcon;

    let buttonColor = "#e0ba8c";
    buttonColor = handlingMessagingToken === "error" ? "#C36126" : buttonColor;
    buttonColor = handlingMessagingToken === "done" ? "#4C8440" : buttonColor;

    if (hasLoadedData) {
      return (
        <Page>
          <TutorialModal
            hidden={isImpersonatingUser}
            db={db} 
            getUser={this.getUser} 
            tutorialName="MobileHomeFirstLaunchV1"
          >
            <div
              style={{
                width: "100%",          
              }}
            >
              <Button
                icon={buttonIcon !== null}
                labelPosition={buttonIcon !== null ? "left" : null}
                style={{
                  backgroundColor: buttonColor,
                  color: "white",
                  width: "50%", 
                  marginLeft: "25%",
                  marginRight: "25%",    
                  marginTop: "2em",               
                }}
                loading={handlingMessagingToken === "running"}
                onClick={() => this.getMessagingToken()}            
              >
                Tillåt notiser
              </Button>
            </div>            
          </TutorialModal>

          <div
            style={{
              width: "calc(100vw - 20)",
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
            }}
          >
            <HomeBarWidget db={db} />
            <WeatherForecastAndSummaryWidget db={db} fileBucketRef={fileBucketRef} />
            <WeatherSeasonSummaryWidget weatherData={weatherData} fileBucketRef={fileBucketRef} />
            <FieldsWidget db={db} />
          </div>          
        </Page>
      );
    } else {
      return (
        <LoaderModal caption="Laddar..." />
      );
    }
  }
}

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "dashboard" },
      ],
      storeAs: 'dashboard'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fieldsWeather", doc: "farm" },
      ],
      storeAs: 'fieldsWeather'
    },       
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },    
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "settings" },
      ],
      storeAs: 'settings'
    },   
  ]),
  withFirestoreData,
  withRouter
)(HomePage);
