import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from "react-router-dom";

import {
  firestoreConnect,
  isLoaded,
  isEmpty, 
} from 'react-redux-firebase';

import {
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import Page from '@/components/misc/Page';

import FieldDataViewer from '@/components/fieldData/FieldDataViewer';

import MenuFieldList from '@/components/menus/MenuFieldList';
import MenuTop from "@/components/menus/MenuTop";

const INITIAL_STATE = {};

class FieldDataPage extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getDb = () => this.props.firestoreData;
  
  getUser = () => this.props.firestore.collection('users').doc(this.props.userId);

  openField = (newFieldId) => {
    this.props.history.push("/layers/" + newFieldId + "/greenmass");
  };

  openLayer = (type, idx) => {
    const {
      match: { params },
    } = this.props;

    this.props.history.replace("/layers/" + params.fieldId + "/" + type + (idx ? "/" + idx : ""));
  };

  closeField = () => {
    this.props.history.goBack();
  };

  onClickGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      match: {
        params: { fieldId, layer, layerId }
      },
    } = this.props;

    const db = this.getDb();
    let fields = db && isLoaded(db) && db.fields;
    let fieldsInfo = db && isLoaded(db) && db.fieldsInfo;

    const processedYieldMaps = db && db["fieldsYieldMapsProcessed_" + fieldId];
    const processedSentinelMaps = db && db["fieldsSentinelHubProcessed_" + fieldId];

    let hasLoadedData = isLoaded(db) && !isEmpty(db) && isLoaded(db.fields) && !isEmpty(db.fields);

    let fileBucketRef = this.props.firebase.storage().ref();

    let menuCaption = "Utforska";
    menuCaption = (layer && layer === "greenmass") ? menuCaption + " grönmassa" : menuCaption;
    menuCaption = (layer && layer === "yieldMap") ? menuCaption + " skördekarta" : menuCaption;
    menuCaption = (layer && layer === "trueColor") ? menuCaption + " satellitbild" : menuCaption;

    if (hasLoadedData) {
      return (
        <Page
          style={{
            paddingBottom: 0,
          }}
        >
          {!fieldId &&
            <MenuTop
              caption={menuCaption}
              onClickGoBack={this.onClickGoBack}
            />
          }

          {!fieldId &&
            <MenuFieldList
              db={db}
              userId={this.props.userId}
              fieldId={null}
              compact={false}
              hideFarmCard={true}
              hideLockedFields={true}
              hideUnLockedFields={false}
              openField={this.openField}
            />
          }

          {fieldId &&
            <FieldDataViewer
              db={db}
              userId={this.props.userId}
              fileBucketRef={fileBucketRef}
              field={fields && fields[fieldId]}
              fieldInfo={fieldsInfo && fieldsInfo[fieldId]}
              fieldId={fieldId}
              layer={layer}
              layerId={layerId}
              openLayer={this.openLayer}
              closeField={this.closeField}
              processedYieldMap={processedYieldMaps}    
              processedSentinelMap={processedSentinelMaps}
              markerScale={1.0}
              lockedMap={true}
              setContainerState={(newState) => this.setState(newState)}
              cacheFieldData={this.props.cacheFieldData}
              updateCacheFieldData={this.props.updateCacheFieldData}
           />
          }    
        </Page>
      );
    } else {
      return (
        <Page>
          <Dimmer active inverted>
            <Loader style={{paddingBottom: '8em'}} />
            <p style={{color: "#000000"}}>Laddar skiftesdata...</p>
          </Dimmer>
        </Page>
      )
    }
  }
}

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {
    const {
      match: {
        params: { fieldId }
      },
    } = props;

    let results = [
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fieldsInfo" },
        ],
        storeAs: 'fieldsInfo'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },           
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "settings", doc: "bookmarks", subcollections: [{ collection: "fieldData" }] },
        ],
        storeAs: 'bookmarks'
      },          
    ];

    if (fieldId && fieldId !== '' && fieldId !== "farm") {
      results = [...results,
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsSoilMapsProcessed", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsSoilMapsProcessed_' + fieldId
        },
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsYieldMapsProcessed", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsYieldMapsProcessed_' + fieldId
        },  
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsSentinelHubRaw", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsSentinelHubRaw_' + fieldId
        },  
        { 
          collection: 'users', 
          doc: `${props.userId}`, 
          subcollections: [
            { collection: "fieldsSentinelHubProcessed", doc: `${fieldId}` },
          ],
          storeAs: 'fieldsSentinelHubProcessed_' + fieldId
        },
      ];
    };

    return results
  }),
  withFirestoreData,
  withRouter
)(FieldDataPage);