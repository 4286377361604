import React, { Component } from "react";

import {
  Segment, 
  Grid,
} from "semantic-ui-react";

import ForecastData from '@/components/weather/ForecastData'

import moment from 'moment';

const INITIAL_STATE = {};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class WeatherForecastWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, leftArrowState: "disabled", rightArrowState: "" };
  }

  render() {
    const {
      db
    } = this.props;

    // Get max and min temp to set chart min max
    let minTemp = +Infinity
    let maxTemp = -Infinity
    let currentMin;
    let currentMax;

    //Could be moved serverside
    for (let forecastOffset = 0; forecastOffset <= 2; forecastOffset++) {
      const forecastDate = moment().add(forecastOffset, 'days').format('YYYY-MM-DD')

      let forecastTemps =
        db &&
        db.fieldsWeather &&
        db.fieldsWeather.forecasts &&
        db.fieldsWeather.forecasts[forecastDate] &&
        db.fieldsWeather.forecasts[forecastDate]["interpolated_forecast_temps"];

      let currentTemps = forecastTemps && Object.values(forecastTemps)
      currentMin = currentTemps && Math.min(...currentTemps)
      currentMax = currentTemps && Math.max(...currentTemps)

      if (currentMin < minTemp) minTemp = currentMin
      if (currentMax > maxTemp) maxTemp = currentMax
    }

    let nVertical = 3

    let dataComponent = () => {

      let forecastComponents = []
      for (var i = 0; i <= nVertical - 1; i++) {
        forecastComponents.push(
          <Grid.Column>
            <ForecastData 
              db={db} 
              forecastOffset={i} 
              relativeDate={true} 
            />
          </Grid.Column>
        )
      }
      return (
        <Grid 
          columns={nVertical} 
          style={{
            marginTop: "1em",
            marginBottom: 0,
            fontWeight: 600,
            fontSize: "160%",
            padding: 0,
          }}
        >
          {forecastComponents}
        </Grid>)

    }

    return (
      <ConditionalWrapper
        condition={!this.props.renderAsInnerComponent}
        wrapper={children => <Segment style={{ padding: "2em" }}>{children}</Segment>}
      >
        {dataComponent()}

      </ConditionalWrapper>
    )
  }
}

export default WeatherForecastWidget;
