import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import { 
  Icon, 
  Image,
  Header, 
  Segment,
} from 'semantic-ui-react';

import MenuTop from "@/components/menus/MenuTop";
import TermsList from '@/components/misc/TermsList';

const INITIAL_STATE = {};

const MenuItem = ({id, title, description, onClick, href, icon, img, target, rel}) => (
  <Segment
    basic
    style={{
      width: "100%",
      padding: "1.25em",
      marginTop: 5,
      marginBottom: 5,
      backgroundColor: "white",
    }}
    key={`div_${id}`}
    onClick={() => onClick && onClick(id)}
    href={href && href}
    target={target && target}
    rel={rel && rel}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >

      <div 
        style={{ 
          width: "10%",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {img && 
          <Image 
            style={{ 
              height: "1.75em",
              marginTop: "auto",
              marginBottom: "auto",
            }} 
            src={img} 
          />
        }
        
        {icon && 
          <Icon 
            style={{ 
              fontSize: "1.75em",
              marginTop: 5,
              color: "grey",
            }}           
            name={icon} 
          />
        }
      </div>

      <div 
        style={{ 
          width: "80%", 
          marginLeft: 5,  
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Header
            as='h4'
            key={`title_${id}`}
            style={{
              marginBottom: '0em',
              fontWeight: 500,
            }}
        >
            {title}
        </Header>

        <Header
            as='h5'
            color='grey'
            style={{
              marginTop: '0em', 
              marginBottom: '0em', 
              fontWeight: 'normal'
            }}
        >
            {description}
        </Header>       
      </div>

      <div 
        style={{ 
          width: "10%",
          textAlign: "right",
          marginTop: "auto",
          marginBottom: "auto",
          }}
        >
          <Icon
            name="chevron right"
            color='grey'
          />
      </div>
    </div>
  </Segment>
)

class LegalPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection('users').doc(this.props.userId);

  onClickSubMenuItem = (newPage) => {
    this.props.history.push(newPage);
  };

  returnToMenu = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      match: {
        params: { subMenu }
      }
    } = this.props;

    switch(subMenu) {
      case 'third-party-licences':
        return <div>
            <MenuTop caption={"Tredjepartslicenser"} onClickGoBack={this.props.history.goBack} />    
            <TermsList mode={subMenu} />
          </div>

      case 'user-terms':
      return <div>
          <MenuTop caption={"Användarvillkor"} onClickGoBack={this.props.history.goBack} />    
          <TermsList mode={subMenu} />
        </div>

      case 'privacy':
        return <div>
            <MenuTop caption={"Integritetspolicy"} onClickGoBack={this.props.history.goBack} />    
            <TermsList mode={subMenu} />
          </div>      

      default:
        return (
          <Page>
            <div
              style={{
                width: "calc(100vw - 20)",
                marginTop: 70,
                marginLeft: 10,
                marginRight: 10,              
              }}
            >
              <MenuTop caption="Juridisk information" onClickGoBack={this.props.history.goBack} />
              
              <MenuItem 
                id="legal/user-terms"
                icon="legal"
                title="Användarvillkor"
                description="Läs det finstilta men viktiga."
                onClick={this.onClickSubMenuItem}
              />
      
              <MenuItem 
                id="legal/privacy"
                icon="privacy"
                title="Integritetspolicy"
                description="Läs hur vi skyddar dina personuppgifter."
                onClick={this.onClickSubMenuItem}
              />
      
              <MenuItem 
                id="legal/third-party-licences"
                icon="code branch"
                title="Tredjepartslicenser"
                description="De som har hjälpt oss att bygga appen."
                onClick={this.onClickSubMenuItem}
              />
            </div>
          </Page>
        )
    }
  }
}

export default withRouter(LegalPage);
