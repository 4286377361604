import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import {
  Map as LeafletMap,
  TileLayer,
  Polygon,
  CircleMarker,
  ImageOverlay,
  Tooltip,
  FeatureGroup,
} from "react-leaflet";

import {
  Loader,
  Dimmer,
} from "semantic-ui-react";

import overlayUrl from "@/assets/backgrounds/transparent_bg.png";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";
import { AREAL_TILE_URL } from "@/constants/map";

import { geoPointToPosition } from "@/helpers/db";
import { getFieldMapOptions } from "@/helpers/maps";

import "./style.css"

class MapWithCircleMarkers extends Component {

  state = {
    mouseOverPoint: null,
  };

  isPointSelected = (pointArray, point) => (pointArray && point && Array.isArray(pointArray) && pointArray.includes(point.idx));

  getMarker = (marker, idx, screenWidth) => {
    const {
      userId,
      fieldId,
      layer,
      layerId,
      markers,
      colorScale,
      markerScale,
      onMarkerClick,
      selectedMarker,
      geoJsonFieldKey,
      showMissingData,
      onHoverPopup,
    } = this.props;

    let markerRadius = screenWidth < 1600 ? markerScale * 3 : markerScale * 5;
    markerRadius = this.isPointSelected(selectedMarker, marker) ? 2 * markerRadius : markerRadius;

    let markerWeight = screenWidth < 1600 ? 1 : 2;
    markerWeight = this.isPointSelected(selectedMarker, marker) ? 2 * markerWeight : markerWeight;

    let markerValue = geoJsonFieldKey && marker && marker.properties && marker.properties[geoJsonFieldKey];

    if (typeof(markerValue) !== "number") {
      return null
    };

    let markerColorFill = colorScale && colorScale(markerValue)._rgb;
    markerColorFill = markerColorFill && 'rgb(' + markerColorFill[0] + ',' + + markerColorFill[1] + ',' + markerColorFill[2] + ')';

    let markerColorEdge = markerColorFill;
    markerColorEdge = this.isPointSelected(selectedMarker, marker) ? "rgba(251, 189, 8, 1.0)" : markerColorEdge;

    let hasMissingData = marker.properties && marker.properties['missing_data'] && marker.properties['missing_data'] === 1.0;
    let markerOpacity = (showMissingData && hasMissingData) ? 0.4 : 1.0;
    markerOpacity = markers.includes(marker) ? markerOpacity : 0.15;

    if (hasMissingData && (!showMissingData || showMissingData === false)) {
      return (
        <CircleMarker
          key={"marker_" + userId + "_" + fieldId + "_" + layer + "_" + layerId + "_" + idx}
          className="map-circle-marker"
          center={{
            lat: marker.geometry.coordinates[0],
            lng: marker.geometry.coordinates[1]
          }}
          color="grey"
          opacity={0.25}
          fill={false}
          weight={1.0}
          radius={markerRadius}
          zIndex={1000}
        />
      )
    } else {
      return (
        <CircleMarker
          key={"marker_" + userId + "_" + fieldId + "_" + layer + "_" + layerId + "_" + idx}
          className="map-circle-marker"
          onClick={e => {
            e.originalEvent.stopPropagation();
            onMarkerClick(marker.idx);
          }}
          center={{
            lat: marker.geometry.coordinates[0],
            lng: marker.geometry.coordinates[1]
          }}
          color={markerColorEdge}
          fill={true}
          opacity={markerOpacity}
          fillOpacity={markerOpacity}
          fillColor={markerColorFill}
          weight={markerWeight}
          radius={markerRadius}
          zIndex={1000}
        >
          {onHoverPopup && onHoverPopup === true && <Tooltip>{markerValue.toFixed(0)} kg/ha</Tooltip>}
        </CircleMarker>
      )
    }
  };

  render() {
    const {
      userId,
      fieldId,
      layer,
      layerId,
      field,
      markers,
      dataStats,
      allMarkers,
      polygonImage,
      mapStyle,
      lockedMap,
      placeholderText,
      placeholderOnClick,
      loadingData,
      loadingDataText,
      selectedAreaPoints,
    } = this.props;

    let dataLayer = getCurrentDataLayer(layer);
    let attribution = dataLayer && dataLayer.attribution;

    let mapOptions = this.props.mapOptions;
    let dataLayerMissing = typeof(this.props.dataLayerMissing) === "boolean" ? this.props.dataLayerMissing : false;

    if (typeof(lockedMap) === "boolean" && lockedMap === false) {
      mapOptions = {
        ...mapOptions,
        touchZoom: true,
        scrollWheelZoom: true,
        dragging: true,
      };
    };

    const newMapOptions = getFieldMapOptions(field, mapStyle, mapOptions);

    let showArealMap = typeof(this.props.showArealMap) === "boolean" ? this.props.showArealMap : true;

    let screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    let polygonImageOverlay = polygonImage &&
      <ImageOverlay
        className={"satelliteImageBrighter"}
        url={polygonImage}
        bounds={geoPointToPosition(field.position)}
        opacity={1.0}
        zIndex={500}
      />
    
    let geoJsonMarkers = [];
    geoJsonMarkers = markers && markers.length > 0 ? [...geoJsonMarkers, ...markers] : geoJsonMarkers;
    geoJsonMarkers = allMarkers && allMarkers.length > 0 ? [...geoJsonMarkers, ...allMarkers] : geoJsonMarkers;
     
    let noDataAvailable = !polygonImage && !geoJsonMarkers && geoJsonMarkers.length === 0;

    let circleMarkers = [];

    !dataStats && geoJsonMarkers && geoJsonMarkers.forEach((marker, idx) => {
      circleMarkers = [...circleMarkers, this.getMarker(marker, idx, screenWidth)];
    });
          
    dataStats && geoJsonMarkers && dataStats.groups && dataStats.groups.forEach((x, idx1) => {
      let groupIndices = x && x.sampleIndices;
      let groupCircleMarkers = [];

      geoJsonMarkers
        .filter((y) => groupIndices.includes(y.idx))
        .forEach((marker, idx2) => {
          groupCircleMarkers = [...groupCircleMarkers, this.getMarker(marker, idx1 + '_' + idx2, screenWidth)];
      });

      let newGroup = 
        <FeatureGroup 
          key={"circleMarkerGroup_" + idx1}
          className="circle-marker-group"
        >
          {groupCircleMarkers && groupCircleMarkers}
        </FeatureGroup>

      circleMarkers = [...circleMarkers, newGroup];
    });

    return (
      <div 
        style={{ 
          flex: 1, 
          position: "relative",
        }}

        onClick={(placeholderOnClick && noDataAvailable && !dataLayerMissing) ? () => placeholderOnClick() : () => {}}
      >
        <LeafletMap 
          // key={geoJsonMarkers}
          {...newMapOptions}
          onClick={this.props.onClickMap}
        >
          {showArealMap && <TileLayer attribution={attribution && attribution} url={AREAL_TILE_URL} />}
          
          <TileLayer opacity={noDataAvailable ? 0.9 : 0.5} url={overlayUrl} />

          {(noDataAvailable || loadingData) &&
              <Dimmer active>
                <Loader active indeterminate size="big">{loadingDataText ? loadingDataText : ''}</Loader>
              </Dimmer>
          }

          {noDataAvailable && !loadingData && dataLayerMissing &&
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "25%",
                width: "50%",
                right: "auto",
                zIndex: 450,
                color: "white",
              }}
            >
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "140%",
                  whiteSpace: "pre-line",
                }}
              >
                Datalagret saknas för skiftet
              </div>
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "100%",
                  whiteSpace: "pre-line",
                }}
              >
                Välj ett annat lager eller skifte för att visa kartan.
              </div>              
            </div>
          }

          {noDataAvailable && !loadingData && placeholderText && !dataLayerMissing &&
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "25%",
                width: "50%",
                right: "auto",
                zIndex: 450,
                color: "white",
              }}
            >
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "140%",
                  whiteSpace: "pre-line",
                }}
              >
                {placeholderText}
              </div>
            </div>
          }

          {selectedAreaPoints && selectedAreaPoints.map((point, idx) => (
            <CircleMarker
              key={"marker" + idx}
              center={point}
              color='rgba(239, 181, 46, 1.0)'
              fillColor={'rgba(239, 181, 46, 1.0)'}
              fillOpacity={1.0}
              radius={4}
              zIndex={100}                      
            />
          ))}

          {selectedAreaPoints &&
            <Polygon
              positions={selectedAreaPoints}
              color='rgba(239, 181, 46, 0.9)'
              fillColor={'rgba(239, 181, 46, 0.7)'}
              fillOpacity={1.0}
              weight={3}
            />
          }      

          {field &&
            <Polygon
              positions={geoPointToPosition(field.position)}
              color={"rgba(251, 189, 8, " + (noDataAvailable ? 0.2 : "0.9") + " )"}
              fillOpacity={0}
              weight={3}
              zIndex={10}
            />
          }

          {field && field.position_holes && Object.keys(field.position_holes).map((idx) => (
            <Polygon
              key={"polygonHole_" + userId + "_" + fieldId + "_" + layer + "_" + layerId + "_" + idx}
              positions={geoPointToPosition(field.position_holes[idx])}
              color={"rgba(251, 189, 8, " + (noDataAvailable ? 0.2 : "0.9") + " )"}
              fillOpacity={0}
              weight={3}
              zIndex={10}
            />            
          ))}

          {polygonImageOverlay && polygonImageOverlay}

          <div className="all-circle-markers">{circleMarkers && circleMarkers}</div>
        </LeafletMap>

        {this.props.children}
      </div>
    );
  }
}

export default withRouter(MapWithCircleMarkers);
