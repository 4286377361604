import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import {
  Image,
  Segment,
} from "semantic-ui-react";

import imgIllustration from "@/assets/illustrations/onboarding_guide_2.png";

import MenuTop from "@/components/menus/MenuTop";

class GuidesNotFoundPage extends Component {
  state = {};

  onClickGoBack = () => {
      this.props.history.replace("/reminders");
  };

  render() {
    return (    
      <Page>
        <MenuTop caption="Styra" onClickGoBack={this.onClickGoBack} />

        <div style= {{ marginTop: "70px" }} />

        <Segment 
          basic 
          style={{
            display: "block",
            marginTop: "15vh",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Image  
            centered 
            style={{
              height: "40vh",
            }}
            src={imgIllustration}
          />

          <div 
            style={{ 
              fontWeight: 500, 
              marginBottom: 0, 
              textAlign: "center" ,
              width: "80%",
              marginTop: "1em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {this.props.type === "guidesUploadSoilMaps" && "Tyvärr kan du inte ännu hantera uppladdade markkarteringar från din mobil. Vi jobbar på detta man du kan redan idag göra det på din dator istället."}
            {this.props.type === "guidesUploadYieldMaps" && "Tyvärr kan du inte ännu hantera uppladdade skördekartor från din mobil. Vi jobbar på detta man du kan redan idag göra det på din dator istället."}            
          </div>
        </Segment>
      </Page>    
    );
  }
};

export default withRouter(GuidesNotFoundPage);
  
