import L from 'leaflet';

import { geoPointToPosition } from "@/helpers/db";

export const getCenterOfPolygon = positions => {
    let x = positions.map(x => x[0]);
    let y = positions.map(x => x[1]);
    let cx = (Math.min(...x) + Math.max(...x)) / 2;
    let cy = (Math.min(...y) + Math.max(...y)) / 2;
  
    return [cx, cy];
  };
  
  export const getBoundingBoxOfPolygon = positions => {
    let x = positions.map(x => x[0]);
    let y = positions.map(x => x[1]);
    let minX = Math.min(...x);
    let maxX = Math.max(...x);
    let minY = Math.min(...y);
    let maxY = Math.max(...y);
  
    return [[minX, minY], [maxX, maxY]];
  };
  
  export const isPointInPolygon = (point, vs) => {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    
    let x = point[0], y = point[1];
    
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        let xi = vs[i][0], yi = vs[i][1];
        let xj = vs[j][0], yj = vs[j][1];
        
        let intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
};

  export const getFieldMapOptions = (field, style={}, options={}) => {
    const polygonPosition = field && field.position && geoPointToPosition(field.position);
  
    const polygonCenter = polygonPosition && getCenterOfPolygon(polygonPosition);
    const polygonBoundingBox = polygonPosition && getBoundingBoxOfPolygon(polygonPosition);
    
    const corner1 = polygonBoundingBox && polygonBoundingBox[1][0] && polygonBoundingBox[0][1] && L.latLng(polygonBoundingBox[1][0], polygonBoundingBox[0][1]);
    const corner2 = polygonBoundingBox && polygonBoundingBox[0][0] && polygonBoundingBox[1][1] && L.latLng(polygonBoundingBox[0][0], polygonBoundingBox[1][1]);
  
    let output = {
        style: {
          flex: 1,
          height: "100vh",
          top: 0,
          ...style,
        },
        zoom: null,
        maxZoom: 20,
        zoomSnap: 0.1,
        boundsOptions: { padding: [20, 20] },
        zoomControl: false,
        touchZoom: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        boxZoom: false,
        dragging: false,
        ...options,
      };
  
      output = polygonCenter && polygonCenter[0] && polygonCenter[1] ? {...output, center: [polygonCenter[0], polygonCenter[1]]} : output;
      output = (corner1 && corner2) ? {...output, bounds: L.latLngBounds(corner1, corner2)} : output;
      
      return output;
  };
  