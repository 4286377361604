import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Header,
  Segment,
} from "semantic-ui-react";

import FieldCartoon from "@/components/misc/FieldCartoon";
import WidgetMenu from "@/components/menus/WidgetMenu";
import { isLoaded } from "react-redux-firebase";

const NO_FIELDS_TO_SHOW = 3;

const INITIAL_STATE = {
  activeView: "maxGreenmass",
};

class FieldsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  openField = (fieldId) => {
    const {
      activeView,
    } = this.state;

    if (activeView === "maxGreenmass") {
      this.props.history.push("/layers/" + fieldId + "/greenmass")
    };

    if (activeView === "varianceGreenmass") {
      this.props.history.push("/layers/" + fieldId + "/greenmass_percent")
    };

    if (activeView === "maxHarvest") {
      this.props.history.push("/layers/" + fieldId + "/yieldMap")
    };        
  }

  getFieldCards = (fieldId, cartoonColor) => {
    const {
      db,
    } = this.props;

    let field = db && db.fields && db.fields[fieldId] && db.fields[fieldId];

    let name = field && field.name && field.name;
    let position = field && field.position && field.position;
    
    
    if (!position) {
      return null
    }

    return (
      <React.Fragment 
        key={`DashboardTopList_${fieldId}`}
      >
        <Segment
          basic
          className="fieldCardOnHover"
          onClick={() => this.openField(fieldId)}
          style={{
            backgroundColor: "white",
            padding: "15px",
            paddingTop: 5,
            paddingRight: 12,
            paddingBottom: 5,
            marginTop: 5,
            marginBottom: 0,
            cursor: "pointer",
            height: "50px",
          }}
        >
          <div
            style={{
              display: "flex", 
            }}
          >
            <div>
              {position && 
                <FieldCartoon
                  className="fieldCartoon"
                  style={{
                    margin: 0,
                    padding: 0,
                    marginTop: "7px",
                    height: "30px",
                    width: "30px",
                    zIndex: 20,
                  }}            
                  color={cartoonColor}
                  position={position}
                />
              }    
            </div>
            <div
              style={{
                marginLeft: 20,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <div 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Header
                  style={{ 
                    fontWeight: 500,
                    paddingTop: "5px",
                    paddingBottom: 0,
                    marginBottom: 0,
                    fontSize: "110%"
                  }}
                >
                  {name && name}
                </Header>      
              </div>
            </div>
          </div>
        </Segment>
      </React.Fragment>
    )
  };

  handleItemClick = (item) => this.setState({ activeView: item });

  render() {
    const { 
      db,
    } = this.props;

    const {
      activeView,
    } = this.state;

    let greenmassMean = db && db.dashboard && 
      db.dashboard.top_list_greenmass &&
      db.dashboard.top_list_greenmass.mean;

    let greenmassVariance = db && db.dashboard && 
      db.dashboard.top_list_greenmass &&
      db.dashboard.top_list_greenmass.variance;
    
    let menuItems = [
      {
        id: 'maxGreenmass',
        caption: "Grönmassa",
        active: activeView === 'maxGreenmass',
        onClick: this.handleItemClick,
        fieldIds: greenmassMean,
        headerHighest: "Högt medel i år",
        headerLowest: "Lågt medel i år",
      },
      {
        id: 'varianceGreenmass',
        caption: "Variation i grönmassa",
        active: activeView === 'varianceGreenmass',
        onClick: this.handleItemClick,
        fieldIds: greenmassVariance,
        headerHighest: "Stor variation i år",
        headerLowest: "Liten variation i år"        
      },
      // {
      //   id: 'maxHarvest',
      //   caption: "Skörd",
      //   active: activeView === 'maxHarvest',
      //   onClick: this.handleItemClick,
      //   fieldIds: greenmassMean,
      //   headerHighest: "Stora skördar av höstvete",
      //   headerLowest: "Små skördar av höstvete",        
      // },            
    ];

    let currentMenuItem = activeView && menuItems.find((x) => x.id === activeView);
    let sortedFieldIds = currentMenuItem && currentMenuItem.fieldIds;

    let headerHighest = currentMenuItem && currentMenuItem.headerHighest;
    let headerLowest = currentMenuItem && currentMenuItem.headerLowest;

    sortedFieldIds = sortedFieldIds && Object.keys(sortedFieldIds).sort((a, b) => sortedFieldIds[b] - sortedFieldIds[a]);

    let fieldIdsHighest = sortedFieldIds && sortedFieldIds.slice(0, NO_FIELDS_TO_SHOW);
    let fieldIdsLowest = sortedFieldIds && sortedFieldIds.slice(1).slice(-NO_FIELDS_TO_SHOW);

    let fieldItemsHighest = fieldIdsHighest && fieldIdsHighest.map((x) => this.getFieldCards(x, "#89A275"));
    let fieldItemsLowest = fieldIdsLowest && fieldIdsLowest.map((x) => this.getFieldCards(x, "#D6946B"));

    if (!greenmassMean || !fieldIdsHighest || !fieldIdsLowest) {
      return <div />
    };
    
    return (
      <Segment
        style={{
          marginTop: "2em",
          padding: "1em",
          paddingTop: 0,
          width: "100%",
          display: "block",
        }}
        loading={!isLoaded(db) || !db.dashboard || !isLoaded(db.dashboard)}
      >
        <WidgetMenu menuItems={menuItems} />
        
        <div 
          style={{
            marginTop: "1em",
            width: "100%",
            display: "flex",
            alignContent: "space-between",
          }}
        >

          <div style={{ width: "50%" }}>
            <Header color="grey" as="h4" style={{ fontWeight: 500, textAlign: "center" }}>{headerHighest}</Header>
            {fieldItemsHighest && fieldItemsHighest}
          </div>
        
          <div style={{ width: "50%" }}>
              <Header color="grey" as="h4" style={{ fontWeight: 500, textAlign: "center" }}>{headerLowest}</Header>
              {fieldItemsLowest && fieldItemsLowest}
          </div>
        </div>
      </Segment>
    );
  }
}

export default withRouter(FieldsWidget);
