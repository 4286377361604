export const firebaseConfig = {
    apiKey: "AIzaSyDGhd4TuCcVjOfIImRfmcMP4cL2qMpyUf8",
    authDomain: "agriopt-frontend-staging.firebaseapp.com",
    databaseURL: "https://agriopt-frontend-staging.firebaseio.com",
    projectId: "agriopt-frontend-staging",
    storageBucket: "agriopt-frontend-staging.appspot.com",
    messagingSenderId: "627200025899",
    appId: "1:627200025899:web:27b6dcb141541e9586916e",
    measurementId: "G-KD1XSYERJB",
};

export const APP_VERSION = 'staging';