import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { 
  Header, 
  Image,
  Modal,
} from 'semantic-ui-react'

import DoneIllustration from '@/assets/illustrations/done.png';
import ErrorIllustration from '@/assets/illustrations/error.png';

const INITIAL_STATE = {};

class ActionDone extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClick = () => {
    const { 
      pushUrl,
      pushState,
    } = this.props.info;

    if (pushUrl) {
      this.props.history.push(pushUrl, pushState ? pushState : {});
    } else {
      this.props.hideModal();
    };
  };

  render() {
    const {
      text,
      errorMessage,
      outcome,
    } = this.props.info;

    let image = '';

    switch (outcome) {
      case 'failure':
        image = ErrorIllustration;
        break;

      default:
        image = DoneIllustration;
        break;
    };

    return (
      <Modal
        basic
        open={true}
        size="fullscreen"
        closeOnDocumentClick={true}
        closeOnDimmerClick={true}
        closeOnTriggerClick={true}
        onClick={() => this.onClick()}
      >                  
          <Modal.Content>
            <div
              style={{
                marginTop: '15vh',
                marginBottom: '15vh',
              }}
            >
              <Image
                centered
                size='medium'
                src={image}
              />

              <Header
                as='h3'
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                {text}
              </Header>

              <p
                style={{
                  color: "white",
                  margin: "1em",
                  textAlign: "center",
                  whiteSpace: "normal",
                }}
              >
                {errorMessage && errorMessage}
              </p>              

              <p
                style={{
                  color: 'white',
                  padding: '0',
                  paddingLeft: '2em',
                  paddingRight: '2em',
                  whiteSpace: 'normal',
                  textAlign: 'center',
                }}
              >
              Klicka på skärmen för att gå vidare
            </p>           
          </div>   
        </Modal.Content>
      </Modal>     
    );
  };
};

export default withRouter(ActionDone);
