import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import {
  firestoreConnect,
} from "react-redux-firebase";

import GuideDownloadFiles from "@/components/guidesTemplates/GuideDownloadFiles";

import MenuTop from "@/components/menus/MenuTop";

class GuidesPage extends Component {
  state = {};

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  onClickGoBack = () => {
      this.props.history.replace("/reminders");
  };

  render() {
    const {
      match: {
        params: { guideId }
      },
    } = this.props; 

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    const guide = db && guideId && db["guides_" + guideId];
    let selectedFieldIds = guide && guide.field_ids;

    return (    
      <Page>
        <MenuTop caption="Styra" onClickGoBack={this.onClickGoBack} />

        <div
          style={{
            width: "calc(100vw - 20)",
            marginTop: 70,
            marginLeft: 10,
            marginRight: 10,              
          }}
        >
          <GuideDownloadFiles 
            db={db}
            fileBucketRef={fileBucketRef}
            getUser={this.getUser}
            compoundUnit="kg/ha"
            selectedFieldIds={selectedFieldIds}
            guide={guide}
            currentRationNo={guide && guide.guide_type && guide.guide_type === "nitrogenV1" ? 1 : 0}
            skippedVraMapsForFields={[]}
          />
        </div>
      </Page>    
    );
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {
    const {
      match: {
        params: { guideId }
      },
    } = props; 

    let results = [
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "guides", doc: `${guideId}` },
        ],
        storeAs: 'guides_' + guideId
      }, 
    ]

    return results
  }),
  withFirestoreData,
  withRouter
)(GuidesPage);