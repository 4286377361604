import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from "react-router-dom";

import {
  firestoreConnect,
  isLoaded,
  isEmpty,
} from 'react-redux-firebase';

import {
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import Page from '@/components/misc/Page';

import GeoTagLayerMenu from "@/components/geotags/GeoTagLayerMenu";
import GeoTagTopMenu from '@/components/geotags/GeoTagTopMenu';

import MenuFieldList from '@/components/menus/MenuFieldList';

import OverviewGeoTagsList from '@/components/geotags/OverviewGeoTagsList';
import OverviewGeoTagsMap from '@/components/geotags/OverviewGeoTagsMap';

const INITIAL_STATE = {
  showGeoTagFilterMenu: false,
  geoTagFilter: {
    plant: {
      plantStage: true,
      plantsPerArea: true,
      earsPerPlant: true
    },
    bug: {
      rapeseedBeetle: true,
        oatAphid: true,
        trips: true
    },
    mushroom: {
        blight: true,
        snowmold: true,
        fusariumearblight: true,
        wheatleafdisease: true,
        yellowrust: true
    },
    land: {
        cornflower: true,
        wildoat: true, 
        couchgrass: true,
        blackgrass: true,
        aperaspicaventi: true
    },
    animals:{
        grazing: true,
        grout: true,
    },
    custom: true,
  },
  selectedGeoTagId: null,
  returnTo: "list",
  removedObservationIds: [],
};

export class GeoTagPage extends Component {
  state = {
    ...INITIAL_STATE 
  };

  getDb = () => this.props.firestoreData;

  getUser = () => this.props.firestore.collection('users').doc(this.props.userId);

  openField = (newFieldId) => {
    this.props.history.push("/geotags/" + newFieldId + "/list");
  };

  closeField = () => {
    const {
      match: {
        params: { fieldId, mode }
      },
    } = this.props;

    const {
      returnTo,
    } = this.state;
    
    if (mode === "details" && returnTo !== "home") {
      this.props.history.push("/geotags/" + fieldId + "/" + (returnTo ? returnTo : "list"));
    } else if (mode === "details" && returnTo === "home") {
      this.props.history.push("/");
    } else if (!fieldId) {
      this.props.history.push("/more");      
    } else {
      this.props.history.push("/geotags/");
    };
  };

  openLayer = (mode) => {
    const {
      match: {
        params: { fieldId }
      },
    } = this.props;

    this.props.history.replace("/geotags/" + fieldId + "/" + mode);
  };

  onClickGoBack = () => {
    this.props.history.goBack();
  };  

  onChangeGeoTagView = (layer) => {
    const { params } = this.props.match;
    this.props.history.replace("/geotags/" + params.fieldId + "/" + layer);
  };

  onClickGeoTag = (geoTag, source) => {
    this.setState({ 
      selectedGeoTagId: geoTag.id,
      returnTo: source,
    });
  };

  removeObservation = (id) => {
    this.setState({
      removedObservationIds: [...this.state.removedObservationIds, id]
    });
  };

  render() {
    const {
      match: {
        params: { fieldId, mode, tagId }
      },
      userId,
      trueUserId,
    } = this.props;

    const {
      geoTagFilters,
      removedObservationIds,
    } = this.state;

    const db = this.getDb();
    let fileBucketRef = this.props.firebase.storage().ref();

    let field = fieldId && db && isLoaded(db) && db.fields && db.fields[fieldId];

    let toolbarCaption =  "Observationer";
    toolbarCaption = field && field.name ? "Observationer: " + field.name : toolbarCaption;
    toolbarCaption = mode === "details" ? "Detaljer om observationen" : toolbarCaption;

    let hasLoadedData = 
      isLoaded(db) && 
      !isEmpty(db) && 
      isLoaded(db.fields) && 
      !isEmpty(db.fields) && 
      isLoaded(db.observations);

    if (hasLoadedData) {
      return (
        <Page>
        <GeoTagTopMenu
          caption={toolbarCaption}
          closeField={this.closeField}
          toolbar={null}
        />        
        
        {fieldId && mode !== "details" &&
          <GeoTagLayerMenu
            db={db}
            mode={mode}
            fieldId={fieldId}
            openLayer={this.openLayer}
          />
        }

          {!fieldId &&
            <MenuFieldList
              db={db}
              userId={userId}
              fieldId={null}
              compact={false}
              hideFarmCard={true}
              hideLockedFields={true}
              hideUnLockedFields={false}
              openField={this.openField}
            />
          }

          {fieldId && (!mode || mode === "list") &&
            <OverviewGeoTagsList 
              db={db}
              fileBucketRef={fileBucketRef}
              userId={userId}
              fieldId={fieldId}
              geoTagFilters={geoTagFilters}
              onClickGeoTag={this.onClickGeoTag}
              onChangeLayer={this.onChangeLayer}
              onChangeGeoTagView={this.onChangeGeoTagView}
              removeObservation={this.removeObservation}
              removedObservationIds={removedObservationIds}
            />
          }

          {fieldId && mode === "map" &&
            <OverviewGeoTagsMap 
              db={db}
              userId={userId}
              fieldId={fieldId}
              geoTagFilters={geoTagFilters}
              onClickGeoTag={this.onClickGeoTag}
              onChangeLayer={this.onChangeLayer}
              onChangeGeoTagView={this.onChangeGeoTagView}
              removedObservationIds={removedObservationIds}
            />
          }

          {fieldId && mode === "details" &&
            <OverviewGeoTagsList 
              db={db}
              getUser={this.getUser}
              fileBucketRef={fileBucketRef}
              userId={userId}
              fieldId={fieldId}
              tagId={tagId}
              trueUserId={trueUserId}
              geoTagFilters={geoTagFilters}
              onClickGeoTag={this.onClickGeoTag}
              onChangeLayer={this.onChangeLayer}
              onChangeGeoTagView={this.onChangeGeoTagView}
              removeObservation={this.removeObservation}
              removedObservationIds={removedObservationIds}
            />
          }                 
        </Page>
      )
    }

    if (!hasLoadedData) {
      return (
        <Dimmer active inverted>
          <Loader style={{ paddingBottom: '8em' }} />
          <p style={{color: "#000000"}}>Laddar taggdata...</p>
        </Dimmer>       
      )
    };
  };
};


const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {

    let results = [
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "fields" },
        ],
        storeAs: 'fields'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "observations" },
        ],
        storeAs: 'observations'
      },           
    ];

    return results
  }),
  withFirestoreData,
  withRouter
)(GeoTagPage);