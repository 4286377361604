import React, { Component} from "react";

import {
  Button, 
  Image,
  Segment,
  Header,
 } from "semantic-ui-react";

import emptyIllustration from "@/assets/illustrations/onboarding_guide_1.png";

const INITIAL_STATE = {};
  
class NoAccessToBetaModal extends Component {
  
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }

    render ()  {

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
          }}
        >

          <Segment 
            basic 
            style={{
              display: "block",
              backgroundColor: "transparent",
              width: "80%",
            }}
          >
            <Image  
              centered 
              src={emptyIllustration}
            />

            <div 
              style={{  
                marginBottom: 0, 
                marginTop: "2em",
                marginLeft: "1em",
                marginRight: "1em",
                textAlign: "center",
                fontSize: "95%",
              }}
            >
              <Header 
                as="h3"
                style={{ 
                  fontWeight: 500
                }}
              >
                Testmiljön är stängd
              </Header>

              <p>
                Just nu är Frejas miljö för användartester stängd eftersom vi jobbar vidare med nästa version av Freja. Ett stort tack för din medverkan i användartesterna.

                <br/> <br/>
                Om du har några förslag, idéer eller frågor är du som alltid välkommen att maila vår support på <a href="mailto:support@agriopt.se">support@agriopt.se</a>.
              </p>

              <p>Med vänliga hälsningar, Teamet bakom Freja</p>
              
              <Button
                primary
                style={{
                  display: "block",
                  marginTop: "2em",
                  marginBottom: "3em"
                }}
                href="https://m.freja.agriopt.se/"
                rel="noopener noreferrer"
                size="large"                
              >
                Öppna Freja
              </Button>

            </div>
          </Segment>
        </div>
      );
  };
}

export default NoAccessToBetaModal;