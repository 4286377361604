import React, { Component } from 'react';

class Page extends Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  };

  render () {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          paddingBottom: "7em",
          overflowY: "auto",
          overflowX: "hidden",
          ...this.props.style
        }}
      >
        {this.props.children && this.props.children}
      </div>
    )
  };
};

export default Page;