
import React, { Component } from 'react';

import {
  Map as LeafletMap,
  Marker as LeafletMarker,
  TileLayer,
  Polygon,
  } from 'react-leaflet';

import L from 'leaflet';

import insidePolygon from 'point-in-polygon';

import { 
  collectionToIdArray,
  geoPointToPosition,
  getAvailableFieldIds,
} from "@/helpers/db";

import { AREAL_TILE_URL } from "@/constants/map";

import MeIcon from '@/assets/icons/map/mePos.png';
import newMarkerIcon from "@/assets/icons/map/icon_adding_marker.png";

const iconNewMarker = new L.Icon({
  iconUrl: newMarkerIcon,
  iconRetinaUrl: newMarkerIcon,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
});

const iconMe = new L.Icon({
  iconUrl: MeIcon,
  iconRetinaUrl: MeIcon,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 30),
});

const INITIAL_STATE = {
  hasLocation: false,
  latlng: {
    lat: 58.411,
    lng: 15.621,
  },
  errorMessage: '',
};
  
class GetTagLocationMap extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  mapRef = React.createRef();

  componentDidMount() {    
    this.mapRef.current && this.mapRef.current.leafletElement.locate({
      watch: true, //  - set to true if should track user live
    });
  };

  handleLocationFound = (e) => {
    this.setState({
      hasLocation: true,
      latlng: e.latlng,
    })
  };

  onClickMap = ({ latlng }) => {
    let fieldId = this.pointIsInsideField(latlng);

    if (fieldId) {
      this.props.updateTag({
        position: latlng,
        fieldId: fieldId.id
      });
    } else {
      console.log("The point is not inside a field")
    };
  };

  pointIsInsideField = (latlng) => {
    const db = this.props.db;
    return collectionToIdArray(db.fields).find((x) => this.isPointInsideField(latlng.lat, latlng.lng, x))
   };

  isPointInsideField = (lat, lng, field) => {
    const positions = geoPointToPosition(field.position);
    
    if (insidePolygon([lat, lng], positions)) {
      return true;
    }
    return false;
  };

  render() {
    const {
      db,
      newTag,
    } = this.props;

    const {
      hasLocation,
      latlng,
    } = this.state;

    const fields = db && db.fields;
    const availableFieldIds = db && getAvailableFieldIds(db);

    let allPositions = [];
    availableFieldIds && availableFieldIds.forEach((fieldId) => {
      allPositions = [...allPositions, ...fields[fieldId].position];
    });

    let allPositionHolesAvailable = [];
    availableFieldIds && availableFieldIds.forEach((fieldId) => {
      fields[fieldId].position_holes && Object.keys(fields[fieldId].position_holes).forEach((idx) => {
        allPositionHolesAvailable = [...allPositionHolesAvailable, fields[fieldId].position_holes[idx]];
      })
    });
    return (
      <React.Fragment>
        <div
          style={{
            marginTop: 60,
            height: "calc(100vh - 60px - 140px)",
            overflowY: "auto",
          }}
        >
          <LeafletMap
            center={latlng}
            zoom={14}
            minZoom={8}
            maxZoom={16}
            style={{
              position: "absolute",
              top: 50,
              left: 0,
              height: "100%",
              width: '100%',
            }}
            zoomControl={false}
            ref={this.mapRef}
            onClick={this.onClickMap}
            onLocationFound={this.handleLocationFound}
          >
            <TileLayer url={AREAL_TILE_URL} />

            {newTag.position && <LeafletMarker position={newTag.position} icon={iconNewMarker} />}
            {hasLocation && latlng && <LeafletMarker position={latlng} icon={iconMe} />}         

            {fields && Object.keys(fields).map((fieldId) => (
              <Polygon
                key={fieldId}
                positions={geoPointToPosition(fields[fieldId].position)}
                color={"rgba(251, 189, 8, 0.9)"}
                fillOpacity={0.6}
                weight={3}
              />
            ))}

            {allPositionHolesAvailable && allPositionHolesAvailable.map((positionHole, idx) => (
            <Polygon
              key={"fieldhole_" + idx}
              positions={geoPointToPosition(positionHole)}
              color={"rgba(251, 189, 8, 0.9)"}
              fillOpacity={0}
              weight={3}
            />                   
          ))}                 
          </LeafletMap> 
        </div>
      </React.Fragment>
    )
  }
};

export default GetTagLocationMap;



