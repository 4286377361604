import imgHome from '@/assets/icons/menuPrimary/my-farm.svg';
import imgReminders from '@/assets/icons/menuPrimary/events.svg';
import imgTodo from '@/assets/icons/menuPrimary/to-do.svg';
import imgAdd from '@/assets/icons/menuPrimary/collect-data.svg';
import imgMore from '@/assets/icons/menuPrimary/menu.svg';

export const VIEWS = {
    home: {
        caption: 'Min gård',
        name: 'home',
        aliases: [],
        icon: imgHome,
        path: '',
    },      
    notifications: {
        caption: 'Händelser',
        name: 'notifications',
        aliases: [],
        icon: imgReminders,
        path: 'notifications',
    },          
    collect: {
        caption: 'Samla',
        name: 'collect',
        aliases: [],
        icon: imgAdd,
        path: 'collect',
    },               
    reminders: {
        caption: 'Planering',
        name: 'reminders',
        aliases: [],
        icon: imgTodo,
        path: 'reminders',
    },
    more: {
        caption: 'Meny',
        name: 'more',
        aliases: ['layers', 'files', 'legal', 'settings', 'support', 'geotags'],
        icon: imgMore,
        path: 'more',
    },       
};