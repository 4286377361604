import plantsIcon from '@/assets/icons/map/plants.png'
import bugIcon from '@/assets/icons/map/pests.png'
import mushroomIcon from '@/assets/icons/map/fungus.png'
import landIcon from '@/assets/icons/map/weeds.png'
import animalsIcon from '@/assets/icons/map/animals.png'
import defaultIcon from '@/assets/icons/map/misc.png'

export const DC_VALUES = [
  {
      key: "13",
      value: "13",
      text: "13",
  },
  {
      key: "23",
      value: "23",
      text: "23",
  },
  {
      key: "30",
      value: "30",
      text: "30",
  },
  {
      key: "32",
      value: "32",
      text: "32",
  },
  {
      key: "37",
      value: "37",
      text: "37",
  },
  {
      key: "39",
      value: "39",
      text: "39",
  },
  {
      key: "41",
      value: "41",
      text: "41",
  },
  {
      key: "45",
      value: "45",
      text: "45",
  },
  {
      key: "47",
      value: "47",
      text: "47",
  },
  {
      key: "59",
      value: "59",
      text: "59",
  },
  {
      key: "61",
      value: "61",
      text: "61",
  },
  {
      key: "71",
      value: "71",
      text: "71",
  },    
];

export const QUANTITY_VALUES = [
  {
    key: "0-5",
    value: "lessThan5",
    text: "Färre än 5 st",
  },  
  {
    key: "5-10",
    value: "form5to10",
    text: "5 till 10 st",
  },
  {
    key: "10-20",
    value: "from10to20",
    text: "10 till 20 st",
  },
  {
    key: "20-30",
    value: "from20to30",
    text: "20 till 30 st",
  }, 
  {
    key: "30-50",
    value: "from30to50",
    text: "30 till 50 st",
  }, 
  {
    key: "50",
    value: "moreThan50",
    text: "Fler än 50 st",
  }, 
];

export const PERCENT_VALUES = [
  {
    key: "0-5",
    value: "lessThan5",
    text: "Mindre än 5%",
  },  
  {
    key: "5-10",
    value: "form5to10",
    text: "5 till 10 %",
  },
  {
    key: "10-20",
    value: "from10to20",
    text: "10 till 20 %",
  },
  {
    key: "20-30",
    value: "from20to30",
    text: "20 till 30 %",
  }, 
  {
    key: "30-50",
    value: "from30to50",
    text: "30 till 50 %",
  }, 
  {
    key: "50-70",
    value: "from50to70",
    text: "50 till 70 %",
  }, 
  {
    key: "70",
    value: "moreThan70",
    text: "Fler än 70 %",
  }, 
];

export const RADIUS_VALUES = [
  {
    key: "0-5",
    value: "lessThan5",
    text: "Inom än 5 m",
  },  
  {
    key: "5-10",
    value: "form5to10",
    text: "5 till 10 m",
  },
  {
    key: "10-20",
    value: "from10to20",
    text: "10 till 20 m",
  },
  {
    key: "20-30",
    value: "from20to30",
    text: "20 till 30 m",
  }, 
  {
    key: "30-50",
    value: "from30to50",
    text: "30 till 50 m",
  }, 
  {
    key: "50-70",
    value: "from50to70",
    text: "50 till 70 m",
  }, 
  {
    key: "70",
    value: "moreThan70",
    text: "Mer än 70 m",
  }, 
];

export const GEOTAG_TYPES = {
    plant: {
      title: 'Gröda',
      icon: plantsIcon,
      generalAmountTitle: 'Ange antal',
      generalAmount: 'quantity',
      subTypes: {
        plantStage: {
          tagType: 'plant',
          title: 'Grödstadie (DC)',
          amountTitle: 'DC-värde',
          amounts: DC_VALUES,
        },
        plantsPerArea: {
          tagType: 'plant',
          title: 'Plantor per m²',
          amountTitle: 'Antal Plantor per m²',
          amounts: QUANTITY_VALUES,
        },
        earsPerPlant: {
          tagType: 'plant',
          title: 'Ax per planta',
          amountTitle: 'Antal ax per planta',
          amounts: QUANTITY_VALUES,
        },
      },
    },
    bug: {
      title: 'Skadeinsekter',
      icon: bugIcon,
      generalAmountTitle: 'Insekt per planta',
      generalAmount: 'quantity',
      subTypes: {
        rapeseedBeetle: {
          tagType: 'bug',
          title: 'Rapsbagge',
          amountTitle: 'Baggar per planta',
          amounts: QUANTITY_VALUES,
        },
        oatAphid: {
          tagType: 'bug',
          title: 'Havrebladlus',
          amountTitle: 'Bladlöss per planta',
          amounts: QUANTITY_VALUES,
        },
        trips: {
          tagType: 'bug',
          title: 'Trips',
          amountTitle: 'Trips per planta',
          amounts: QUANTITY_VALUES,
        },
      },
    },
    mushroom: {
      title: 'Skadesvampar',
      icon: mushroomIcon,
      generalAmountTitle: 'Angripna plantor',
      generalAmount: 'percent',
      subTypes: {
        blight: {
          tagType: 'mushroom',
          title: 'Mjöldagg',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
        },
        snowmold: {
          tagType: 'mushroom',
          title: 'Snömögel',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
        },
        fusariumearblight : {
          tagType: 'mushroom',
          title: 'Axfusarios',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
        },
        wheatleafdisease: {
          tagType: 'mushroom',
          title: 'Vetets bladfläcksjuka',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
        },
        yellowrust: {
          tagType: 'mushroom',
          title: 'Gulrost',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
        },
      },
    },
    land: {
      title: 'Ogräs',
      icon: landIcon,
      generalAmountTitle: 'Angripna plantor',
      generalAmount: 'percent',
      subTypes: {
        cornflower: {
          tagType: 'land',
          title: 'Blåklint',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
          radius: RADIUS_VALUES,
        },
        wildoat: {
          tagType: 'land',
          title: 'Flyghavre',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
          radius: RADIUS_VALUES,
        },
        couchgrass: {
          tagType: 'land',
          title: 'Kvickrot',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
          radius: RADIUS_VALUES,
        },
        blackgrass: {
          tagType: 'land',
          title: 'Renkavle',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
          radius: RADIUS_VALUES,
        },
        aperaspicaventi: {
          tagType: 'land',
          title: 'Åkerven',
          amountTitle: 'Angripna plantor',
          amounts: PERCENT_VALUES,
          radius: RADIUS_VALUES,
      },
     },
    },
    animals: {
      title: 'Viltskador',
      icon: animalsIcon,
      generalAmountTitle: 'Angripna plantor',
      generalAmount: 'percent',
      subTypes: {
        grazing: {
          tagType: 'animals',
          title: 'Betning/trampning',
          amountTitle: 'Påverkad gröda',
          amounts: PERCENT_VALUES,
          radius: RADIUS_VALUES,
        },
        grout: {
          tagType: 'animals',
          title: 'Bökning',
          amountTitle: 'Uppbökad mark',
          amounts: PERCENT_VALUES,
          radius: RADIUS_VALUES,
        },
      },
    },
  custom: {
    icon: defaultIcon,
    title: 'Övrigt',
  },
};