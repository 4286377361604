import React, { Component } from 'react';

import { 
  Segment, 
} from 'semantic-ui-react';

import { GEOTAG_TYPES } from "@/constants/geotags";

const INITIAL_STATE = [];

export class GetGeoTagAmountRadius extends Component {
  
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onSelectAmount = (amount, amountText,) => {
    const {
      selectType, 
    } = this.props;
    
    this.props.updateTag({ 
      [selectType]: amount,
      [selectType + "Text"]: amountText, 
    });
  };

  render () {
    const {
      newTag,
      selectType,
      marginTop,
    } = this.props;

    let selectedMainTypeMarker = newTag && newTag.mainType && 
      GEOTAG_TYPES && GEOTAG_TYPES[newTag.mainType];

    let amountOptions = 
      selectedMainTypeMarker && 
      newTag.subType &&
      selectedMainTypeMarker.subTypes &&
      selectedMainTypeMarker.subTypes[newTag.subType].amounts;

    let radiusOptions = 
      selectedMainTypeMarker && 
      newTag.subType &&
      selectedMainTypeMarker.subTypes &&
      selectedMainTypeMarker.subTypes[newTag.subType].radius;

    let amountTitle = 
      selectedMainTypeMarker && 
      newTag.subType &&
      selectedMainTypeMarker.subTypes &&
      selectedMainTypeMarker.subTypes[newTag.subType] &&
      selectedMainTypeMarker.subTypes[newTag.subType].amountTitle;

    let viewOptions = selectType && selectType === "radius" ? radiusOptions : amountOptions;

    let currentValue = newTag && newTag.amount;
    currentValue = selectType && selectType === "radius" ? newTag.radius : currentValue;

    if (viewOptions){
      return (
        <div
          style={{
            marginTop: marginTop ? marginTop : null,
          }}
        >
          <p 
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "120%",
              fontWeight: 600,
            }}
          >
            {amountTitle && amountTitle}
          </p>

          {viewOptions.map(x =>
            <Segment 
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                marginLeft: "0.75em",
                marginRight: "0.75em",                
                padding: "1em",
                fontSize: "150%",
                textAlign: "center",
                backgroundColor: x.value === currentValue ? "#F0BD38" : null,   
                color: x.value === currentValue ? "white" : null,
              }}
              key={x.key} 
              onClick={() => this.onSelectAmount(x.value, x.text)} 
              content={x.text}
            />
          )}
        </div>
      )
    } else {
      return <div />;
    }

  };
};

export default GetGeoTagAmountRadius;