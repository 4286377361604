import moment from 'moment';

export const getCurrentDate = (format="YYYY-MM-DD") => {
    return moment().format(format)
};

export const getCurrentYear = () => {
    let currentDate = new Date();
    return currentDate.getFullYear().toFixed(0)
};

export const getPreviousYear = () => {
    let currentDate = new Date();
    return (currentDate.getFullYear() - 1).toFixed(0)
};