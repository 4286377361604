import React, { Component } from "react";

import {
  isLoaded,
} from "react-redux-firebase";

import { 
  Segment,
  Image,
} from "semantic-ui-react";

import imgFields from "@/assets/icons/home/fields.svg";
import imgFrejaHappy from "@/assets/icons/home/freja-happy.svg";

const INITIAL_STATE = {};

class HomeBarWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render () {
    const {
        db,
    } = this.props;

    let firstName = db && 
      db.profile && 
      db.profile.contact && 
      db.profile.contact.first_name && 
      db.profile.contact.first_name;

    let fieldIds = db && isLoaded(db) && db.fields && 
      Object.keys(db.fields)
      .map((x) => x)
      .filter((x) => ( typeof(db.fields[x].available_to_user) !== "boolean") || (typeof(db.fields[x].available_to_user) === "boolean" && db.fields[x].available_to_user === true))

    let totalArea = db && fieldIds && fieldIds
      .map(x => db.fields[x].field_size && db.fields[x].field_size)
      .reduce((partial_sum, a) => partial_sum + a, 0);
    totalArea = totalArea ? totalArea : null;

    let noFields = fieldIds && fieldIds.length;

    return (
      <Segment
        basic
        style={{
          margin: 0,
          marginTop: 20,
          marginBottom: 20,
          padding: 0,
          backgroundColor: "transparent",
          border: "0px",
          boxShadow: "0px",
          width: "calc(100vw - 20px)",
        }}              
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div 
            style={{ 
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto", 
              textAlign: "center",               
            }}
          >
              <Image 
                style={{
                  display: "block",
                  height: "3em",
                  filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={imgFrejaHappy} 
              />

              <div>
                <p style={{ color: "grey", marginBottom: 0, marginTop: 10, }}>Välkommen</p>
                <p style={{ fontWeight: 400, fontSize: "130%" }}>{firstName}</p>
              </div>
            </div>
            
            <div 
              style={{ 
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",    
                textAlign: "center",            
              }}
            >
              <Image 
                style={{
                  display: "block",
                  height: "3em",
                  filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)",
                  marginLeft: "auto",
                  marginRight: "auto",                  
                }}
                src={imgFields} 
              />
              <div>
                <p style={{ color: "grey", marginBottom: 0, marginTop: 10, }}>Freja övervakar</p>
                <p style={{ fontWeight: 400, fontSize: "130%" }}>{totalArea && " " + totalArea.toFixed(0)} ha ({noFields && noFields} skiften)</p>
              </div>
            </div>                                                                                                   
        </div>
      </Segment>
    )
  }
}

export default HomeBarWidget;
