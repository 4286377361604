import React, { Component } from 'react';
import styled from 'styled-components';

import { Header, Icon } from 'semantic-ui-react';

import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";

const FloatingDiv = styled.div`
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  top: 65px;
  left: auto;
  right: 1em;
  position: absolute;
  float: right;
  padding: 1em;
`;

const INITIAL_STATE = {
  menuOpen: false,
};

class FieldDataSubLayerMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  openLayer = (name) => {
    this.props.openLayer(name);
    this.onToggleMenu();
  }

  onToggleMenu = () => {
    const currentValue = this.state.menuOpen;
    this.setState({ menuOpen: !currentValue });
  };

  getOverlay = (menuItems, layer, onClick) => (
    <FloatingDiv 
      onClick={() => onClick()}
    >
      <Header 
        as='h4' 
        style={{
          marginTop: '0em', 
          marginBottom: '0em', 
          color: '#ffffff', 
          fontWeight: 'normal'
        }}
      >
        {menuItems && menuItems.captions && menuItems.captions[layer]}
        {menuItems && menuItems.captions && !menuItems.captions[layer] && menuItems.captions[layer.split("_percent")[0]]}

        <Icon 
          name='chevron down' 
          style={{
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: '10px',
            height: '18px',
            marginLeft: '5px',
            marginRight: '0px'
          }}
        />
      </Header>
    </FloatingDiv>    
  )

  getMenu = (menuItems, layer, onlyShow=[]) => (
    <div 
      style={{
        position: 'absolute',
        zIndex: 11000,
        height: "100vh",
        width: '100vw',
      }}
    >
      <div
        style={{
          position: 'relative',
          height: "100%",
          width: "100%",
          paddingTop: "7.5vh",
          paddingBottom: "7.5vh",
          overflowY: "auto",
          backgroundColor: 'rgba(0, 0, 0, 0.75)', 
        }}
      >
        <Icon 
          style={{
            position: 'absolute',
            top: 25,
            right: 25,
            color: "white",
          }}
          name="close" 
          size="large"
          onClick={() => this.onToggleMenu()}
        />

        {menuItems && menuItems.captions && Object.keys(menuItems.captions).map((x) => {
          if (menuItems.onlyBeta && menuItems.onlyBeta.includes(x)) {
            return <div />
          } else if (onlyShow && onlyShow.length > 0 && menuItems.geoJsonFieldKey[x] && !onlyShow.includes(menuItems.geoJsonFieldKey[x])) {
            return <div />
          } else {
            return (
              <Header 
                textAlign='center'
                as='h2'
                style={{
                  color: '#ffffff',
                  textDecoration: x === layer ? 'underline' : null,
                  fontWeight: x === layer ? 'bold' : 'normal',
                  marginBottom: "2em",
                }}
                key={x}
                onClick={() => this.openLayer(x)}
              >
                {menuItems.captions[x]}
              </Header>
            )
          }
        })}
      </div> 
    </div>
  )  

  render() {
    const {
      db,
      layer,
      fieldId,
      layerId,
    } = this.props;

    const {
      menuOpen
    } = this.state;

    let menuItems = FIELD_DATA_LAYERS;
    let openItem = layer && menuItems && Object.keys(menuItems).filter((x) => menuItems[x].aliases.includes(layer));
    let subMenuItems = menuItems && openItem && menuItems[openItem] && menuItems[openItem];

    let availableMenuItems = subMenuItems && subMenuItems.captions && 
      Object.keys(subMenuItems.captions).map((x) => x)
      .filter((x) => subMenuItems.onlyBeta && !subMenuItems.onlyBeta.includes(x));
    
    if (availableMenuItems && availableMenuItems.length === 1) {
      return null
    };

    let onlyShow = db && fieldId && layerId &&
      db['fieldsSoilMapsProcessed_' + fieldId] &&
      db['fieldsSoilMapsProcessed_' + fieldId].rawdata_uploaded_soilmap_available_keys &&
      db['fieldsSoilMapsProcessed_' + fieldId].rawdata_uploaded_soilmap_available_keys[layerId] &&
      db['fieldsSoilMapsProcessed_' + fieldId].rawdata_uploaded_soilmap_available_keys[layerId].map((x) => x);

    onlyShow = layer.includes("soilMapUploaded") ? onlyShow : [];

    return (
      <div>
        {!menuOpen && this.getOverlay(subMenuItems, layer, this.onToggleMenu)}
        {menuOpen && this.getMenu(subMenuItems, layer, onlyShow)}
      </div>
    )
  }
};

export default FieldDataSubLayerMenu;
