import React, { Component } from "react";

import {
    Segment,
} from "semantic-ui-react";

import {
    isLoaded,
} from "react-redux-firebase";

import WidgetMenu from "@/components/menus/WidgetMenu";

import WeatherDailySummaryWidget from "@/components/weather/WeatherDailySummaryWidget";
import WeatherForecastWidget from "@/components/weather/WeatherForecastWidget";

const INITIAL_STATE = {
    activeView: "last24hrs",
};

class WeatherForecastAndSummaryWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }
    handleItemClick = (newView) => this.setState({ activeView: newView })

    render() {
        const {
            db,
        } = this.props;

        const {
            activeView,
        } = this.state

        let activeElement;

        switch (activeView) {
            case ("last24hrs"):
                activeElement = <WeatherDailySummaryWidget db={db} renderAsInnerComponent={true} />;
                break;
            case ("forecast"):
                activeElement = <WeatherForecastWidget db={db} renderAsInnerComponent={true} />;
                break;
            default:
                activeElement = <WeatherDailySummaryWidget db={db} renderAsInnerComponent={true} />;
        };

        let menuItems = [
            {
              id: 'last24hrs',
              caption: "Senaste dygnet",
              active: activeView === 'last24hrs',
              onClick: this.handleItemClick
            },
            {
              id: 'forecast',
              caption: "Kommande dygnen",
              active: activeView === 'forecast',
              onClick: this.handleItemClick
            },           
        ];

        return (
            <Segment 
                style={{ 
                    width: "100%",
                    padding: "1em",
                    paddingTop: 0,
                    display: "block",
                }}
                loading={!isLoaded(db) || !db.fieldsWeather || !isLoaded(db.fieldsWeather)}
            >
                <WidgetMenu menuItems={menuItems} />

                {activeElement}
            </Segment>
        )
    }
}

export default WeatherForecastAndSummaryWidget;
