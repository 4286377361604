import React, { Component } from "react";

import { 
  Button,
} from "semantic-ui-react";

const INITIAL_STATE = {};

class ImageFileInputButton extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  fileInputRef = React.createRef();

  render() {
    const {
      bigButton,
      inputIcon,
      disabled, 
    } = this.props;
    
    return (
      <React.Fragment>
        <input
          ref={this.fileInputRef}
          type="file"
          accept="image/*;capture=camera"
          hidden
          onChange={e => this.props.onChange(e)}
        />

        {bigButton &&
          <Button 
            primary
            fluid
            size="large"
            icon="camera"
            labelPosition="left"
            content="Lägg till ett foto"
            disabled={disabled}
            onClick={() => this.fileInputRef.current.click()}
          />
        }

        {inputIcon &&
          <Button
            basic
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: 7,
            }}
            icon='camera' 
            disabled={disabled}
            onClick={() => this.fileInputRef.current.click()}
          />
        }
      </React.Fragment>
    );
  };
};



export default ImageFileInputButton;