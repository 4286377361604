import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import L from 'leaflet';

import {
  Map as LeafletMap,
  TileLayer,
  Polygon,
} from 'react-leaflet';

import GeoTagList from '@/components/geotags/GeoTagList';

import {
  collectionToIdArray,
  geoPointToPosition,
} from '@/helpers/db';  

import {
  getBoundingBoxOfPolygon,
} from '@/helpers/maps';  

import overlayUrl from '@/assets/backgrounds/transparent_bg.png';

const INITIAL_STATE = {
  mapBottom: 0,
  mapMarginTop: 0,
  currentPosArray: '',
  showGeoTagFilterMenu: false,
};

class OverviewGeoTagsMap extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickGeoTag = (geoTag) => {
    this.props.onClickGeoTag(geoTag, "map");
    this.props.history.push("/geotags/" + this.props.fieldId + "/details/" + geoTag.id);
  };

  render () {
    const {
      db,
      fieldId,
      geoTagFilters,
      removedObservationIds,
    } = this.props;

    const {
      mapBottom,
      mapMarginTop,
    } = this.state;

    let field = db && fieldId && db.fields[fieldId];

    let observations = db && fieldId && db.observations && 
      collectionToIdArray(db.observations)
      .filter((x) => !removedObservationIds.includes(x.id))
      .filter((x) => x.field_id === fieldId);

    let polygonPosition = geoPointToPosition(db.fields[fieldId].position);
    let polygonBoundingBox = getBoundingBoxOfPolygon(polygonPosition);

    let corner1 = L.latLng(polygonBoundingBox[1][0], polygonBoundingBox[0][1]);
    let corner2 = L.latLng(polygonBoundingBox[0][0], polygonBoundingBox[1][1]);
    let bounds = L.latLngBounds(corner1, corner2);

    return (
      <React.Fragment>
        <LeafletMap
          maxZoom={20}
          zoomSnap={0.1}
          dragging={false}
          bounds={bounds}
          boundsOptions={{padding: [20, 20]}}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: mapBottom,
            marginTop: mapMarginTop,
            transition: 'margin-top 0.5s linear',
          }}
          zoomControl={false}
          touchZoom={false}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          boxZoom={false}
        >
          <TileLayer 
            url="https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?app_id=6Cy9N8yG1SZYlO61DQC7&app_code=6yLDAiRRtx2-9-7Wkn4M4g&lg=eng"
          />

          <TileLayer 
            opacity={0.6} 
            url={overlayUrl} 
          />

          {field &&
            <Polygon
              key={field.id}
              positions={geoPointToPosition(field.position)}
              color='rgba(239, 181, 46, 0.9)'
              fillColor='rgba(0, 0, 0, 0.0)'
              fillOpacity={1.0}
              weight={3}
            />
          }

          {observations && observations.length > 0 &&
            <GeoTagList
              fieldId={fieldId}
              observations={observations}
              onClickGeoTag={this.onClickGeoTag}
              geoTagFilters={geoTagFilters}
            />
          }
        </LeafletMap>
      </React.Fragment>
    );
  }
}

export default withRouter(OverviewGeoTagsMap);
