import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Segment,
  Image,
  Modal,
  Button,
  Header,
  Icon,
} from "semantic-ui-react";

import moment from 'moment';
import 'moment/locale/sv';

import { FIELD_DATA_LAYERS, FIELD_ANALYSIS_LAYERS } from "@/constants/viewLayersAnalysis";

import yieldOverviewIcon from "@/assets/icons/yield-overview.svg";
import observationIcon from "@/assets/icons/dataTypes/geotag.svg";
import userIcon from "@/assets/icons/menuPrimary/to-do.svg";
import emptyIllustration from "@/assets/illustrations/done_simple.png"

import MenuTop from "@/components/menus/MenuTop";

const ReminderSegment = ({ id, onClick, icon, header, description, descriptionMargin, fieldNames, seen, urgent }) => {
  return (
    <Segment
      key={"reminder_segment_" + id}
      basic
      className="backgroundColorOnHover"
      style={{
        margin: 7,
        padding: 10,
        cursor: "pointer",
        backgroundColor: "#ffffff",
        borderRadius: 5,
        width: "calc(100% - 20px)",
        marginLeft: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            backgroundColor: urgent && (typeof (seen) !== "boolean" || seen !== true) ? "#C36126" : "#F8EED0",
            borderRadius: 5,
            width: "2.4em",
            height: "2.4em",
            marginRight: "1em",
          }}
        >
          <Image
            style={{
              marginTop: "0.5em",
              marginLeft: "0.5em",
              width: "1.4em",
              height: "1.4em",
              filter: urgent && (typeof (seen) !== "boolean" || seen !== true) ? "invert(100) brightness(100)" : "invert(0)",
            }}
            src={icon}
          />
        </div>

        <div
          style={{
            marginBottom: 0,
            marginLeft: 10,
            width: "90%",
          }}
          onClick={() => onClick()}
        >
          <div style={{ lineHeight: "120%" }}>{header && header}</div>
          {fieldNames && <div style={{ color: "grey", fontSize: "90%" }}>{fieldNames}</div>}
          {description && <div style={{ color: "grey", fontSize: "90%", marginTop: descriptionMargin ? 10 : null }}>{description}</div>}
        </div>
      </div>
    </Segment>
  )
};

const INITIAL_STATE = {
  mode: 'current',
  showOpenVraMapGuide: false,
  vraMapData: null,
  noRemindersToShow: 10,
  prevY: 0,
};

class RemindersList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );

    this.observer.observe(this.bottomDivRef);
  };

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {
      this.setState({ noRemindersToShow: this.state.noRemindersToShow + 5 });
    };

    this.setState({ prevY: y });
  };

  getReminder = (data) => {
    if (data && data.type && data.type === "guideActionRequired") {
      return this.getGuideActionRequiredSegment(data)
    };

    if (data && data.type && data.type === "guideReminder") {
      return this.getGuideReminderSegment(data)
    };

    if (data && data.type && data.type === "observationReminder") {
      return this.getObservationReminderSegment(data)
    };

    if (data && data.type && data.type === "userReminder") {
      return this.getUserReminderSegment(data)
    };
  };

  getEmptySegment = (mode = "today") => {

    if (mode === "today") {
      return (
        <Segment
          basic
          style={{
            display: "block",
            marginTop: "15vh",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          <Image
            centered
            style={{
              height: "40vh",
            }}
            src={emptyIllustration}
          />

          <div
            style={{
              fontWeight: 500,
              marginBottom: 0,
              textAlign: "center",
            }}
          >
            Du har inga planerade aktiviteter i Freja idag.
          </div>
        </Segment>
      )
    } else {
      return (
        <Segment
          basic
          style={{
            margin: 7,
            padding: 10,
            cursor: "pointer",
            backgroundColor: "#ffffff",
            borderRadius: 5,
            width: "calc(100% - 20px)",
            marginLeft: 10,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <Image
              style={{
                width: "2.4em",
                height: "2.4em",
                marginRight: "1em",

              }}
              src={emptyIllustration}
            />

            <div
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: 10,
                width: "80%",
              }}
            >
              Inga planerade aktiviteter i Freja.
            </div>
          </div>
        </Segment>
      )
    };
  };

  openUploadFileGuide = (data) => {
    if (data && data.data_type && data.guide_id && data.current_guide_step) {
      this.props.history.push("/" + data.data_type + "/" + data.current_guide_step + "/" + data.guide_id);
    };
  };

  openObservation = (data) => {
    if (data && data.field_id && data.observation_id) {
      this.props.history.push("/geotags/" + data.field_id + "/details/" + data.observation_id);
    };
  };

  openVraMapGuide = (data) => {
    if (data && data.guide_id) {
      this.props.history.push("/guides/downloadFiles/" + data.guide_id);
      this.toggleOpenVraMapGuide();
    };
  };

  setVraMapToUsed = (data) => {
    this.props.setVraMapToUsed(data);
    this.toggleOpenVraMapGuide();
  };

  toggleOpenVraMapGuide = (data) => {
    this.setState({
      showOpenVraMapGuide: !this.state.showOpenVraMapGuide,
      vraMapData: data ? data : null,
    });
  };

  getGuideActionRequiredSegment = (data) => {
    let title = "Freja behöver din hjälp";
    let icon = yieldOverviewIcon;
    let complete = data && typeof (data.complete) === "boolean" && data.complete;

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    let noFields = data && data.new_entries && data.new_entries.length;

    if (data && data.data_type && data.data_type === "guidesUploadSoilMaps") {
      title = "Freja behöver din hjälp med " + (noFields > 1 ? noFields : "en") + ((!noFields || noFields < 2) ? " markkartering" : " markkarteringar")
      icon = FIELD_DATA_LAYERS.soilMapSweden.icon;
    };

    if (data && data.data_type && data.data_type === "guidesUploadYieldMaps") {
      title = "Freja behöver din hjälp med " + (noFields > 1 ? noFields : "en") + ((!noFields || noFields < 2) ? " skördekarta" : " skördekartor")
      icon = FIELD_DATA_LAYERS.yieldMap.icon;
    };

    return (
      <ReminderSegment
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={() => this.openUploadFileGuide(data)}
        seen={complete}
        urgent={true}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
      />
    )
  };

  getGuideReminderSegment = (data) => {
    let title = "Du har en påminnelse...";
    let description = "";
    let fieldNames = "";
    let icon = yieldOverviewIcon;

    let complete = data && typeof (data.complete) === "boolean" && data.complete;
    let purpose = data && data.guide_purpose;
    let urgent = data && typeof (data.condition_active) === "boolean" ? data.condition_active : false;

    if (data && data.reminder_type && data.reminder_type === "planNewFiles") {
      title = "Det är dags att planera nya styrfiler i Freja för" + (purpose ? " " + purpose : "...");
      icon = FIELD_ANALYSIS_LAYERS.vraMap.icon;
    };

    if (data && data.reminder_type && data.reminder_type === "useFiles") {
      title = "Det är dags att använda dina styrfiler i Freja för" + (purpose ? " " + purpose : "...");
      icon = FIELD_ANALYSIS_LAYERS.vraMap.icon;
    };

    let noFields = data && data.new_entries && data.new_entries.length;
    fieldNames = noFields && noFields > 0 ? noFields.toFixed(0) + " skiften. " : fieldNames;

    description = data && data.condition_reason ? data.condition_reason : description;

    return (
      <ReminderSegment
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        fieldNames={fieldNames && fieldNames}
        description={description && description}
        descriptionMargin={true}
        icon={icon}
        onClick={() => this.toggleOpenVraMapGuide(data)}
        seen={complete}
        urgent={urgent}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
      />
    )
  };

  getObservationReminderSegment = (data) => {
    let title = "Återbesöka en observation";
    let description = "Typ: " + data.observation_type;
    let icon = observationIcon;

    let fieldNames = data && data.field_name && "Skifte: " + data.field_name;
    let complete = data && typeof (data.complete) === "boolean" && data.complete;
    let urgent = data && typeof (data.condition_active) === "boolean" ? data.condition_active : false;

    return (
      <ReminderSegment
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        description={description && description}
        fieldNames={fieldNames && fieldNames}
        descriptionMargin={false}
        icon={icon}
        onClick={() => this.openObservation(data, false)}
        seen={complete}
        urgent={urgent}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
      />
    )
  };

  getUserReminderSegment = (data) => {
    let title = data && data.header;
    let description = data && data.note;
    let icon = userIcon;

    let complete = data && typeof (data.complete) === "boolean" && data.complete;
    let urgent = data && typeof (data.condition_active) === "boolean" ? data.condition_active : false;

    return (
      <ReminderSegment
        key={data.id}
        id={data.id}
        header={(title ? title : "") + "."}
        description={description && description}
        descriptionMargin={false}
        icon={icon}
        seen={complete}
        urgent={urgent}
        markReminderRead={this.markReminderRead}
        deleteReminder={this.toggleDeleteReminder}
      />
    )
  };

  getReminderSegment = (db, mode, header) => {
    const {
      noRemindersToShow,
    } = this.state;

    let reminders = db && db.reminders && db.reminders;

    reminders = reminders && Object.keys(reminders).map(x => ({ id: x, ...db.reminders[x] }));
    reminders = reminders && reminders.filter((x) => typeof (x.type) === "string");

    switch (mode) {
      case 'today':
        reminders = reminders && reminders.filter((x) =>
          !x.complete && (
            x.condition_active === true || x.urgent === true || x.type === "guideActionRequired"
          )
        );
        break;

      case 'tomorrow':
        reminders = reminders && reminders.filter((x) => {
          let formattedDate;

          if (x.condition && x.condition.first_date) {
            if (typeof (x.condition.first_date) === "string") {
              formattedDate = moment(x.condition.first_date, "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD");
            } else {
              formattedDate = moment(x.condition.first_date.toDate()).endOf("day").format("YYYY-MM-DD");
            };
          };

          return !x.complete && formattedDate && formattedDate === moment().add(1, "day").format("YYYY-MM-DD");
        });
        break;

      case 'week':
        reminders = reminders && reminders.filter((x) => {
          let formattedDate;

          if (x.condition && x.condition.first_date) {
            if (typeof (x.condition.first_date) === "string") {
              formattedDate = moment(x.condition.first_date, "YYYY-MM-DD").endOf("day");
            } else {
              formattedDate = moment(x.condition.first_date.toDate()).endOf("day");
            };
          }

          return !x.complete && formattedDate && moment().add(1, "day").endOf("day") < formattedDate && formattedDate <= moment().add("7", "days").endOf("day");
        });
        break;

      case 'later':
        reminders = reminders && reminders.filter((x) => {
          let formattedDate;

          if (x.condition && x.condition.first_date) {
            if (typeof (x.condition.first_date) === "string") {
              formattedDate = moment(x.condition.first_date, "YYYY-MM-DD").endOf("day");
            } else {
              formattedDate = moment(x.condition.first_date.toDate()).endOf("day");
            };
          }

          return !x.complete && (!formattedDate || formattedDate > moment().add("7", "days").endOf("day"));
        });
        break;

      case 'done':
        reminders = reminders && reminders.filter((x) => {
          return x.complete;
        });
        break;

      default:
        return <div />
    }

    reminders = reminders && reminders.sort((a, b) => b.date - a.date);

    let noReminders = reminders && reminders.length;
    reminders = ["done", "later"].includes(mode) ? reminders && reminders.splice(0, noRemindersToShow) : reminders;

    let reminderSegments = reminders && reminders.map((x) => this.getReminder(x));
    reminderSegments = reminderSegments && reminderSegments.filter((x) => x !== null);

    if (mode === "today" && (!reminderSegments || reminderSegments.length === 0)) {
      return this.getEmptySegment(mode);
    };

    reminderSegments = reminderSegments && reminderSegments.length > 0 ? reminderSegments : this.getEmptySegment(mode);

    return (
      <React.Fragment>
        <Header
          style={{
            fontWeight: 500,
            marginLeft: "1em",
            marginRight: "1em",
            marginTop: 15,
          }}
          as="h4"
        >
          {header && header}
        </Header>

        {reminderSegments && reminderSegments}

        {["done", "later"].includes(mode) && noReminders >= noRemindersToShow &&
          <Segment
            basic
            style={{
              color: "grey",
              textAlign: "center",
              padding: 0,
              margin: 0,
              marginBottom: 0,
              paddingBottom: 10,
              fontWeight: "normal",
              fontSize: "85%",
            }}
            onClick={() => this.setState({ noRemindersToShow: this.state.noRemindersToShow + 5 })}
          >
            Klicka här eller scrolla för att visa fler aktiviteter
              <Icon name="caret down" />
          </Segment>
        }

        {["done", "later"].includes(mode) && reminderSegments && reminderSegments.length > 0 && noReminders > 10 && noReminders < noRemindersToShow &&
          <Segment
            basic
            style={{
              color: "grey",
              textAlign: "center",
              padding: 0,
              margin: 0,
              marginBottom: 0,
              paddingBottom: 10,
              fontWeight: "normal",
              fontSize: "85%",
            }}
          >
            Inga fler aktiviteter hittades
            </Segment>
        }
      </React.Fragment>
    )
  }

  render() {
    const {
      db,
      caption,
    } = this.props;

    const {
      mode,
      showOpenVraMapGuide,
      vraMapData,
    } = this.state;

    return (
      <React.Fragment>
        <Modal
          open={showOpenVraMapGuide}
          style={{
            zIndex: 9,
          }}
          size="fullscreen"
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Vad vill du göra?
          </Modal.Header>

          <Modal.Content>
            <Button
              style={{
                marginTop: "3em",
              }}
              fluid
              primary
              onClick={() => this.openVraMapGuide(vraMapData)}
            >
              Hämta styrfiler och PDF-karta
            </Button>

            <Button
              style={{
                marginTop: "3em",
                marginBottom: "3em",
              }}
              fluid
              positive
              onClick={() => this.setVraMapToUsed(vraMapData)}
            >
              Klarmarkera styrfiler
            </Button>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={() => this.toggleOpenVraMapGuide()}>Stäng</Button>
          </Modal.Actions>
        </Modal>

        <div
          style={{
            padding: 0,
            width: "100%",
          }}
        >
          <MenuTop caption={caption}>
            <div>
              {mode === "current" &&
                <div
                  style={{
                    display: "inline-block",
                    color: "#6B9D9F",
                    fontSize: "95%",
                    cursor: "pointer",
                    fontWeight: 600,
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: 10,
                  }}
                  onClick={() => this.props.history.push("/add/addReminder")}
                >
                  Lägg till
                </div>
              }

              {mode !== "current" &&
                <div
                  style={{
                    display: "inline-block",
                    color: "#6B9D9F",
                    fontSize: "95%",
                    cursor: "pointer",
                    fontWeight: 600,
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: 10,
                  }}
                  onClick={() => this.setState({ mode: mode === "coming" ? "done" : "coming" })}
                >
                  {mode === "coming" && "Genomförda aktiviteter"}
                  {mode === "done" && "Planerade aktiviteter"}
                </div>
              }

              &bull;

              <div
                style={{
                  display: "inline-block",
                  color: "#6B9D9F",
                  fontSize: "95%",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 10,
                }}
                onClick={() => this.setState({ mode: ["done", "coming"].includes(mode) ? "current" : "coming" })}
              >
                {mode === "current" && "Planerade aktiviteter"}
                {mode !== "current" && "Dagens aktiviteter"}
              </div>
            </div>
          </MenuTop>

          <Segment.Group
            style={{
              border: "none",
              boxShadow: "none",
              marginTop: 50,
              width: "100%",
              overflowY: "auto",
            }}
          >
            {mode === "current" && this.getReminderSegment(db, "today", "Idag")}

            {mode === "coming" && this.getReminderSegment(db, "tomorrow", "Imorgon")}
            {mode === "coming" && <div style={{ height: 20 }} />}
            {mode === "coming" && this.getReminderSegment(db, "week", "Under veckan")}
            {mode === "coming" && <div style={{ height: 20 }} />}
            {mode === "coming" && this.getReminderSegment(db, "later", "Senare")}

            {mode === "done" && this.getReminderSegment(db, "done", "Genomförda")}

            <div ref={bottomDivRef => (this.bottomDivRef = bottomDivRef)} />
          </Segment.Group>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(RemindersList);
