import React, { Component } from 'react';

import styled from 'styled-components';

const FloatingDiv = styled.div`
  z-index: 8000;
  background-color: rgba(0, 0, 0, 0.5);
  top: 65px;
  right: auto;
  left: 1em;
  position: absolute;
  float: right;
  padding: 1em;
`;

class MapToolbar extends Component {

  getTextLine = (text) => (
    <div 
      style={{
        color: "white", 
      }}
    >
      {text}
    </div>
  )

  render () {
    const {
      name,
      date,
      cropShortText,
    } = this.props;

    if (!name && !date && !cropShortText) {
      return null
    }

    return (
      <FloatingDiv >
        {name && this.getTextLine(name)}
        {date && this.getTextLine(date)}
        {cropShortText && this.getTextLine(cropShortText)}
      </FloatingDiv>
    );
  }
};

export default MapToolbar;
