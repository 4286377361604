import React, { Component } from "react";

import { 
  Header, 
  Table, 
  Modal,
} from "semantic-ui-react";

import chroma from "chroma-js";

import { Bar } from "react-chartjs-2";

import { getCurrentDataLayer } from "@/constants/viewLayersAnalysis";

const INITIAL_STATE = {
  showStatsModal: false,
};

class MapStatsBasic extends Component {
  state = { ...INITIAL_STATE };
  
  getThousandSeparators = (number, noDecimals=2) => {
    let output = '';

    output = (typeof(number) === "number" && number >= 1000.0) ? (Math.floor(number / 10) * 10).toLocaleString('sv') : output;
    output = (typeof(number) === "number" && number < 1000.0) ? Math.floor(number).toFixed(noDecimals) : output;

    return output;
  };

  render () {
    const {
      dataStats,
      layer,
      fieldSize,
    } = this.props;

    if (!dataStats) {
      return <div />
    }

    // Get the data type of the current data layer (float, percent, integer, etc.)
    let dataLayer = getCurrentDataLayer(layer);

    let dataLayerType = dataLayer && dataLayer.dataType && dataLayer.dataType[layer];
    let dataHistogramLabels = dataLayer && dataLayer.histogramLabels && dataLayer.histogramLabels[layer.replace("_hotspot", "")];

    let isFloatLayer = dataLayerType === "float";

    if (!isFloatLayer) {
      return (
        <div />
      )
    };

    let noDecimals = typeof(dataHistogramLabels.noDecimals) === "number" ? dataHistogramLabels.noDecimals : 1;
    let prefix = dataHistogramLabels.prefix ? dataHistogramLabels.prefix : "";
    let postfix = dataHistogramLabels.postfix ? dataHistogramLabels.postfix : "";

    // Handle Sentinel layers
    let scaleFactor = 1.0;
    scaleFactor = layer.includes("greenmass") ? 100.0 : scaleFactor;
    scaleFactor = layer.includes("moisture") ? 100.0 : scaleFactor;
    scaleFactor = layer.includes("lai") ? 100.0 : scaleFactor;
    scaleFactor = layer.includes("psri") ? 100.0 : scaleFactor;

    noDecimals = layer.includes("greenmass") ? 0 : noDecimals;
    noDecimals = layer.includes("moisture") ? 0 : noDecimals;
    noDecimals = layer.includes("lai") ? 0 : noDecimals;
    noDecimals = layer.includes("psri") ? 0 : noDecimals;


    let values = dataStats && dataStats.values;
    let noBins = values && Math.floor(Math.sqrt(values.length));
    let barPlotLimits = values && chroma.limits(values, 'e', noBins);

    let barPlotGroups = [];
    let noSamples;
  
    if (barPlotLimits) {
      for (let idx = 0; idx < barPlotLimits.length - 1; idx++) {
        let subSamples = [];
  
        values && values.forEach((x) => {
          let currentValue = parseFloat(x) * scaleFactor;
  
          let lowerLimit = barPlotLimits[idx] * scaleFactor;
          let upperLimit = barPlotLimits[idx + 1] * scaleFactor;
  
          lowerLimit = (idx === 0) ? lowerLimit - 0.001 : lowerLimit;
          upperLimit = (idx === barPlotLimits.length - 2) ? upperLimit + 0.001 : upperLimit;
  
          if ((currentValue - lowerLimit) >= 0.0 && (currentValue - upperLimit) < 0.0) {
            subSamples = [...subSamples, { value: currentValue, idx: x.idx }];
          }
        });
  
        noSamples = subSamples && subSamples.length;
  
        barPlotGroups = [...barPlotGroups, {
          noSamples: noSamples,
          max: barPlotLimits[idx + 1] * scaleFactor,
          min: barPlotLimits[idx] * scaleFactor,
        }]
      }
    };

    return (
      <Modal 
        size="fullscreen"
        open={true} 
      >
        <Modal.Content>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.5em"
            }}
          >
            <Header
              style={{ 
                fontWeight: 500,
              }}
            >
              Statistik
            </Header>
          </div>

          <article
            className="canvas-container"
            style={{
              width: "100%",
              height: "40vh",
              background: "transparent",
              padding: "1em"
            }}
          >
            <Bar 
              data={{
                labels: barPlotGroups && barPlotGroups.map((x) => x.min.toFixed(2) + " - " + x.max.toFixed(2)),
                datasets: [
                  {
                    data: barPlotGroups && barPlotGroups.map((x) => Math.round(100.0 * fieldSize * x.noSamples / values.length) / 100.0),
                    backgroundColor: "#89A275",
                    borderColor: "#4C8440"
                  }
                ]
              }}
              options={{ 
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                    }
                },
                animation: {
                    duration: 1000
                },
                legend: {
                  display: false,
                  position: "bottom",
                  labels: {
                    fontSize: 14,
                    fontColor: 'black',
                    padding: 20,
                  }
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      fontSize: 14,
                      fontColor: 'grey',
                    },
                    scaleLabel: {
                      display: true,
                      fontSize: 12,
                      fontColor: 'black',
                      labelString: "Yta (ha)",
                    }
                  }],
                  xAxes: [{
                    ticks: {
                      fontSize: 14,
                      fontColor: 'grey',
                    },
                    scaleLabel: {
                      display: true,
                      fontSize: 12,
                      fontColor: 'black',
                      labelString: prefix + postfix,
                    }
                  }],           
                }
              }} 
            />
          </article>

          <Table 
            celled 
            striped 
            unstackable
            columns="2"
          >
            <Table.Body>
              {dataStats && typeof(dataStats.min) === "number" && 
                <Table.Row>
                  <Table.Cell textAlign="center">Lägsta värde</Table.Cell>
                  <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.min).toFixed(noDecimals) + postfix}</Table.Cell>
                </Table.Row>
              }

              {dataStats && typeof(dataStats.max) === "number" && 
                <Table.Row>
                  <Table.Cell textAlign="center">Högsta värde</Table.Cell>
                  <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.max).toFixed(noDecimals) + postfix}</Table.Cell>
                </Table.Row>
              }

              {dataStats && typeof(dataStats.median) === "number" && 
                <Table.Row>
                  <Table.Cell textAlign="center">Median</Table.Cell>
                  <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.median).toFixed(noDecimals) + postfix}</Table.Cell>
                </Table.Row>
              }

              {dataStats && typeof(dataStats.mean) === "number" &&          
                <Table.Row>
                  <Table.Cell textAlign="center">Medelvärde</Table.Cell>
                  <Table.Cell textAlign="center">{prefix + (scaleFactor * dataStats.mean).toFixed(noDecimals) + postfix}</Table.Cell>
                </Table.Row>
              }
                
              {dataStats && typeof(dataStats.variance) === "number" &&                  
                <Table.Row>
                  <Table.Cell textAlign="center">Standardavvikelse</Table.Cell>
                  <Table.Cell textAlign="center">{prefix + (scaleFactor * Math.sqrt(dataStats.variance)).toFixed(noDecimals) + postfix}</Table.Cell>
                </Table.Row>
              }
            </Table.Body>
          </Table>             
        </Modal.Content>          
      </Modal>
    );
  }
};

export default MapStatsBasic;

