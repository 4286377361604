import React from 'react';

import {
  Segment, 
  Header,
  Image,
} from 'semantic-ui-react';

import { GEOTAG_TYPES } from "@/constants/geotags";

const GetGeoTagSummary = (props) => {
  const {
    newTag,
  } = props;

  const {
    mainType,
    subType,
    radius,
    radiusText,
    amount,
    amountText,
  } = newTag;

  let selectedMarkerType = mainType && GEOTAG_TYPES[mainType];
  let selectedMarkerSubType = selectedMarkerType && subType &&
    selectedMarkerType.subTypes &&
    selectedMarkerType.subTypes[subType];

  let cardHeader = selectedMarkerType && selectedMarkerType.title;
  let cardSubHeader = selectedMarkerSubType && selectedMarkerSubType.title;
  let icon = selectedMarkerType && selectedMarkerType.icon;

  return (
    <div
      style={{
        left: 0, 
        right: 0, 
        top: 50, 
        position: 'absolute',
        zIndex: 9,
        backgroundColor: "#f7f4f0",
      }}    
    >
      <Segment
        secondary
        style={{
          margin: "0.5em",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Image
          style={{
            width: "4em",
            height: "4em",
          }}
          src={icon}
        />

        <div
          style={{
            width: "80%",
            marginLeft: "1em",
          }}
        >
          <Header 
            as="h3"
            style={{
              fontWeight: 500,
              marginBottom: 4,
            }}
          >
            {cardSubHeader ? cardSubHeader : cardHeader}
          </Header>
        
          <p style={{ margin: 0 }}>{amount && <span><b>Mängd:</b> {amountText}.</span>}</p>
          <p style={{ margin: 0 }}>{radius && <span><b>Radie:</b> {radiusText}. </span>}</p>
        </div>
      </Segment>
    </div>
  );
};

export default GetGeoTagSummary;