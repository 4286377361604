import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { 
  Modal, 
  Segment,
  Icon,
  Header,
} from 'semantic-ui-react';


const ADD_MENU_ITEMS = [
  {name: '/add/addObservation/new/selectMapLocation', icon: 'map marker alternate', color: "#9CB5B6", caption: 'Observation', subCaption: ''},
  {name: '/add/addReminder', icon: 'bell', color: "#89A275", caption: 'Planering', subCaption: ''},
  // {name: '/add/photo', icon: 'photo', color: "#9CB5B6", caption: 'Foto', subCaption: ''},
];

const INITIAL_STATE = {};

class MenuAdd extends Component {

  state = INITIAL_STATE;

  getAddMenuIcon = (name, icon, color, caption, subCaption, onClick) => {
    return (
      <Header 
        as='h2' 
        icon 
        textAlign='center'
        style={{
          paddingBottom: '15px',
          color: "white",
        }}
        onClick={() => onClick(name)}
      >
        <Icon 
          name={icon} 
          circular 
          style={{
            backgroundColor: color, 
            fontSize: '27px',
            color: 'white',
          }}
        />

        <Header.Content
          style={{
            fontWeight: 'normal',
            fontSize: '9pt',
            lineHeight: '11pt',
            width: "80%",
            whiteSpace: "pre-line",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {caption} {subCaption && <br/>} {subCaption && subCaption}
        </Header.Content>
      </Header>  
    )
  };

  openUrl = (url) => {
    url && this.props.history.push(url);
    this.props.toggleShowAddMenuModal();
  }
  

  render ()  {
    const {
      showAddMenuModal, 
      toggleShowAddMenuModal, 
    } = this.props;

    return (
      <Modal
        open={showAddMenuModal}
        onClose={toggleShowAddMenuModal}
        basic
        size='fullscreen'
        style={{
          position: 'absolute',
          height: '20em',
          top: 'auto',
          bottom: 0,
          margin: 0,
        }}   
      >
        <Segment
          style={{
            display: "block",
            color: "white",
            textAlign: "center",    
            width: "100%",
            height: "100%",
            paddingTop: "2em",  
            border: "4px solid black !important"  ,
            backgroundColor: '#363e40',  
          }}
        >
          <div
            style={{
              marginTop: "2em",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {ADD_MENU_ITEMS.filter((x, idx) => idx < 3 ).map((x) =>
              <div key={x.name} style={{ width: "33%" }}>
                  {this.getAddMenuIcon(x.name, x.icon, x.color, x.caption, x.subCaption, () => this.openUrl(x.name))}
              </div>
            )}          
          </div>
        </Segment>
      </Modal>
    );
  };
}

export default withRouter(MenuAdd);