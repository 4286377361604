import React, { Component } from 'react';

import {
  Marker, 
  Tooltip
} from 'react-leaflet';

import L from 'leaflet';

import { GEOTAG_TYPES } from "@/constants/geotags";

import mePosIcon from '@/assets/icons/map/mePos.png';
import defaultIcon from '@/assets/icons/map/misc.png';
export class GeoTag extends Component {

  markerRef = React.createRef();

  render() {
    const {
      type,
    } = this.props;

    let selectedMarkerType = type && GEOTAG_TYPES && GEOTAG_TYPES[type];
    
    let icon = selectedMarkerType && selectedMarkerType.icon ? selectedMarkerType.icon : defaultIcon;
    icon = type === "me" ? mePosIcon : icon;

    const markerIcon = new L.Icon({
      iconUrl: icon,
      iconRetinaUrl: icon,
      iconSize: new L.Point(50.50),
      className: 'marker-icon'
    });
    
    return (
      <Marker
        draggable={this.props.draggable}
        position={this.props.position}
        icon={markerIcon}
        onClick={this.props.click ? this.props.click : null}
        interactive={true}
        onDragend={() => this.props.onDraggedMarker(this.markerRef)}
        ref={this.markerRef} 
      >   
        {this.props.draggable && <Tooltip direction="top" offset={[0, -20]} sticky opacity={1} permanent>Dra för att flytta tagg</Tooltip>}
      </Marker>
    );
  };
}

export default GeoTag;
