import React, { Component } from 'react';

import { 
  Menu,
  Icon,
} from 'semantic-ui-react';

import "./style.css";

const INITIAL_STATE = {};

class FieldDataTopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  render () {
    const {
      caption,
      toolbar,
    } = this.props;

    return (
      <div 
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: 50,
          zIndex: 9999,
          backgroundColor: "white",
          width: "100vw",                
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
        }}
      >
        <p
          style={{
            fontSize: "110%",
            width: "90%",
          }}
        > 
          {caption && caption}
        </p> 

        <Menu
          pointing
          fluid
          secondary
          style={{
            paddingLeft: 0,
            marginLeft: 0,
            paddingRight: 5,
            marginRight: -15,
            marginTop: -26,
            borderRadius: 0,
            border: "0px white !important",
            textAlign: "center",
            backgroundColor: "white",
            height: "60px",
          }}
        >
          <Menu.Menu position="right">
            {toolbar && toolbar.rightButtons && toolbar.rightButtons.map((x) => (
              x.visible &&
              <Menu.Item
                style={{
                  background: x.active ? "rgba(251, 189, 8, 0.25)" : "transparent",
                  borderBottom: x.active ? "4px solid rgba(251, 189, 8, 1.0)" : "4px solid white",
                  fontSize: "110%"
                }}
                key={'menuitem_' + x.id}
                active={x.active}
                icon={x.icon}
                onClick={() => x.onClick()}
              />
            ))}

            <Menu.Item
              onClick={() => this.props.closeField()}
              style={{
                marginBottom: 2,
                marginRight: -10,
              }}
            > 
              <Icon name="close" />
            </Menu.Item>            
          </Menu.Menu>
        </Menu>
      </div>
    )
  };
};

export default FieldDataTopMenu;