import React, { Component } from "react";

import {
  Icon,
  Segment,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import { 
  fetchWeatherData, 
  extractDatesAndData, 
  extractDatesAndDataComparison, 
  getDateArray,
} from "@/helpers/weather";

import { getCurrentYear } from "@/helpers/dates";

const INITIAL_STATE = {
  temperatureData: null,
  rainfallData: null,
  irradiationData: null,
  comparisonYear: parseInt(getCurrentYear() - 1).toFixed(0),
};

class WeatherSeasonSummaryWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
  };

  componentDidUpdate(prevProps) {
    const {
      weatherData,
      fileBucketRef,
    } = this.props;

    if (!this.state.temperatureData && weatherData !== prevProps.weatherData) {
      fetchWeatherData(weatherData, fileBucketRef, this.setParentState);
    }
  };

  setParentState = (newState) => this.setState(newState);
  
  render() {
    const {
      comparisonYear,
      temperatureData,
      rainfallData,
      irradiationData,
    } = this.state;

    let currentYear = getCurrentYear();

    let accTemperatureCurrentYear = currentYear && temperatureData && extractDatesAndData(temperatureData, currentYear);
    let accRainfallCurrentYear = currentYear && rainfallData && extractDatesAndData(rainfallData, currentYear);
    let accIrradiationCurrentYear = currentYear && irradiationData && extractDatesAndData(irradiationData, currentYear) / 1000.0;

    let accTemperatureLastYear = comparisonYear && temperatureData && extractDatesAndDataComparison(temperatureData, comparisonYear);
    let accRainfallLastYear = comparisonYear && rainfallData && extractDatesAndDataComparison(rainfallData, comparisonYear);
    let accIrradiationLastYear = comparisonYear && irradiationData && extractDatesAndDataComparison(irradiationData, comparisonYear) / 1000.0;    

    let percentAccTemperature = accTemperatureCurrentYear && accTemperatureLastYear &&
      100.0 * (accTemperatureCurrentYear - accTemperatureLastYear) / accTemperatureLastYear;

    let percentAccRainfall = accRainfallCurrentYear && accRainfallLastYear &&
      100.0 * (accRainfallCurrentYear - accRainfallLastYear) / accRainfallLastYear;

    let percentAccIrradiation = accIrradiationCurrentYear && accIrradiationLastYear &&
      100.0 * (accIrradiationCurrentYear - accIrradiationLastYear) / accIrradiationLastYear;

    let dailyTemperature = currentYear && temperatureData && extractDatesAndData(temperatureData, currentYear, null, true, false, true);

    let plotOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
          padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
          }
      },
      animation: {
        duration: 0
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          display: false
        },
        ],
        xAxes: [{
          display: false
        }],
      }
    };

    let plotTemperature = {
      labels: dailyTemperature && getDateArray().slice(0, dailyTemperature.length),
      datasets: [
        {
          data: dailyTemperature,
          borderColor: "white",
          fill: false,
          pointRadius: 0
        },
      ]
    };

    return (
      <React.Fragment>

        <Segment
          inverted
          style={{
            width: "100%",
            padding: "1.5em",
            backgroundColor: "#D6946B",
          }}
          loading={!comparisonYear || !percentAccTemperature || !dailyTemperature}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div 
              style={{ 
                width: "40%",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  textAlign: "center",
                  fontSize: "160%",
                  color: "white",
                  width: "100%",
                }}
              >
                Dygnsgrader
              </p>

              <p
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: "center",
                  fontSize: "250%",
                  width: "100%",
                }}
              >
                {accTemperatureCurrentYear ? Math.floor(accTemperatureCurrentYear).toLocaleString().replace(/,/g, " ",) : '-'}
                <span style={{ fontSize: "35%" }}>&deg;C</span>

                {percentAccTemperature && 
                  <Icon 
                    style={{
                      fontSize: "75%",
                      marginLeft: 10,
                    }} 
                    name={percentAccTemperature > 0 ? "arrow alternate circle up outline" : "arrow alternate circle down outline"} 
                  />
                }                
              </p>

              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  marginTop: 0,
                  textAlign: "center",
                  color: "white",
                  whiteSpace: "pre-line",
                  width: "100%",
                  fontSize: "95%",
                }}
              >
                vilket {percentAccTemperature ? percentAccTemperature.toFixed(0).replace("-", "") : '-'}% {percentAccTemperature && percentAccTemperature > 0.0 ? "mer" : "mindre"} än samma period under {comparisonYear && comparisonYear}.
              </p>
            </div>

            <div style={{ width: "60%" }}>
              <article
                className="canvas-container"
                style={{
                  paddingTop: 0,
                  marginLeft: "4em",
                  marginRight: "2em",
                  alignContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",                  
                }}
              >
                <Line
                  data={plotTemperature}
                  options={plotOptions}
                />
              </article>
            </div>
          </div>
        </Segment>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
        
          <div style={{ width: "49%" }}>
            <Segment
              inverted
              style={{
                display: "block",
                width: "100%",
                margin: 0,
                padding: "1.5em",
                backgroundColor: "#9CB5B6",
              }}
              loading={!comparisonYear || !percentAccTemperature || !dailyTemperature}
            >
              <p
                style={{
                  fontWeight: "normal",
                  textAlign: "center",
                  margin: 0,
                  fontSize: "150%",
                  color: "white",
                  
                }}
              >
                Nederbörd
              </p>

              <p
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: "center",
                  fontSize: "200%",
                  width: "100%",
                }}
              >
                {accRainfallCurrentYear ? accRainfallCurrentYear.toFixed(0) : '-'}
                <span style={{ fontSize: "35%" }}>  mm</span>

                {percentAccRainfall && 
                  <Icon 
                    style={{
                      fontSize: "75%",
                      marginLeft: 10,
                    }} 
                    name={percentAccRainfall > 0 ? "arrow alternate circle up outline" : "arrow alternate circle down outline"} 
                  />                
                }
              </p>
              
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  textAlign: "center",
                  color: "white",
                  whiteSpace: "pre-line",
                  width: "100%",
                  fontSize: "95%",
                }}
              >
                vilket är {percentAccRainfall ? percentAccRainfall.toFixed(0).replace("-", "") : '-'}% {percentAccRainfall && percentAccRainfall > 0.0 ? "mer" : "mindre"} än samma period under {comparisonYear && comparisonYear}.
              </p>
            </Segment>
          </div>
          
          <div style={{ width: "49%" }}>
            <Segment
              inverted
              style={{
                width: "100%",
                margin: 0,
                padding: "1.5em",
                backgroundColor: "#e0ba8c",
                display: "block",
                height: "100%",
              }}
              loading={!comparisonYear || !percentAccTemperature || !dailyTemperature}
            >
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  textAlign: "center",
                  fontSize: "150%",
                  color: "white",
                  width: "100%",
                }}
              >
                Solinstrålning
              </p>

              <p
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: "center",
                  fontSize: "200%",
                  width: "100%",
                }}
              >
                {accIrradiationCurrentYear ? accIrradiationCurrentYear.toFixed(0) : '-'}
                <span style={{ fontSize: "35%" }}> kW/m2</span>

                {percentAccIrradiation && 
                  <Icon 
                    style={{
                      fontSize: "75%",
                      marginLeft: 10,
                    }} 
                    name={percentAccIrradiation > 0 ? "arrow alternate circle up outline" : "arrow alternate circle down outline"} 
                  />      
                }          
              </p>

              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  textAlign: "center",
                  color: "white",
                  whiteSpace: "pre-line",
                  width: "100%",
                  fontSize: "95%",
                }}
              >
                vilket är {percentAccIrradiation ? percentAccIrradiation.toFixed(0).replace("-", "") : '-'}% {percentAccIrradiation && percentAccIrradiation > 0.0 ? "mer" : "mindre"} än samma period under {comparisonYear && comparisonYear}.          
              </p>
            </Segment>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default WeatherSeasonSummaryWidget;
