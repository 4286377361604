export const VRA_MAP_TYPES = [
  {
    key: 'agco',
    caption: "AGCO",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'ISOXML (8 nivåer)',
        url_name: 'file_path_isoxml_type1',
      }, 
      {
        caption: 'ISOXML',
        url_name: 'file_path_isoxml_type2',
      },           
    ],
  },
  {
    key: 'bogballe',
    caption: "Bogballe",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'Shapefile',
        url_name: 'file_path_shapefile_jd',
      },                
    ],
  },    
  {
    key: 'cnh',
    caption: "CNH",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'ISOXML (8 nivåer)',
        url_name: 'file_path_isoxml_type1',
      }, 
      {
        caption: 'ISOXML',
        url_name: 'file_path_isoxml_type2',
      },           
    ],
  },         
  {
    key: 'jd',
    caption: "John Deere",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'Shapefile',
        url_name: 'file_path_shapefile_jd',
      },        
    ],
  },    
  {
    key: 'kverneland',
    caption: "Kverneland",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'ISOXML',
        url_name: 'file_path_isoxml_type2',
      },           
    ],
  },
  {
    key: 'muller',
    caption: "Müller",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'ISOXML',
        url_name: 'file_path_isoxml_type2',
      },           
    ],
  },        
  {
    key: 'trimble',
    caption: "Trimble",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'Shapefile',
        url_name: 'file_path_shapefile_trimble',
      },        
    ],
  },
  {
    key: 'yara',
    caption: "Yara",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'Bakgrundskarta',
        url_name: 'file_path_surfgridfile',
      },        
    ],
  },  
  {
    key: 'isoxml',
    caption: "Övriga (ISOXML)",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'ISOXML (8 nivåer)',
        url_name: 'file_path_isoxml_type1',
      }, 
      {
        caption: 'ISOXML',
        url_name: 'file_path_isoxml_type2',
      },              
    ],
  },
  {
    key: 'shape',
    caption: "Övriga (Shapefil)",
    files: [
      {
        caption: 'PDF-fil',
        url_name: 'file_path_pdf',
      },
      {
        caption: 'Shapefile',
        url_name: 'file_path_shapefile_jd',
      },        
    ],
  },            
];
  
  export const REMINDER_WEATHER_TYPES = [
    {
      key: 'totalTemp',
      value: 'totalTemp',
      text: 'De sammanräknade dygnsgraderna',
      settings: {
        showTemperatureField: true,
        showRainfallField: false,
        usedSummedData: true,
      }
    },
    {
      key: 'totalTempRain',
      value: 'totalTempRain',
      text: 'De sammanräknade dygnsgraderna och nederbörd',
      settings: {
        showTemperatureField: true,
        showRainfallField: true,
        usedSummedData: true,
      }
    },
    {
      key: 'totalRain',
      value: 'totalRain',
      text: 'Den sammanräknade nederbörden',
      settings: {
        showTemperatureField: false,
        showRainfallField: true,
        usedSummedData: true,
      }
    },
    {
      key: 'dayTemp',
      value: 'dayTemp',
      text: 'Medeltemperaturen över ett dygn',
      settings: {
        showTemperatureField: true,
        showRainfallField: false,
        usedSummedData: false,
      }
    },
    {
      key: 'dayRain',
      value: 'dayRain',
      text: 'Nederbörden över ett dygn',
      settings: {
        showTemperatureField: false,
        showRainfallField: true,
        usedSummedData: false,
      }
    },
  ];
  
  export const REMINDER_WEATHER_TYPES_TIME = [
    {
      key: 'yesterday',
      value: 'yesterday',
      text: 'Dagen efter det intäffat',
    },
    {
      key: 'today',
      value: 'today',
      text: 'Dagen som det kommer inträffa (enligt prognos)',
    },
    {
      key: 'tomorrow',
      value: 'tomorrow',
      text: 'Dagen innan det kommer inträffa (enligt prognos)',
    },
    {
      key: 'tomorrowPlus1',
      value: 'tomorrowPlus1',
      text: 'Två dagar innan det kommer inträffa (enligt prognos)',
    },
    {
      key: 'tomorrowPlus2',
      value: 'tomorrowPlus2',
      text: 'Tre dagar innan det kommer inträffa (enligt prognos)',
    },
  ];