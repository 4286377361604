import imgMenu from "@/assets/icons/menuPrimary/menu.svg";
import imgSoil from "@/assets/icons/dataTypes/soil-data.svg";
import imgSatellite from "@/assets/icons/dataTypes/satellite-data.svg";
import imgYield from "@/assets/icons/dataTypes/yield-data.svg";
import imgVraMap from "@/assets/icons/dataTypes/as-applied-maps.svg";

import {
  COLOR_SCALE_PERCENT,
} from "./colorScales";

import {
  SENTINEL_ATTRIBUTION,
  SGU_ATTRIBUTION,
} from "./map";

export const SHOW_BASIC_STATS_FOR_LAYERS = [
  "greenmass",
  "moisture",
  "lai",
  "psri",
  "vraMap",
  "yieldMap",
  "yieldStatistics",
  "soilMapUploaded",
  "laiAnalysis_clustering",
  "soilMapSweden",
  "elevationMap",
];

export const SHOW_ADVANCED_STATS_FOR_LAYERS = [
  "yieldAnalysis_potential",
  "yieldAnalysis_potentialWinterWheat",
  "yieldAnalysis_clustering",
  "yieldAnalysis_segmentationMedian",
  "yieldAnalysis_segmentationVariance",
  "waterAnalysis_clustering",
  "moistureAnalysis_clustering",
  "greenmassAnalysis_clustering",
  "laiAnalysis_clustering",
  "psriAnalysis_clustering",
];

export const FIELD_DATA_LAYERS = {
  greenmass: {
    name: "greenmass",
    source: "sentinel",
    attribution: SENTINEL_ATTRIBUTION,
    layerIdTypes: ['dates'],
    aliases: [
      "greenmass",
      "greenmass_percent",
      "lai",
      "lai_percent",
      "psri",
      "psri_percent",
      "moisture",
      "moisture_percent",
      "trueColor",
    ],
    icon: imgSatellite,
    caption: "Satellit",
    captions: {
      greenmass: "Grönmassa",
      greenmass_percent: "Grönmassa som % av snitt",
      lai: "Bladyta",
      lai_percent: "Bladyta som % av snitt",
      psri: "Mognadsgrad",
      psri_percent: "Mognadsgrad som % av snitt",
      moisture: "Fukthalt",
      moisture_percent: "Fukthalt som % av snittet",
      trueColor: "Färgbild",
    },
    shortCaptions: {
      greenmass: "Grönmassa",
      greenmass_percent: "Grönmassa som % av snitt",
      lai: "Bladyta",
      lai_percent: "Bladyta som % av snitt",
      psri: "Mognadsgrad",
      psri_percent: "Mognadsgrad som % av snitt",
      moisture: "Fukthalt",
      moisture_percent: "Fukthalt som % av snittet",
      trueColor: "Färgbild",
    },
    helpText: {
      greenmass: "Grönmassa",
      greenmass_percent: "Grönmassa som % av snitt",
      lai: "Bladyta",
      lai_percent: "Bladyta som % av snitt",
      psri: "Mognadsgrad",
      psri_percent: "Mognadsgrad som % av snitt",
      moisture: "Fukthalt",
      moisture_percent: "Fukthalt som % av snittet",
      trueColor: "Färgbild",
    },
    histogramLabels: {
      greenmass: {
        'first': "Glesare",
        'middle': "Genomsnitt",
        'last': "Tätare",
      },
      greenmass_percent: null,
      lai: {
        'first': "Mindre bladyta",
        'middle': "Genomsnitt",
        'last': "Större bladyta",
      },
      lai_percent: null,
      psri: {
        'first': "Mindre mogen",
        'middle': "Genomsnitt",
        'last': "Mer mogen",
      },
      psri_percent: null,
      moisture: {
        first: "Torrare",
        middle: "Genomsnitt",
        last: "Fuktigare",
      },
      moisture_percent: null,
      trueColor: null,
    },
    dbDataDocName: {
      greenmass: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      greenmass_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      lai: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      lai_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      psri: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      psri_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      moisture: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      moisture_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_dates',
      trueColor: "fieldsSentinelHubProcessed/rawdata_sentinel_dates",
    },
    dbPathDocName: {
      greenmass: 'fieldsSentinelHubProcessed/rawdata_sentinel_msavi_path',
      greenmass_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_msavi_path',
      lai: 'fieldsSentinelHubProcessed/rawdata_sentinel_lai_path',
      lai_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_lai_path',
      psri: 'fieldsSentinelHubProcessed/rawdata_sentinel_psri_path',
      psri_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_psri_path',
      moisture: 'fieldsSentinelHubProcessed/rawdata_sentinel_ndwi_path',
      moisture_percent: 'fieldsSentinelHubProcessed/rawdata_sentinel_ndwi_path',
      trueColor: null
    },
    dbStatDocName: 'fieldsSentinelHubRaw/rawdata_sentinel_optical_info_json',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      greenmass: "filtered",
      greenmass_percent: "filtered_percent",
      lai: "filtered",
      lai_percent: "filtered_percent",    
      psri: "filtered",
      psri_percent: "filtered_percent",
      moisture: "filtered",
      moisture_percent: "filtered_percent",  
      trueColor: null,
    },
    dataType: {
      greenmass: "float",
      greenmass_percent: "percent",
      lai: "float",
      lai_percent: "percent",      
      psri: "float",
      psri_percent: "percent",
      moisture: "float",
      moisture_percent: "percent",
      trueColor: null,
    },
    colorScale: {
      greenmass: "Greens",
      greenmass_percent: COLOR_SCALE_PERCENT,
      lai: "Greens",
      lai_percent: COLOR_SCALE_PERCENT,
      psri: "OrRd",
      psri_percent: COLOR_SCALE_PERCENT,
      moisture: "Blues",
      moisture_percent: COLOR_SCALE_PERCENT,
      trueColor: null,
    },
    colorLimit: {},
    onlyBeta: [],
    includedInPlans: ['all'],
  },
  yieldMap: {
    name: "yieldMap",
    source: "yieldMap",
    layerIdTypes: ['years'],
    aliases: [
      "yieldMap",
      "yieldMap_dryYield_percent",
      "yieldMap_wetYield",
      "yieldMap_wetYield_percent",
      "yieldMap_waterContent",
      "yieldMap_waterContent_percent"
    ],
    icon: imgYield,
    caption: "Skördekarta",
    captions: {
      yieldMap: "Torrvikt med 14% vattenhalt",
      yieldMap_dryYield_percent: "Torrvikt som % av snitt",
      yieldMap_wetYield: "Våtvikt",
      yieldMap_wetYield_percent: "Våtvikt som % av snitt",
      yieldMap_waterContent: "Vattenhalt",
      yieldMap_waterContent_percent: "Vattenhalt som % av snitt",
    },
    shortCaptions: {
      yieldMap: "Torrvikt",
      yieldMap_dryYield_percent: "Torrvikt som % av snitt",
      yieldMap_wetYield: "Våtvikt",
      yieldMap_wetYield_percent: "Våtvikt som % av snitt",
      yieldMap_waterContent: "Vattenhalt",
      yieldMap_waterContent_percent: "Vattenhalt som % av snitt",
    },
    helpText: {
      yieldMap: "Torrvikten hos din skörd omräknad med 14% vattenhalt.",
      yieldMap_dryYield_percent: "Torrvikt som procent av snittskörden på fältet omräknat med 14% vattenhalt.",
      yieldMap_wetYield: "Våtvikten hos din skörd.",
      yieldMap_wetYield_percent: "Våtvikt som procent av snittskörden på fältet.",
      yieldMap_waterContent: "Vattenhalt hos din skörd.",
      yieldMap_waterContent_percent: "Vattenhalt som procent av medelvärdet på vattenhalten på fältet.",
    },
    histogramLabels: {
      yieldMap: { postfix: " ton/ha", noDecimals: 2 },
      yieldMap_dryYield_percent: null,
      yieldMap_wetYield: { postfix: " ton/ha", noDecimals: 2 },
      yieldMap_wetYield_percent: null,
      yieldMap_waterContent: { postfix: "%" },
      yieldMap_waterContent_percent: null,
    },
    dbDataDocName: 'fieldsYieldMapsProcessed/rawdata_harvest_maps_geojson',
    dbStatDocName: 'fieldsYieldMapsProcessed/rawdata_harvest_maps_stats',
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      yieldMap: "dry_yield",
      yieldMap_dryYield: "dry_yield",
      yieldMap_dryYield_percent: "dry_yield_percent",
      yieldMap_wetYield: "wet_yield",
      yieldMap_wetYield_percent: "wet_yield_percent",
      yieldMap_waterContent: "water_content",
      yieldMap_waterContent_percent: "water_content_percent",
    },
    dataType: {
      yieldMap: "float",
      yieldMap_dryYield: "float",
      yieldMap_dryYield_percent: "percent",
      yieldMap_wetYield: "float",
      yieldMap_wetYield_percent: "percent",
      yieldMap_waterContent: "float",
      yieldMap_waterContent_percent: "percent",
    },
    colorScale: {
      yieldMap: "Greens",
      yieldMap_dryYield: "Greens",
      yieldMap_dryYield_percent: COLOR_SCALE_PERCENT,
      yieldMap_wetYield: "Purples",
      yieldMap_wetYield_percent: COLOR_SCALE_PERCENT,
      yieldMap_waterContent: "Blues",
      yieldMap_waterContent_percent: COLOR_SCALE_PERCENT,
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },

  soilMapUploaded: {
    name: "soilMapUploaded",
    source: "soilMapUploaded",
    layerIdTypes: ['years'],
    aliases: [
      "soilMapUploaded",
      "soilMapUploaded_ph",
      "soilMapUploaded_p_al",
      "soilMapUploaded_k_al",
      "soilMapUploaded_ca_al",
      "soilMapUploaded_mg_al",
      "soilMapUploaded_p_hcl",
      "soilMapUploaded_k_hcl",
      "soilMapUploaded_ca_hcl",
      "soilMapUploaded_mg_hcl",
      "soilMapUploaded_cu_hcl",
      "soilMapUploaded_bor",
      "soilMapUploaded_fe_al",
      "soilMapUploaded_al_al",
      "soilMapUploaded_cd_hno3",
      "soilMapUploaded_clay",
      "soilMapUploaded_sand",
      "soilMapUploaded_silt",
      "soilMapUploaded_organic",
      "soilMapUploaded_k_mg_ratio",
      "soilMapUploaded_samples",
    ],
    icon: imgSoil,
    caption: "Markkartering",
    captions: {
      soilMapUploaded: "pH-värde",
      soilMapUploaded_p_al: 'Lättlösligt fosfor',
      soilMapUploaded_k_al: 'Lättlösligt kalium',
      soilMapUploaded_ca_al: 'Lättlösligt kalcium',
      soilMapUploaded_mg_al: 'Lättlösligt magnesium',
      soilMapUploaded_p_hcl: 'Förråd fosfor',
      soilMapUploaded_k_hcl: 'Förråd kalium',
      soilMapUploaded_ca_hcl: 'Förråd kalcium',
      soilMapUploaded_mg_hcl: 'Förråd magnesium',
      soilMapUploaded_cu_hcl: 'Förråd koppar',
      soilMapUploaded_bor: "Bor",
      soilMapUploaded_fe_al: "Lättlösligt järn",
      soilMapUploaded_al_al: "Lättlösligt aluminium",
      soilMapUploaded_k_mg_ratio: "Kalium-magnesium-kvot",
      soilMapUploaded_cd_hno3: "Kadmium",
      soilMapUploaded_clay: "Lerhalt",
      soilMapUploaded_sand: "Sandhalt",
      soilMapUploaded_silt: "Silthalt",
      soilMapUploaded_organic: "Mullhalt",
      soilMapUploaded_samples: "Tagna markprover",
    },
    shortCaptions: {
      soilMapUploaded: "pH-värde",
      soilMapUploaded_p_al: 'Lättlösligt fosfor',
      soilMapUploaded_k_al: 'Lättlösligt kalium',
      soilMapUploaded_ca_al: 'Lättlösligt kalcium',
      soilMapUploaded_mg_al: 'Lättlösligt magnesium',
      soilMapUploaded_p_hcl: 'Förråd fosfor',
      soilMapUploaded_k_hcl: 'Förråd kalium',
      soilMapUploaded_ca_hcl: 'Förråd kalcium',
      soilMapUploaded_cu_hcl: 'Förråd koppar',
      soilMapUploaded_mg_hcl: 'Förråd magnesium',
      soilMapUploaded_bor: "Bor",
      soilMapUploaded_fe_al: "Lättlösligt järn",
      soilMapUploaded_al_al: "Lättlösligt aluminium",
      soilMapUploaded_cd_hno3: "Kadmium",
      soilMapUploaded_k_mg_ratio: "Kalium-magnesium-kvot",
      soilMapUploaded_clay: "Lerhalt",
      soilMapUploaded_sand: "Sandhalt",
      soilMapUploaded_silt: "Silthalt",
      soilMapUploaded_organic: "Mullhalt",
      soilMapUploaded_samples: "Tagna markprover",
    },
    helpText: {
      soilMapUploaded: "pH-värdet i jorden. Ett pH under 7 betyder att marken är sur och över 7 att den är basisk.",
      soilMapUploaded_ph: "pH-värdet i jorden. Ett pH under 7 betyder att marken är sur och över 7 att den är basisk.",
      soilMapUploaded_p_al: 'Lättlösligt fosfor är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_k_al: 'Lättlösligt kalium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_ca_al: 'Lättlösligt kalcium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_mg_al: 'Lättlösligt magnesium är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_p_hcl: 'Förråd fosfor är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_k_hcl: 'Förråd kalium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_ca_hcl: 'Förråd kalcium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_cu_hcl: 'Förråd koppar är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_mg_hcl: 'Förråd magnesium är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_bor: 'Förråd bor är den mängd som grödan kan ta upp under flera växtsäsonger.',
      soilMapUploaded_fe_al: 'Lättlösligt järn är den mängd som grödan kan ta upp under växtsäsongen.',
      soilMapUploaded_al_al: 'Lättlösligt aluminium är den mängd som grödan kan ta upp under flera växtsäsongen.',
      soilMapUploaded_cd_hno3: 'Halten kadmium.',
      soilMapUploaded_k_mg_ratio: 'Kvoten mellan Kalium och Magnesium. Eftersom dessa två näringsämnen konkurrerar med varandra ska kvoten inte vara för stor.',
      soilMapUploaded_clay: "Lerhalten i jorden som procent.",
      soilMapUploaded_sand: "Sandhalt i jorden som procent.",
      soilMapUploaded_silt: "Silthalt i jorden som procent.",
      soilMapUploaded_organic: "Mullhalt i jorden som procent.",
      soilMapUploaded_samples: "Platser där markprover togs som del av karteringen.",
    },
    histogramLabels: {
      soilMapUploaded: { prefix: "pH " },
      soilMapUploaded_p_al: { postfix: " mg (per kg)" },
      soilMapUploaded_k_al: { postfix: " mg (per kg)" },
      soilMapUploaded_ca_al: { postfix: " mg (per kg)" },
      soilMapUploaded_mg_al: { postfix: " mg (per kg)" },
      soilMapUploaded_p_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_k_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_ca_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_cu_hcl: { postfix: " mg (per 100g)" },
      soilMapUploaded_mg_hcl: { postfix: " mg (per kg)" },
      soilMapUploaded_bor: { postfix: " mg (per 100g)" },
      soilMapUploaded_fe_al: { postfix: " mg (per 100g)" },
      soilMapUploaded_al_al: { postfix: " mg (per 100g)" },
      soilMapUploaded_cd_hno3: { postfix: " mg (per 100g)" },
      soilMapUploaded_k_mg_ratio: { noDecimals: 2 },
      soilMapUploaded_clay: { postfix: "%", noDecimals: 0 },
      soilMapUploaded_sand: { postfix: "%", noDecimals: 0 },
      soilMapUploaded_silt: { postfix: "%", noDecimals: 0 },
      soilMapUploaded_organic: { postfix: "%", noDecimals: 1 },
      soilMapUploaded_samples: null,
    },
    dbDataDocName: 'fieldsSoilMapsProcessed/rawdata_uploaded_soilmap_geojson',
    dbStatDocName: null,
    dbMarkersDocName: 'fieldsSoilMapsRaw/samples_in_file',
    geoJsonFieldKey: {
      soilMapUploaded: "ph",
      soilMapUploaded_ph: "ph",
      soilMapUploaded_p_al: 'p_al',
      soilMapUploaded_k_al: 'k_al',
      soilMapUploaded_ca_al: 'ca_al',
      soilMapUploaded_mg_al: 'mg_al',
      soilMapUploaded_p_hcl: 'p_hcl',
      soilMapUploaded_k_hcl: 'k_hcl',
      soilMapUploaded_ca_hcl: 'ca_hcl',
      soilMapUploaded_cu_hcl: 'cu_hcl',
      soilMapUploaded_mg_hcl: 'mg_hcl',
      soilMapUploaded_bor: 'bor',
      soilMapUploaded_fe_al: 'fe_al',
      soilMapUploaded_al_al: 'al_al',
      soilMapUploaded_cd_hno3: 'cd_hno3',
      soilMapUploaded_k_mg_ratio: 'k_mg_ratio',
      soilMapUploaded_clay: "clay",
      soilMapUploaded_sand: "sand",
      soilMapUploaded_silt: "silt",
      soilMapUploaded_organic: "organic",
      soilMapUploaded_samples: "samples",
    },
    dataType: {
      soilMapUploaded: "float",
      soilMapUploaded_ph: "float",
      soilMapUploaded_p_al: 'float',
      soilMapUploaded_k_al: 'float',
      soilMapUploaded_ca_al: 'float',
      soilMapUploaded_mg_al: 'float',
      soilMapUploaded_p_hcl: 'float',
      soilMapUploaded_k_hcl: 'float',
      soilMapUploaded_ca_hcl: 'float',
      soilMapUploaded_cu_hcl: 'float',
      soilMapUploaded_mg_hcl: 'float',
      soilMapUploaded_bor: 'float',
      soilMapUploaded_fe_al: 'float',
      soilMapUploaded_al_al: 'float',
      soilMapUploaded_cd_hno3: 'float',
      soilMapUploaded_k_mg_ratio: 'float',
      soilMapUploaded_clay: "float",
      soilMapUploaded_sand: "float",
      soilMapUploaded_silt: "float",
      soilMapUploaded_organic: "float",
      soilMapUploaded_samples: "integer",
    },
    colorScale: {
      soilMapUploaded: "RdYlGn",
      soilMapUploaded_ph: "RdYlGn",
      soilMapUploaded_p_al: 'RdYlGn',
      soilMapUploaded_k_al: 'RdYlGn',
      soilMapUploaded_ca_al: 'RdYlGn',
      soilMapUploaded_mg_al: 'RdYlGn',
      soilMapUploaded_p_hcl: 'RdYlGn',
      soilMapUploaded_k_hcl: 'RdYlGn',
      soilMapUploaded_ca_hcl: 'RdYlGn',
      soilMapUploaded_cu_hcl: 'RdYlGn',
      soilMapUploaded_mg_hcl: 'RdYlGn',
      soilMapUploaded_bor: 'RdYlGn',
      soilMapUploaded_fe_al: 'RdYlGn',
      soilMapUploaded_al_al: 'RdYlGn',
      soilMapUploaded_cd_hno3: 'RdYlGn',
      soilMapUploaded_k_mg_ratio: 'RdYlGn',
      soilMapUploaded_clay: "Purples",
      soilMapUploaded_sand: "Oranges",
      soilMapUploaded_silt: "Blues",
      soilMapUploaded_organic: "Greens",
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },

  soilMapSweden: {
    name: "soilMapSweden",
    source: "soilMapSweden",
    attribution: SGU_ATTRIBUTION,
    layerIdTypes: ['years'],
    aliases: [
      "soilMapSweden",
      "soilMapSweden_clay",
      "soilMapSweden_sand",
      "soilMapSweden_silt",
      "soilMapSweden_texture"
    ],
    icon: imgMenu,
    caption: "Annat",
    captions: {
      soilMapSweden: "Åkermarkskartan: Lerhalt",
      soilMapSweden_sand: "Åkermarkskartan: Sandhalt",
      soilMapSweden_silt: "Åkermarkskartan: Silthalt",
      soilMapSweden_texture: "Åkermarkskartan: Jordtextur",
    },
    shortCaptions: {
      soilMapSweden: "Lerhalt",
      soilMapSweden_sand: "Sandhalt",
      soilMapSweden_silt: "Silthalt",
      soilMapSweden_texture: "Jordtextur",
    },
    helpText: {
      soilMapSweden: "Lerhalten i jorden som procent. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
      soilMapSweden_sand: "Sandhalt i jorden som procent. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
      soilMapSweden_silt: "Silthalt i jorden som procent. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
      soilMapSweden_texture: "Jordtextur enligt USDA-skalan. Informationen är hämtad från den Digitala åkermarkskartan som tagits fram av SLU i samarbete med SGU. Kartan är ungefärlig och behöver inte stämma bra för dina fält.",
    },
    histogramLabels: {
      soilMapSweden: { postfix: "%", noDecimals: 0 },
      soilMapSweden_clay: { postfix: "%", noDecimals: 0 },
      soilMapSweden_sand: { postfix: "%", noDecimals: 0 },
      soilMapSweden_silt: { postfix: "%", noDecimals: 0 },
      soilMapSweden_texture: {
        first: "Lättare jord",
        last: "Tyngre jord",
      },
    },
    dbDataDocName: 'fieldsSoilMapsProcessed/rawdata_swedish_soilmap_geojson',
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      soilMapSweden: "clay",
      soilMapSweden_clay: "clay",
      soilMapSweden_sand: "sand",
      soilMapSweden_silt: "silt",
      soilMapSweden_texture: "texture",
    },
    dataType: {
      soilMapSweden: "float",
      soilMapSweden_clay: "float",
      soilMapSweden_sand: "float",
      soilMapSweden_silt: "float",
      soilMapSweden_texture: "float",
    },
    colorScale: {
      soilMapSweden: "Purples",
      soilMapSweden_clay: "Purples",
      soilMapSweden_sand: "Oranges",
      soilMapSweden_silt: "Blues",
      soilMapSweden_texture: "BrBG",
    },
    colorLimit: {},
    includedInPlans: ['all'],
  },  
};

export const FIELD_ANALYSIS_LAYERS = {
  vraMap: {
    name: "vraMap",
    source: "",
    layerIdTypes: [],
    aliases: [
      "vraMap",
      "vraMapTon",
      "vraMap_nitrogen",
      "vraMap_lime",
      "vraMap_phosphorus",
      "vraMap_potassium",
    ],
    icon: imgVraMap,
    caption: "Styrfil",
    captions: {
      vraMap: "",
      vraMapTon: "",
      vraMap_nitrogen: "",
      vraMap_lime: "",
      vraMap_phosphorus: "",
      vraMap_potassium: "",
    },
    shortCaptions: {
      vraMap: "Styrfil",
      vraMapTon: "Styrfil",
      vraMap_nitrogen: "Styrfil",
      vraMap_lime: "Styrfil",
      vraMap_phosphorus: "Styrfil",
      vraMap_potassium: "Styrfil",
    },
    helpText: {
      vraMap: "",
      vraMapTon: "",
      vraMap_nitrogen: "",
      vraMap_lime: "",
      vraMap_phosphorus: "",
      vraMap_potassium: "",
    },
    histogramLabels: {
      vraMap: { postfix: " kg/ha", noDecimals: 0  },
      vraMapTon: { postfix: " ton/ha", noDecimals: 0  },
      vraMap_nitrogen: { postfix: " kg N/ha", noDecimals: 0  },
      vraMap_lime: { postfix: " kg Ca/ha", noDecimals: 0  },
      vraMap_phosphorus: { postfix: " kg P/ha", noDecimals: 0  },
      vraMap_potassium: { postfix: " kg K/ha", noDecimals: 0  },
    },
    dbDataDocName: null,
    dbStatDocName: null,
    dbMarkersDocName: null,
    geoJsonFieldKey: {
      vraMap: "value",
      vraMapTon: "value",
      vraMap_nitrogen: "value",
      vraMap_lime: "value",
      vraMap_phosphorus: "value",
      vraMap_potassium: "value",
    },
    dataType: {
      vraMap: "float",
      vraMapTon: "float",
      vraMap_nitrogen: "float",
      vraMap_lime: "float",
      vraMap_phosphorus: "float",
      vraMap_potassium: "float",
    },
    colorScale: {
      vraMap: "spectralReversed",
      vraMapTon: "spectralReversed",
      vraMap_nitrogen: "spectralReversed",
      vraMap_lime: "spectralReversed",
      vraMap_phosphorus: "spectralReversed",
      vraMap_potassium: "spectralReversed",
    },
    colorLimit: {},
    includedInPlans: ['none'],
  },  
};

export const DATA_LAYERS = { ...FIELD_DATA_LAYERS, ...FIELD_ANALYSIS_LAYERS };

export const getCurrentDataLayer = (layer) => {
  let currentLayer = DATA_LAYERS && layer && Object.keys(DATA_LAYERS).find((x) => DATA_LAYERS[x].aliases && DATA_LAYERS[x].aliases.includes(layer));
  currentLayer = currentLayer && DATA_LAYERS[currentLayer] && DATA_LAYERS[currentLayer];

  return currentLayer
};