import React, { Component} from "react";

import { 
  Image,
  Segment,
  Header,
 } from "semantic-ui-react";

import emptyIllustration from "@/assets/illustrations/onboarding_guide_1.png";

const INITIAL_STATE = {};
  
class NoMobileAccessModal extends Component {
  
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }

    render ()  {

      const {
        userPlanType, 
        reason,
      } = this.props;

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
          }}
        >

          <Segment 
            basic 
            style={{
              display: "block",
              backgroundColor: "transparent",
              width: "80%",
            }}
          >
            <Image  
              centered 
              src={emptyIllustration}
            />

            <div 
              style={{  
                marginBottom: 0, 
                marginTop: "2em",
                marginLeft: "1em",
                marginRight: "1em",
                textAlign: "center",
                fontSize: "95%",
              }}
            >
              {userPlanType === "demo" && reason === "date" &&
                <React.Fragment>
                  <Header 
                    as="h3"
                    style={{ 
                      fontWeight: 500
                    }}
                  >
                    Din demoperiod är slut
                  </Header>

                  <p>
                    Inom kort kommer du att bli kontaktad av en säljare för uppföljning.
                    Om du redan idag vill teckna ett abonemang hos AgriOpt, besök vår hemsida {" "}
                    <a href="https://www.agriopt.se" target="_blank" rel="noopener noreferrer">www.agriopt.se</a>.
                  </p>
                </React.Fragment>
              }
              
              {userPlanType !== "demo" && reason === "date" &&
                <React.Fragment>
                  <Header 
                    as="h3"
                    style={{ 
                      fontWeight: 500
                    }}
                  >
                    Ditt abonemang har tagit slut
                  </Header>

                  <p>
                    Inom kort kommer du att bli kontaktad av en säljare för uppföljning.
                    Om du redan idag vill teckna ett abonemang hos AgriOpt, besök vår hemsida {" "}
                    <a href="https://www.agriopt.se" target="_blank" rel="noopener noreferrer">www.agriopt.se</a>.
                  </p>
                </React.Fragment>
              }    
            
            {reason === "notCreatedFarm" &&
                <React.Fragment>
                  <Header 
                    as="h3"
                    style={{ 
                      fontWeight: 500
                    }}
                  >
                    Du behöver skapa en gård
                  </Header>

                  <p>
                    Innan du kan logga in på Freja behöver du skapa din gård. 
                    Kolla din mail för mer information om hur du gör detta.
                  </p>
                </React.Fragment>
              }    

              <p>Med vänliga hälsningar, Teamet bakom Freja</p>
            </div>
          </Segment>
        </div>
      );
  };
}

export default NoMobileAccessModal;