import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { 
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import {
  firestoreConnect,
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import Page from "@/components/misc/Page";

import ActionDone from "@/components/modals/ActionDone";
import LoaderModal from "@/components/modals/LoaderModal";

import RemindersList from "@/components/reminders/RemindersList";

const INITIAL_STATE = {
  showActionDone: false,
  actionDoneInfo: null,
  error: null,
  showWorkingDimmer: false,
  workingDimmerText: '',
};

class RemindersPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  getDb = () => this.props.firestoreData;

  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  showActionDone = (actionDoneInfo) => {
    this.setState({ 
      showActionDone: true,
      actionDoneInfo: actionDoneInfo
   })
  }; 

  getWorkingDimmer = (text = 'Arbetar...') => (
    <Dimmer active inverted>
      <Loader style={{paddingBottom: '8em'}} />
      <p style={{color: "#000000"}}>{text}</p>
    </Dimmer>
  );  

  setVraMapToUsed = (reminderData) => {

    if (!reminderData || !reminderData.guide_id) {
      return null;
    };

    this.setState({
      showWorkingDimmer: true, 
      workingDimmerText: 'Klarmarkerar styrfiler...'
    });

    const db = this.getDb();

    let guideId = reminderData && reminderData.guide_id;
    let currentGuide = guideId && db && db.guides & db.guides[guideId];

    let allPromises = [];

    let guidePayload = {
      vra_maps_used: true,
      date_vra_maps_used: new Date(),
      date_complete: new Date(),
      date_latest_update: new Date(),
      complete: true,
    };

    let reminderPayload = {
      condition_active: false,
      urgent: false,
      complete: true,
    };    

    if (currentGuide) {
      allPromises.push(this.getUser().collection("guides").doc(guideId).update(guidePayload));
    };

    currentGuide && currentGuide.reminders && currentGuide.reminders.forEach((reminderId) => {
      if (db && db.reminders && db.reminders[reminderId]) {
        allPromises.push(this.getUser().collection("reminders").doc(reminderId).update(reminderPayload));
      };
    });

    if (reminderData && reminderData.id) { 
      allPromises.push(this.getUser().collection("reminders").doc(reminderData.id).update(reminderPayload));
    };

    Promise.all(allPromises)
      .then((results) => {
        this.setState({
          showWorkingDimmer: false, 
          workingDimmerText: null,
        });

        this.showActionDone({
          outcome: 'success', 
          text: 'Styrfiler klarmarkerade!',
        });        
      })
    .catch((error) => {
      this.setState({
        errorMessage: error.toString(),
        showWorkingDimmer: false, 
        workingDimmerText: null,
      });

      this.showActionDone({
        outcome: 'failure',  
        text: 'Tyvärr, något gick fel! Försök igen eller kontakta supporten.',
        errorMessage: error.toString(),
      })      
    });    
  }

  render() {
    const {
      showWorkingDimmer,
      workingDimmerText,
      actionDoneInfo,
      showActionDone,
    } = this.state;

    const db = this.getDb();
    const fileBucketRef = this.props.firebase.storage().ref();

    let hasLoadedData = isLoaded(db) && !isEmpty(db) && isLoaded(db.reminders);

    if (hasLoadedData) {
      return (
        <Page>
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <RemindersList 
              fileBucketRef={fileBucketRef}
              userId={this.props.userId}
              db={db}
              getUser={this.getUser}
              floating={true}
              caption={"Planering"}
              setVraMapToUsed={this.setVraMapToUsed}
              // toggleShowMultipleFieldsModal={this.toggleShowMultipleFieldsModal}          
            />
          </div>  

          {showWorkingDimmer && this.getWorkingDimmer(workingDimmerText)}

          {showActionDone && 
            <ActionDone
              isOpen={true}
              info={actionDoneInfo}
              hideModal={() => this.setState({ showActionDone: false })}
            />        
          }           
        </Page>     
      );
    } else {
      return (
        <LoaderModal caption="Laddar..." />
      );
    }
  }
}

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "guides" },
      ],
      storeAs: 'guides'
    },    
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "reminders" },
      ],
      storeAs: 'reminders'
    },
  ]),
  withFirestoreData,
  withRouter
)(RemindersPage);