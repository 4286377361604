import React, { Component } from 'react';

import { 
  Form, 
  Image, 
  TextArea,
  Input,
  Icon,
  Header,
  Segment,
} from 'semantic-ui-react';

import moment from 'moment';

import Fab from "@/components/misc/Fab";

import AddReminderButton from "@/components/reminders/AddReminderButton";
import ImageFileInputButton from "@/components/media/ImageFileInputButton";

const INITIAL_STATE = {
  imagesToUpload: [],
  imagesData: [],
  imagesToRemove: [],
};

class GetGeoTagNotes extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount = () => {
    const {
      newTag,
    } = this.props;

    if (newTag && newTag.photos && newTag.photos.length > 0) {
      this.getImageUrl(newTag.photos)
    }
  };

  getImageUrl = (filePaths) => { 
    const {
      fileBucketRef,
    } = this.props;

    let allPromises = [];

    filePaths && filePaths.forEach((filePath) => {
      allPromises.push(fileBucketRef.child(filePath).getDownloadURL())
    });

    Promise.all(allPromises).then((urls) => {
      this.setState({ 
        imagesData: [...this.state.imagesData, ...urls],
      });
    });
  };   

  onChange = (event) => {
    this.props.updateTag({
      [event.target.name]: event.target.value
    });
  };

  fileChange = e => {
    if (!e.target.files || e.target.files.length === 0) {
      this.setState({
        errorMessage: "Inget bild hittades."
      });

      return;
    };

    this.setState({ imagesToUpload: [...this.state.imagesToUpload, e.target.files[0]] });

    const reader = new FileReader();

    reader.addEventListener("load", () => {
      this.setState({ 
        imagesData: [...this.state.imagesData, reader.result],
      });
    }, false)

    reader.readAsDataURL(e.target.files[0])    
  };

  toggleFullScreenImage = (url) => {
    this.props.toggleShowMultipleFieldsModal && this.props.toggleShowMultipleFieldsModal();

    this.setState({
      fullscreenImageUrl: url ? url : '',
    });
  };

  getNewImageSegment = () => {
    const {
      guideMode,
    } = this.props;

    const {
      imagesData,
      imagesToUpload,
      imagesToRemove,
    } = this.state;
  
    return imagesData && imagesData.map((image, idx) => (
      <Segment
        key={"imageSegment_" + idx}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        <div
          style={{
            marginBottom: 0,
            marginLeft: 10,
            width: "90%",
            whiteSpace: "pre-line",
          }}
        >
          <Image src={image} />
        </div>

        <div
          style={{
            width: "1em",
            fontSize: "80%"
          }}
        >
          <Icon 
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              guideMode === "addObservation" && this.setState({
                  imagesData: imagesData.filter((x, idx2) => idx !== idx2),
                  imagesToUpload: imagesToUpload.filter((x, idx2) => idx !== idx2),
                });

              guideMode === "editObservation" && this.setState({
                imagesData: imagesData.filter((x, idx2) => idx !== idx2),
                imagesToRemove: [...imagesToRemove, image],
              });
            }}
            name="close" 
          />
        </div>
      </Segment>
    ));
  };

  getNewReminderSegment = () => {
    const {
      newTag,
    } = this.props;

    let reminderDate = newTag && newTag.reminder && newTag.reminder.condition;
    reminderDate = reminderDate && reminderDate.first_date && moment(reminderDate.first_date).format("YYYY-MM-DD");
  
    return (
      <Segment
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        Freja kommer att påminna dig om att återbesöka denna observation den {reminderDate && reminderDate}.

        <div
          style={{
            width: "1em",
            fontSize: "80%"
          }}
        >
          <Icon 
            style={{
              cursor: "pointer",
            }}
            name="close"
            onClick={() => this.props.updateTag({ reminder: null })}
          />
        </div>
      </Segment>
    );
  };    

  render () {
    const {
      marginTop,
      newTag,
    } = this.props;

    const {
      imagesData,
      imagesToUpload,
      imagesToRemove,
    } = this.state;

    return (
      <div 
        style={{ 
          padding: 0,
          margin: 0,
          marginTop: marginTop ? marginTop : null,
        }} 
      >
        {newTag && newTag.mainType && newTag.mainType === "custom" &&
          <React.Fragment>
            <Header
              as="h3"
              style={{
                fontWeight: 500,
              }}
            >
              Rubrik (frivilligt)
            </Header>

            <Form  
              style={{
                width: '100%',
              }}
            >
              <Input
                fluid
                name='header'
                placeholder='Skriv en valfri rubrik...'
                onChange={e => this.onChange(e)}
                value={newTag.header ? newTag.header : ''}
              />
            </Form>
          </React.Fragment>
        }

        <Header
          as="h3"
          style={{
            fontWeight: 500,
          }}
        >
          Anteckning (frivilligt)
        </Header>

        <Form  
          style={{
            width: '100%',
          }}
        >
          <TextArea
            style={{
              fontSize: '12pt',
            }}
            rows={4}
            name='note'
            placeholder='Skriv en valfri anteckning...'
            onChange={e => this.onChange(e)}
            value={newTag.note ? newTag.note : ''}
          />
        </Form>        

        <Header
          as="h3"
          style={{
            fontWeight: 500,
          }}          
        >
          Foto (frivilligt)
        </Header>

        <p
          style={{
            marginBottom: "1em",
          }}
        >
          Du kan lägga till ett eller flera foton till din observation med hjälp av knappen nedanför.
        </p>

        {imagesData && this.getNewImageSegment()}

        <ImageFileInputButton 
          bigButton
          onChange={this.fileChange}
        />
        
        <Header
          as="h3"
          style={{
            fontWeight: 500,
          }}          
        >
          Påminnelse (frivilligt)
        </Header>

        <p
          style={{
            marginBottom: "1em",
          }}
        >
          Du kan lägga till en påminnelse om att återbesöka denna platsen för att samla in en ny observation.
        </p>

        {newTag && newTag.reminder && this.getNewReminderSegment()}

        {newTag && !newTag.reminder &&
          <AddReminderButton 
            bigButton
            type='observationReminder'
            reminderType='checkObservation'
            updateTag={this.props.updateTag}
          />
        }

        <Fab 
          style={{
            zIndex: 1000,
          }}        
          positive
          position="right"
          icon="check"
          onClick={() => this.props.onSaveNewTag(imagesToUpload, imagesToRemove)}
        />       
      </div>
    )
  };
};

export default GetGeoTagNotes;