import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { VIEWS } from '@/constants/views.js'

import {
  firestoreConnect,
} from 'react-redux-firebase';

import { 
  Menu,
  Image,
} from 'semantic-ui-react';

import AddMenu from '@/components/menus/MenuAdd';

const INITIAL_STATE = {
  showAddMenuModal: false,
};

const NotificationDot = () => (
  <div 
    style={{ 
      position: "absolute", 
      top: 5, 
      right: 15, 
      width: "1em", 
      height: "1em", 
      borderRadius: "50%", 
      backgroundColor: "#C36126",
      zIndex: 9999,
    }} 
  />
);

class MenuPrimary extends Component {

  state = INITIAL_STATE;

  getDb = () => this.props.firestoreData;
  
  getUser = () => this.props.firestore.collection('users').doc(this.props.userId);

  onChangeView = (newView) => {
    if (newView === "collect") {
      this.toggleShowAddMenuModal();
    } else {
      this.props.history.replace("/" + newView);
    }
  };

  toggleShowAddMenuModal = () => {
    const currentValue = this.state.showAddMenuModal;
    this.setState({showAddMenuModal: !currentValue})
  };

  onClickAddMenuItem = (address) => {
    this.props.history.replace(address)
    this.toggleShowAddMenuModal();
  };

  render () {
    const {
      match: {
        params:{
          view,
          fieldId,
        }
      },
    } = this.props;

    const {
      showAddMenuModal,
    } = this.state;
    
    const db = this.getDb();

    let hasNewNotifications = db && db.notifications && 
      Object.keys(db.notifications).find((x) => !db.notifications[x].seen || db.notifications[x].seen === false);
    
    let hasNewReminders = db && db.reminders && 
      Object.keys(db.reminders).find((x) => !db.reminders[x].complete || db.reminders[x].complete === false);

    let showRedDot = {
      home: false,
      notifications: hasNewNotifications,
      collect: false,
      reminders: hasNewReminders,
      more: false,
    }

    let currentView = view ? view : 'home';

    if (view === "layers" && fieldId && fieldId !== "none") {
      return null
    };

    if (view === "geotags" && fieldId && fieldId !== "none") {
      return null
    };

    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100vw",
          zIndex: 8000,
        }}
      >

        <AddMenu
          showAddMenuModal={showAddMenuModal}
          toggleShowAddMenuModal={this.toggleShowAddMenuModal}
        />

        <div
          style={{
            backgroundColor: showAddMenuModal ? "#F8EED0" : "#363e40",
            borderRadius: 40,
            color:  showAddMenuModal ? "rgb(0, 0, 0, 0.85)" : "white",
            bottom: -8,
            position: 'fixed',
            width: 80,
            height: 80,
            left: "50vw",
            marginLeft: -40,
            boxShadow: "none",
            fontSize: "10px",
            zIndex: 99,
          }}
          onClick={() => this.toggleShowAddMenuModal()}
        >
          <Image
            centered
            src={VIEWS.collect.icon}
            style={{
              display: "block",
              marginTop: 15,
              width: 35,
              filter: showAddMenuModal ?
                "brightness(0) invert(35%)" :
                "brightness(0) invert(85%)",
            }}
          />
          <p style={{ textAlign: "center" }}>Samla</p>
        </div>

        <Menu
          pointing
          fluid
          secondary
          icon='labeled'
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            margin: 0,
            borderRadius: 0,
            border: "0px !important",
            textAlign: "center",
            backgroundColor: showAddMenuModal ? "transparent" : "#363e40",
            width: "100vw",
          }}
          widths={5}
        >
          {!showAddMenuModal && VIEWS && Object.keys(VIEWS).map(x => {

            let isSelected = VIEWS[x].name === currentView;
            isSelected = VIEWS[x].aliases.includes(currentView) ? true : isSelected;
            isSelected = showAddMenuModal ? false : isSelected;

            if (typeof(VIEWS[x].hidden) === "boolean" && VIEWS[x].hidden === true) {
              return (
                <Menu.Item
                  as="div"
                  key={x}
                  active={false}
                />
              )
            }

            return (
              <Menu.Item
                as="div"
                style={{
                  color: isSelected ? "rgb(0, 0, 0, 0.85)" : "white",
                  background: isSelected ? "#F8EED0" : "transparent",
                  borderRadius: 0,
                  borderTop: isSelected ? "4px solid rgba(234, 204, 116, 1.0)" : null,
                  fontSize: "70%",
                  fontWeight: 500,
                }}
                key={x}
                active={isSelected}
                onClick={() => this.onChangeView(VIEWS[x].path)}
              >
                <Image
                  centered
                  src={VIEWS[x].icon}
                  style={{
                    width: "2.25em",
                    filter: isSelected ?
                      "brightness(0) invert(35%)" :
                      "brightness(0) invert(85%)",
                    marginBottom: 8,
                  }}
                />
                
                {showRedDot[VIEWS[x].name] && <NotificationDot />}

                {VIEWS[x].caption}
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
    );
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'profile'
    },           
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "notifications" },
      ],
      storeAs: 'notifications'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "reminders" },
      ],
      storeAs: 'reminders'
    },      
  ]),
  withFirestoreData,
  withRouter
)(MenuPrimary);
