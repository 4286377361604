export const MAPZEN_ATTRIBUTION = '<a href="https://www.mapzen.com/rights">Mapzen</a>';
export const SENTINEL_ATTRIBUTION = 'Contains modified Copernicus Sentinel data 2021 processed by Sentinel Hub';
export const SGU_ATTRIBUTION = 'Digiala åkermarkskartan från SGU är licenserad under CC-BY 2.5.';

export const AREAL_TILE_URL = "https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?app_id=6Cy9N8yG1SZYlO61DQC7&app_code=6yLDAiRRtx2-9-7Wkn4M4g&lg=eng";

export const STREET_TILE_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

export const COLOR_SCALE_TYPES = [
  {
    key: "e",
    value: "e",
    text: "Lika stora intervall",
    short: "Lika stora intervall"
  },
  {
    key: "q",
    value: "q",
    text: "Lika många punkter per grupp",
    short: "Lika många punkter"
  },
  {
    key: "k",
    value: "k",
    text: "Gruppera liknande punkter",
    short: "Gruppera liknande"
  }
];

export const YIELD_OPTIONS = [
  {
    key: 'yieldStatistics_max',
    value: 'yieldStatistics_max',
    text: 'Maximal skörd',
  },
  {
    key: 'yieldStatistics',
    value: 'yieldStatistics',
    text: 'Snittskörd',
  },
  {
    key: 'yieldStatistics_goodYear',
    value: 'yieldStatistics_goodYear',
    text: 'Skörd ett bättre år',
  },
  {
    key: 'yieldStatistics_badYear',
    value: 'yieldStatistics_badYear',
    text: 'Skörd ett sämre år',
  },  
];
