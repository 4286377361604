import React, { Component } from 'react';

import { Icon } from 'semantic-ui-react';
class FieldDataDateMenu extends Component {

  backButton = (enabled, onChangeDate) => {
    if (enabled) {
      return (
        <div
          style={{
            background: 'transparent',
            color: '#e0ba8c',
            top: 'auto',
            bottom: '12%',
            right: 'auto',
            left: 0,
            position: 'absolute',
            boxShadow: 'none',
            zIndex: 10000,
          }}
          onClick={() => onChangeDate('decrement')}
        >
          <Icon name="chevron left" size="big" />
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  forwardButton = (enabled, onChangeDate) => {
    if (enabled) {
      return (
        <div
          style={{
            background: 'transparent',
            color: '#e0ba8c',
            top: 'auto',
            bottom: '12%',
            right: '0',
            left: 'auto',
            position: 'absolute',
            boxShadow: 'none',
            zIndex: 10000,
          }}
          onClick={(e) => onChangeDate('increment')}
        >
          <Icon name="chevron right" size="big" />
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  render () {
    const {
      availableDates,
      currentDate,
    } = this.props;

    let currentDateIndex = availableDates && availableDates.indexOf(currentDate);

    let showNext = currentDateIndex < availableDates.length - 1;
    let showPrevious = currentDateIndex > 0;

    return (
      <div>
        {this.backButton(showPrevious, this.props.onChangeDate)}
        {this.forwardButton(showNext, this.props.onChangeDate)}
      </div>
    )
  }
};

export default FieldDataDateMenu;
