
import home1 from "@/assets/illustrations/onboarding_guide_1.png";
import home2 from "@/assets/illustrations/onboarding_guide_2.png";
import home3 from "@/assets/illustrations/onboarding_guide_3.png";
import home4 from "@/assets/illustrations/onboarding_guide_4.png";
import home5 from "@/assets/illustrations/question.png";

export const TUTORIALS = [
  {
    name: "MobileHomeFirstLaunchV1",
    cards: [
      {
        id: 'home1',
        header: 'Enklare precisionsodling', 
        image: home1, 
        text: [
          'Freja hjälper dig att odla effektivare och mer hållbart på ett enkelt sätt.',
          'Mobilappen gör det enkelt att komma åt det viktigaste i din digitala hjälpreda när du inte sitter vid datorn.'
        ],
        useChild: false,
      },
      {
        id: 'home2',
        header: 'Håll koll på gården',
        image: home2, 
        text: [
          'Varje morgon när du vaknar får du en snabb koll på hur grödan växer på dina skiften, säsongens väder, varningar om skadeangrepp och viktiga påminnelser.',
        ],
        useChild: false,
      },
      {
        id: 'home3',
        header: 'Samla och styra',
        image: home3, 
        text: [
          'I fält använder du appen för att snabbt och enkelt samla in observationer om bestockning, tillväxt och skador.',
          'Du kan också skapa styrfiler och kontrollera att de har laddats in rätt i terminalen.',
        ],
        useChild: false,
      },
      {
        id: 'home4',
        header: 'Aktivera notiser', 
        image: home4, 
        text: [
          'För att Freja ska fungera bra på mobilen så behöver vi din tillåtelse att skicka notiser. Just nu fungerar dessa tyvärr bara när du lägger till appen via Google Chrome på en mobil med Android.',
        ],
        useChild: true,
      },
      {
        id: 'home5',
        header: 'Hitta mer hjälp', 
        image: home5, 
        text: [
          'Har du frågor eller behöver mer hjälp så hittar du hjälpcentret och supporten under vyn "Meny".',
        ],
        useChild: false,
      },     
    ]
  },
];
