import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";

import {
  withFirebase,
  withFirestore as withFirestoreActions,
  firestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";

import moment from 'moment';

import { 
  APP_VERSION 
} from "@/constants/firebase";

import {
  APP_BETA_OPEN,
} from "@/constants/settings";

import MenuPrimary from '@/components/menus/MenuPrimary';
import LoadingScreen from '@/components/branding/LoadingScreen';
import LoginPage from '@/containers/LoginPage';

import GuidesPage from "@/containers/GuidesPage";
import GuidesNotFoundPage from "@/containers/GuidesNotFoundPage";
import HomePage from '@/containers/HomePage';

import NotificationPage from '@/containers/NotificationPage';
import RemindersPage from '@/containers/RemindersPage';
import MessagingPage from "@/containers/MessagingPage";

import AddPage from "@/containers/AddPage";
import MenuPage from '@/containers/MenuPage';
import ContactSupportPage from '@/containers/ContactSupportPage';
import FieldDataPage from '@/containers/FieldDataPage';
import GeoTagPage from '@/containers/GeoTagPage';
import LegalPage from '@/containers/LegalPage';
import SettingsPage from "@/containers/SettingsPage";

import NoAccessModal from "@/components/modals/NoAccessModal";
import NoAccessToBetaModal from "@/components/modals/NoAccessToBetaModal";

import "./App.css";

Math.median = (arr, noDecimals=2) => {
  const sortedArray = arr && [...arr].sort();
  const medianElement = sortedArray && sortedArray[Math.floor(arr.length / 2)];
  return medianElement && +medianElement.toFixed(noDecimals);
};

Math.mean = (arr, noDecimals=2) => {
  return arr && +arr.reduce((curr, acc) => (typeof(curr) === "number" ? curr : 0.0) + acc / arr.length, 0).toFixed(noDecimals);
};

Math.sum = (arr) => {
  return arr && +arr.reduce((curr, acc) => curr + acc, 0);
};

Math.var = (arr) => {
  let mean = arr && +arr.reduce((curr, acc) => curr + acc / arr.length, 0.0);
  return arr && +arr.reduce((curr, acc) => curr + (acc - mean) * (acc - mean) / (arr.length - 1), 0.0);
};

const INITIAL_STATE = {
  overrideUserId: "",
  messagingToken: null,
  cacheFieldData: {
    geoJsonData: {},
    statJson: {},
  },
  isAdminUser: null,
  advisorClientIds: [],
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidUpdate = () => {
    if (typeof(this.state.isAdminUser) !== "boolean") {
      this.props.firebase.auth() &&
      this.props.firebase.auth().currentUser &&
      this.props.firebase.auth().currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (!!idTokenResult.claims.adminUser) {
              this.setState({ isAdminUser: true });
          } else {
              this.setState({ isAdminUser: false });
          };

          if (idTokenResult.claims.clientIds) {
            this.setState({ advisorClientIds: idTokenResult.claims.clientIds });
          };
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

  updateCacheFieldData = (geoJsonData, statJson, identifier) => {
    let currentValue = this.state.cacheFieldData;

    geoJsonData && this.setState({
      cacheFieldData: {
        ...currentValue,
        geoJsonData: {
          ...currentValue.geoJsonData,
          [identifier]: geoJsonData,
        }
      },
    });

    statJson && this.setState({
      cacheFieldData: {
        ...currentValue,
        statJson: {
          ...currentValue.statJson,
          [identifier]: statJson,
        }
      },
    });
  };

  onClickLogout = () => {
    this.props.firebase.logout();
  };

  render() {
    const { 
      firebaseData, 
      firestoreData,
    } = this.props;

    const {
      overrideUserId,
      cacheFieldData,
      advisorClientIds,
    } = this.state;

    if (APP_VERSION === "beta" && !APP_BETA_OPEN) {
      return <NoAccessToBetaModal />
    };

    if (!isLoaded(firebaseData.auth) || !isLoaded(firestoreData.data)) {
      return <LoadingScreen />
    };

    if (isLoaded(firebaseData.auth) && isLoaded(firestoreData.data) && isEmpty(firebaseData.auth)) {
      return <LoginPage />
    };

    const db = this.props.firestoreData && this.props.firestoreData.data;
    const userId = overrideUserId ? overrideUserId : firebaseData.auth.uid;
    
    const trueUserId = firebaseData.auth.uid;
    const isImpersonatingUser = typeof(overrideUserId) === "string"; 

    const dbProfile = db && db.userProfileApp;
    const userPlanType = dbProfile && dbProfile.plan && dbProfile.plan.plan_type;
    const validToDate = dbProfile && dbProfile.plan && dbProfile.plan.plan_valid_to && dbProfile.plan.plan_valid_to.toDate();
    const validTo = validToDate && moment(validToDate);

    if (validTo && validTo.isValid() && validTo.isBefore(moment())) {
      return <NoAccessModal reason="date" userPlanType={userPlanType} />
    };

    return (
      <Router>
        <Route 
          path="/:view?/:fieldId?/:layer?"
          render={(props) => (        
            <MenuPrimary
              {...props}
              userId={userId}
            />
          )}
        />

        <Route
          exact
          path="/"
          render={(props) => (
            <HomePage
              {...props}
              userId={userId}
              isImpersonatingUser={isImpersonatingUser}
            />
          )}
        />

        <Route
          path="/home"
          render={(props) => (
            <HomePage
              {...props}
              userId={userId}
              isImpersonatingUser={isImpersonatingUser}
            />
          )}
        />    

        <Route
          path="/add/:addType?/:addId?/:addStep?"
          render={(props) => (
            <AddPage
              {...props}
              userId={userId}
              trueUserId={trueUserId}
            />
          )}
        />    

        <Route
          path="/notifications/:notificationId?"
          render={(props) => (
            <NotificationPage
              {...props}
              userId={userId}
            />
          )}
        /> 

        <Route
          path="/messaging"
          render={(props) => (
            <MessagingPage
              {...props}
              userId={userId}
              trueUserId={trueUserId}
            />
          )}
        /> 

        <Route
          path="/legal/:subMenu?"
          render={(props) => (
            <LegalPage
              {...props}
              userId={userId}
            />
          )}
        />                   

        <Route
          path="/reminders"
          render={(props) => (
            <RemindersPage
              {...props}
              userId={userId}
            />
          )}
        />            

        <Route
          path="/more/:subMenu?"
          render={(props) => (
            <MenuPage
              {...props}
              userId={userId}
              trueUserId={trueUserId}
              onClickLogout={this.onClickLogout}
              advisorClientIds={advisorClientIds}
              openClientFarm={(clientId) => this.setState({ overrideUserId: clientId })}
              isImpersonatingUser={isImpersonatingUser}
            />
          )}
        />                

        <Route
          path="/support"
          render={(props) => (
            <ContactSupportPage
              {...props}
              userId={userId}
            />
          )}
        />  

        <Route
          path="/settings"
          render={(props) => (
            <SettingsPage
              {...props}
              userId={userId}
            />
          )}
        />  
        
        <Route
          path="/geotags/:fieldId?/:mode?/:tagId?/:flag?"
          render={(props) => (
            <GeoTagPage
              {...props}
              userId={userId}
              trueUserId={trueUserId}
            />
          )}
        />

        <Route
          path="/layers/:fieldId?/:layer?/:layerId?/:layerSubId?"
          render={(props) => (
            <FieldDataPage
              {...props}
              userId={userId}
              dataType="layers"
              cacheFieldData={cacheFieldData}
              updateCacheFieldData={this.updateCacheFieldData}
            />
          )}
        />

        <Route
          path="/layers/:fieldId?/:layer?/:layerId?/:layerSubId?"
          render={(props) => (
            <FieldDataPage
              {...props}
              userId={userId}
              dataType="layers"
              cacheFieldData={cacheFieldData}
              updateCacheFieldData={this.updateCacheFieldData}
            />
          )}
        />

        <Route
          path={"/guides/:layer?/:guideId?/:layerId?"}   
          render={props => (
            <GuidesPage 
              {...props}
              userId={userId}              
            />
          )}
        />

        <Route
          path={"/guidesUploadSoilMaps/:layer?/:guideId?/:layerId?"}   
          render={props => <GuidesNotFoundPage type="guidesUploadSoilMaps" />}
        />

        <Route
          path={"/guidesUploadYieldMaps/:layer?/:guideId?/:layerId?"}   
          render={props =>  <GuidesNotFoundPage type="guidesUploadYieldMaps" />}
        />
      </Router>
    );
  }
};

const withFirebaseData = connect(store => {
  return {
    firebaseData: store.firebase
  };
});

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore
  };
});

export default compose(
  withFirebaseData,
  withFirestoreData,
  firestoreConnect(props => [
    { 
      collection: 'admins', 
      storeAs: 'adminsApp' 
    },    
    { 
      collection: 'users', 
      storeAs: 'allUsersApp' 
    },
    { 
      collection: 'users', 
      doc: `${props.firebaseData.auth.uid}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fieldsApp'
    },  
    { 
      collection: 'users', 
      doc: `${props.firebaseData.auth.uid}`, 
      subcollections: [
        { collection: "profile" },
      ],
      storeAs: 'userProfileApp'
    },
    { 
      collection: 'users', 
      doc: `${props.firebaseData.auth.uid}`, 
      subcollections: [
        { collection: "settings" },
      ],
      storeAs: 'userSettingsApp'
    },        
  ]),
  withFirebase,
  withFirestoreActions,
)(App);
