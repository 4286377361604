import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import {
  firestoreConnect,
} from 'react-redux-firebase';

import { 
  Icon, 
  Image,
  Header, 
  Segment,
  Button,
  Modal,
} from 'semantic-ui-react';

import MenuTop from "@/components/menus/MenuTop";
import TutorialModal from "@/components/modals/TutorialModal";

import iconLayers from "@/assets/icons/menuPrimary/raw-data.svg";
import iconMessaging from "@/assets/icons/menuPrimary/advisor-mode.svg";
import iconGeotag from "@/assets/icons/dataTypes/geotag.svg";


const INITIAL_STATE = {
  handlingMessagingToken: null,
  messagingToken: null,
  confirmLogoutModal: false,
};

const MenuItem = ({id, title, description, onClick, href, icon, img, target, rel, active=false}) => (
  <Segment
    basic
    style={{
      width: "100%",
      padding: "1.25em",
      marginTop: 5,
      marginBottom: 5,
      color: active ? "white" : false,
      backgroundColor: active ? "#e0ba8c" : "white",
    }}
    key={`div_${id}`}
    onClick={() => onClick && onClick(id)}
    href={href && href}
    target={target && target}
    rel={rel && rel}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >

      <div 
        style={{ 
          width: "10%",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {img && 
          <Image 
            style={{ 
              height: "1.75em",
              marginTop: "auto",
              marginBottom: "auto",
            }} 
            src={img} 
          />
        }
        
        {icon && 
          <Icon 
            style={{ 
              fontSize: "1.75em",
              marginTop: 5,
              color: active ? "white" : "grey",
            }}           
            name={icon} 
          />
        }
      </div>

      <div 
        style={{ 
          width: "80%", 
          marginLeft: "1em",  
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <Header
            as='h4'
            key={`title_${id}`}
            style={{
              marginBottom: '0em',
              fontWeight: 500,
              color: active ? "white" : false,
            }}
        >
            {title}
        </Header>

        <Header
            as='h5'
            style={{
              color: active ? "white" : "grey",
              marginTop: '0em', 
              marginBottom: '0em', 
              fontWeight: 'normal'
            }}
        >
            {description}
        </Header>       
      </div>

      <div 
        style={{ 
          width: "10%",
          textAlign: "right",
          marginTop: "auto",
          marginBottom: "auto",
          }}
        >
          {!active &&
            <Icon
              name="chevron right"
              color='grey'
            />
          }
      </div>
    </div>
  </Segment>
)


class MenuPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection('users').doc(this.props.userId);

  getMessagingToken = () => {
    this.props.firebase &&
    this.props.firebase.messaging &&
    this.props.firebase.messaging().requestPermission()
      .then(async res => {
        this.setState({ handlingMessagingToken: "running" })
        
        let newValue = await this.props.firebase.messaging().getToken();
        console.log(newValue)

        this.getUser().collection("settings").doc("tokens").set({
          mobile_messaging: this.props.firestore.FieldValue.arrayUnion(newValue),
          latest_update: new Date(),
        }, { merge: true })

        this.setState({ handlingMessagingToken: "done" })
      })
      .catch((err) => {
        this.setState({ handlingMessagingToken: "error" })
        console.log("Unable to get permission to notify.", err);
      });

    navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
  };  

  onClickSubMenuItem = (newPage) => {
    this.props.history.push(newPage);
  };

  returnToMenu = () => {
    this.props.history.goBack();
  };

  onClickLogout = () => {
    this.props.onClickLogout();
  };
  
  toggleConfirmLogoutModal = () => {
    let currentValue = this.state.confirmLogoutModal;
    this.setState({confirmLogoutModal: !currentValue});
  };
  
  render() {
    const {
      userId,
      trueUserId,
      advisorClientIds,
      isImpersonatingUser,
    } = this.props;

    const {
      handlingMessagingToken,
      confirmLogoutModal,
    } = this.state;

    const db = this.getDb();
    let farmName = db && db.profile && db.profile.farm && db.profile.farm.name;

    let buttonIcon = null;
    buttonIcon = handlingMessagingToken === "running" ? "spinner" : buttonIcon;
    buttonIcon = handlingMessagingToken === "error" ? "close" : buttonIcon;
    buttonIcon = handlingMessagingToken === "done" ? "check" : buttonIcon;

    let buttonColor = "#e0ba8c";
    buttonColor = handlingMessagingToken === "error" ? "#C36126" : buttonColor;
    buttonColor = handlingMessagingToken === "done" ? "#4C8440" : buttonColor;

    let clientUserList = advisorClientIds && advisorClientIds;

    return (
      <Page>
        <Modal
          open={confirmLogoutModal}
          dimmer="blurring"
          style={{
            position: 'absolute',
            top: '35%',
            bottom: '35%',
            left: '10%',
            right: '10%',
            height: 'auto',
            width: 'auto',
            backgroundColor: '#ffffff',
            zIndex: 9,
            color: "black",
          }}
        >
          <Modal.Header style={{ color: "black" }}>Vill du verkligen logga ut?</Modal.Header>
          <Modal.Content style={{ height: "100%" }}>
            <div
              style={{
                marginTop: "2em",
                color: "black",
                whiteSpace: "pre-line",
              }}
            >
              
            </div>

            <div
              style={{
                position: "absolute",
                bottom: 25,
                marginLeft: 30,
                marginRight: 30,
                width: "calc(100% - 60px)",
                zIndex: 99,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#868D8E",
                    color: "white",
                  }}
                  onClick={() => this.toggleConfirmLogoutModal()}
                  size="small"
                >
                  Stäng
                </Button>

                <Button
                  style={{
                    backgroundColor: "#6B9D9F",
                    color: "white",
                  }}
                  onClick={() => this.onClickLogout()}
                  size="small"
                >
                  Logga ut
                </Button>                
              </div>
            </div>
          </Modal.Content>
        </Modal>

        <div
          style={{
            width: "calc(100vw - 20)",
            marginTop: 70,
            marginLeft: 10,
            marginRight: 10,              
          }}
        >
            
          <MenuTop caption="Meny">
            <p
              style={{
                color: "#6B9D9F",
                fontSize: "95%",
                cursor: "pointer",
                fontWeight: 600,
                marginTop: "auto",
                marginBottom: "auto",
              }}
              onClick={() => this.props.history.push("/legal")}
            >
              Juridisk information
            </p>
          </MenuTop>

          <Header
            as='h3'
            style={{
              fontWeight: 500,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Fler funktioner
          </Header>

          <MenuItem 
            id="layers"
            img={iconLayers}
            title="Utforska"
            description="Titta på dina kartor för varje skifte."
            onClick={this.onClickSubMenuItem}
          />

          <MenuItem 
            id="geotags"
            img={iconGeotag}
            title="Observationer"
            description="Titta på observationer som du samlat in."
            onClick={this.onClickSubMenuItem}
          />          

          <MenuItem 
            id="messaging"
            img={iconMessaging}
            title="Meddelanden"
            description="Kommunicera enkelt med din rådgivare."
            onClick={this.onClickSubMenuItem}
          />

          <Header
            as='h3'
            style={{
              fontWeight: 500,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Hjälp
          </Header>
          
          <TutorialModal
            hidden={isImpersonatingUser}
            db={db} 
            getUser={this.getUser} 
            tutorialName="MobileHomeFirstLaunchV1"        
            trigger={
              <MenuItem 
                id="tutorial"
                icon="caret square right outline"
                title="Visa startguiden"
                description="Få en rundtur av Freja"
              />
            }
          >
            <div
              style={{
                width: "100%",          
              }}
            >
              <Button
                icon={buttonIcon !== null}
                labelPosition={buttonIcon !== null ? "left" : null}
                style={{
                  backgroundColor: buttonColor,
                  color: "white",
                  width: "50%", 
                  marginLeft: "25%",
                  marginRight: "25%",  
                  marginTop: "2em",                 
                }}
                onClick={() => this.getMessagingToken()}  
                loading={handlingMessagingToken === "running"}        
              >
                Tillåt notiser
              </Button>
            </div>    
          </TutorialModal>            

          {/* <MenuItem 
            id="legal/user-terms"
            title="Hjälpcenter"
            icon="question circle outline"
            description="Få hjälp med vanliga problem."
            // onClick={this.onClickSubMenuItem}
          /> */}

          {/* <MenuItem 
            id="legal/privacy"
            title="Facebook-grupp"
            icon="facebook official"
            description="Freja-användare tipsar varandra."
            href="https://www.facebook.com/groups/361010158354046"
            target="_blank" 
            rel="noopener noreferrer"
          /> */}

          <MenuItem 
            id="support"
            icon="talk"
            title="Kontakta support"
            description="Få personlig hjälp från vårt team."
            onClick={this.onClickSubMenuItem}
          />

          {clientUserList && clientUserList.length > 0 && 
            <React.Fragment>
              <Header
                as='h3'
                style={{
                  fontWeight: 500,
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                Kunders gårdar
              </Header>

              <MenuItem 
                id="ownFarm"
                title="Egen gård"
                description='Öppna din egen gård igen.'
                onClick={() => {
                  this.props.openClientFarm('');
                  this.props.history.replace("/");
                }}
                active={userId === trueUserId}
                icon="user"
              /> 

              {clientUserList.map((clientId) => 
                <MenuItem 
                  id={clientId}
                  title={db && db['profile_' + clientId] && db['profile_' + clientId].farm && db['profile_' + clientId].farm.name}
                  description={'Öppna kundens gård.'}
                  onClick={() => {
                    this.props.openClientFarm(clientId);
                    this.props.history.replace("/");
                  }}
                  active={userId === clientId}
                  icon="user"
                />                   
              )}
             
            </React.Fragment>
          }

          <Header
            as='h3'
            style={{
              fontWeight: 500,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Övrigt
          </Header>

          <MenuItem 
            id="settings"
            title="Inställningar"
            description={'Ändra inställningar om kartor och notiser.'}
            onClick={this.onClickSubMenuItem}
            icon="setting"
          />    

          <MenuItem 
            id="logout"
            title="Logga ut"
            description={'Du är inloggad som: ' + farmName + '.'}
            onClick={this.toggleConfirmLogoutModal}
            icon="sign-out"
          />                
      </div>
    </Page>
    )
  }
};

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => {
    
    let output = [
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "profile" },
        ],
        storeAs: 'profile'
      },
      { 
        collection: 'users', 
        doc: `${props.userId}`, 
        subcollections: [
          { collection: "settings" },
        ],
        storeAs: 'settings'
      },
    ];
  
    props.advisorClientIds && props.advisorClientIds.forEach((clientId) => {
      output.push(
        { 
          collection: 'users', 
          doc: `${clientId}`, 
          subcollections: [
            { collection: "profile" },
          ],
          storeAs: 'profile_' + clientId
        },
      )
    });

    return output;
  }),
  withFirestoreData,
  withRouter
)(MenuPage);