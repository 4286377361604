import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import moment from 'moment';
import 'moment/locale/sv';

import { 
  Segment, 
  Image,
  Button,
  Icon,
  Form,
  TextArea,
  Modal,
  Confirm,
} from "semantic-ui-react";

import { GEOTAG_TYPES } from "@/constants/geotags";

const INITIAL_STATE = {
  showAddComment: false,
  showDeleteModal: false,
  newReplyNote: '',
  isSubmitting: false,
  fullscreenImageUrl: null,
};
class OverviewGeoTagList extends Component {

  state = {...INITIAL_STATE};
  
  onClickGeoTag = (geoTag) => {
    this.props.onClickGeoTag(geoTag, "list");
    this.props.history.push("/geotags/" + this.props.fieldId + "/details/" + geoTag.id);
  };

  deleteObservation = (id) => {
    const {
      db,
      getUser,
      fileBucketRef,
    } = this.props;

    let currentPost = db && db.observations && db.observations[id];

    currentPost && currentPost.photos && currentPost.photos.forEach((x) => {
      fileBucketRef.child(x).delete();
    });

    getUser().collection("observations").doc(id).delete()
      .then((x) => {
        this.props.removeObservation(id);
        this.setState({ showDeleteModal: false });
        this.props.history.push("/geotags/" + this.props.fieldId + "/list");
      });
  };

  onAddComment = (data) => {
    this.setState({
      showAddComment: data && data.id ? data.id : '',
      newReplyNote: '',
    });
  };

  onSaveComment = (data) => {
    const {
      db,
      getUser,
      trueUserId,
    } = this.props;

    const {
      newReplyNote,
    } = this.state;

    this.setState({ isSubmitting: true });
    
    const docRef = getUser().collection("observations").doc(data.id);
    let currentPost = db && db.observations && db.observations[data.id];

    let newComment = {
      date_created: new Date(),
      userId: trueUserId,
      note: newReplyNote,
    };
    
    let newComments = currentPost.comments && currentPost.comments.length > 0 ? 
      [...currentPost.comments, newComment] :
      [newComment];
    
    docRef.update({
      date_latest_update: new Date(),
      comments: newComments,
    })
      .then((res) => {
        this.setState({
          isSubmitting: false,
          newReplyNote: '',
          showAddComment: null,
        })
      });
  };

  onChange = (event) => {
    let newValue = event.target.value;
    this.setState({ [event.target.name]: newValue });
  };  

  getImageUrl = (id, filePaths) => { 
    const {
      fileBucketRef,
    } = this.props;

    if (this.state['imageData_' + id]) {
      return null;
    }

    let allPromises = [];

    filePaths && filePaths.forEach((filePath) => {
      allPromises.push(fileBucketRef.child(filePath).getDownloadURL());
    });

    Promise.all(allPromises)
      .then((urls) => {
        this.setState({ ['imageData_' + id]: urls });
      })
      .catch((error) => console.log(error.toString()))
  }; 

  getGeoTagOverview = (data, expandedDetails=false) => {
    const {
      userId,
    } = this.props;

    const {
      showAddComment,
      newReplyNote,
      isSubmitting,
    } = this.state;

    let type = data && data.tag_main_type;
    let subType = data && data.tag_sub_type;
    let lastEntry = data && data.entries && data.entries[data.entries.length - 1];

    let selectedMarkerType = type && GEOTAG_TYPES && GEOTAG_TYPES[type];
    let selectedSubMarketType = selectedMarkerType && subType && selectedMarkerType.subTypes[subType];
    
    let header = selectedMarkerType && selectedMarkerType.title;
    let ownHeader = data && data.header;
    let subHeader = selectedSubMarketType && selectedSubMarketType.title;
    let icon = selectedMarkerType && selectedMarkerType.icon;

    let amountText = lastEntry && lastEntry.amount;
    amountText = amountText && selectedSubMarketType.amounts.find((x) => x.value === lastEntry.amount);
    amountText = amountText && amountText.text;

    let radiusText = lastEntry && lastEntry.radius;
    radiusText = radiusText && selectedSubMarketType.radius.find((x) => x.value === lastEntry.radius);
    radiusText = radiusText && radiusText.text;

    let note = lastEntry && lastEntry.note;
    let photos = data && data.photos;
    let comments = data.comments ? data.comments : [];

    let dateCreatedString = data && data.date_created && moment(data.date_created.toDate()).locale('sv').fromNow();

    let source = data && data.userId ? data.userId : " dig ";
    source = source === "freja" ? " Freja " : source;
    source = source === userId ? " dig " : source;
    source = source ? source : " din rådgivare ";

    photos && this.getImageUrl(data.id, photos);

    return (
      <React.Fragment
        key={"segment_" + data.id}
      >
        <Segment 
          basic
          style={{
            margin: null,
            marginBottom: "1em",
            padding: 10,
            paddingBottom: 1,
            backgroundColor: "white",
            borderRadius: 5,
            fontSize: "120%",
          }}
          loading={photos && photos.length > 0 && !this.state['imageData_' + data.id]}
          onClick={expandedDetails ? () => {} : () => this.onClickGeoTag(data)}
        >
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
          
            <div
              style={{
                width: "4em"
              }}
            >
              <div 
                style={{
                  backgroundColor: "#F8EED0",
                  borderRadius: 5,
                  width: "2.4em",
                  height: "2.4em",
                  marginRight: "1em",
                }}
              >
                <div style={{ height: "0.5em" }} />
                
                <Image 
                  style={{ 
                    marginLeft: "0.5em",
                    width: "1.4em", 
                    height: "1.4em",
                  }}
                  src={icon} 
                />
              </div>
            </div>
      
            <div
              style={{
                marginBottom: 0,
                marginLeft: 10,
                width: "100%",
              }}
            >
              <div 
                style={{ 
                  fontWeight: 500, 
                  marginBottom: (subHeader || ownHeader) ? 2 : 10,
                  paddingBottom: 0,
                  whiteSpace: "pre-line", 
                }}
              >
                {subHeader && subHeader}
                {!subHeader && ownHeader && ownHeader}
                {(!subHeader && !ownHeader) && header && header}
              </div>
              
              {(subHeader || ownHeader) &&
                <div 
                  style={{ 
                    marginTop: 0,
                    marginBottom: 10,
                    paddingTop: 0,                  
                    fontWeight: 500, 
                    whiteSpace: "pre-line", 
                    fontSize: "80%",
                    lineHeight: "80%",
                    color: "grey",
                  }}
                >
                  {header && header}
                </div>
              }

              {amountText &&
                <div 
                  style={{ 
                    marginTop: 0,
                    marginBottom: 0,
                    paddingTop: 0,                  
                    fontWeight: 500, 
                    whiteSpace: "pre-line", 
                    fontSize: "70%",
                    lineHeight: "70%",
                  }}
                >
                  Mängd: {amountText}
                </div>
              }

              {radiusText &&
                <div 
                  style={{ 
                    marginTop: 0,
                    marginBottom: 0,
                    paddingTop: 0,                  
                    fontWeight: 500, 
                    whiteSpace: "pre-line", 
                    fontSize: "70%",
                    lineHeight: "70%",
                  }}
                >
                  Yta: {radiusText}
                </div>
              }              

              <div 
                style={{ 
                  marginTop: 20,
                  color: "grey", 
                  fontSize: "60%",
                  lineHeight: "60%",
                  whiteSpace: "pre-line" ,
                }}
              >
                Lades till av {source} {dateCreatedString}.
              </div>           
            </div>
          </div>

          {expandedDetails && photos && photos.length > 0 &&
            <div 
              style={{ 
                color: "grey", 
                fontSize: "90%",
                marginBottom: "1em",
                whiteSpace: "pre-line" ,
              }}
            > 
              {this.state['imageData_' + data.id] && this.state['imageData_' + data.id].map((url) => (
                <Image
                  key={url}
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => this.toggleFullScreenImage(url)}
                  src={url} 
                />
              ))}
            </div>
          }

          {note && 
            <div 
              style={{ 
                color: "grey", 
                fontSize: "90%",
                marginBottom: "1em",
                whiteSpace: "pre-line" ,
              }}
            >
              {note}
            </div>
          }


          {!expandedDetails && ((comments && comments.length > 0) || (photos && photos.length > 0)) && 
            <div 
              style={{ 
                fontSize: "70%",
                whiteSpace: "pre-line",
                color: "#6B9D9F",
                marginBottom: 10,
              }}
            >
              {comments && comments.length > 0 && comments.length + " kommentarer"} 
              {comments && comments.length > 0 && photos && photos.length > 0 && <span> &bull; </span>} 
              {photos && photos.length > 0 && photos.length + " bilder"} 
            </div>
          }

          {expandedDetails &&
            <div  
              style={{
                marginTop: "2em",
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                color: "#6B9D9F",
                fontSize: "70%",            
              }}
            >
              <div 
                onClick={() => this.onAddComment(data)}
              >
                <Icon name="comment" />
                Kommentera
              </div>
              
              <div 
                onClick={() => this.props.history.push("/add/editObservation/" + data.id + "/selectMapLocation")}
              >
                <Icon name="edit" />
                Redigera
              </div>

              <div 
                onClick={() => this.setState({ showDeleteModal: data.id })}
              >
                <Icon name="delete" />
                Ta bort
              </div>
            </div>
          }
        </Segment>

        {expandedDetails && comments && comments.length > 0 && comments.map((x, idx) => {
          let dateReplyCreatedString = x && x.date_created && moment(x.date_created.toDate()).locale('sv').fromNow();

          let replySource = x && x.userId ? x.userId : " dig ";
          replySource = replySource === "freja" ? " Freja " : replySource;
          replySource = replySource === userId ? " dig " : replySource;
          replySource = replySource ? replySource : " din rådgivare ";

          return (
            <Segment
              key={"reply_" + data.id + "_" + idx} 
              basic 
              style={{
                marginLeft: "1em",
                marginBottom: ((idx + 1 === comments.length) && showAddComment !== data.id) ? "1em" : "0.35em",
                padding: 10,
                backgroundColor: "white",
                borderRadius: 5,
              }}
            >
              <div 
                style={{ 
                  color: "grey", 
                  fontSize: "90%",
                  marginBottom: "1em",
                  whiteSpace: "pre-line" ,
                }}>
                  {x.note}
              </div>

              <div 
                style={{ 
                  color: "grey", 
                  fontSize: "70%",
                  whiteSpace: "pre-line" ,
                }}
              >
                Lades till av {replySource} {dateReplyCreatedString}
              </div>
            </Segment>
          )
        })}   
        
        {expandedDetails && showAddComment === data.id && 
          <Segment 
            basic 
            style={{
              marginLeft: "1em",
              marginBottom: "1em",
              padding: 10,
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            <Form>
              <TextArea 
                autoFocus
                focus
                placeholder="Skriv en kommentar..."
                size="small"
                name="newReplyNote"
                value={newReplyNote}
                onChange={(e) => this.onChange(e)}
              />
            </Form>

            <div
              style={{
                marginTop: "0.5em",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button
                primary
                size="mini"
                onClick={() => this.onSaveComment(data)}
                disabled={!newReplyNote || newReplyNote.length === 0}
                loading={isSubmitting}
              >
                Skicka
              </Button>

              <Button
                size="mini"
                onClick={() => this.onAddComment()}
                disabled={isSubmitting}
              >
                Avbryt
              </Button>
            </div>
          </Segment>
        }
      </React.Fragment>
    )
  };

  toggleFullScreenImage = (url) => {
    this.props.toggleShowMultipleFieldsModal && this.props.toggleShowMultipleFieldsModal();

    this.setState({
      fullscreenImageUrl: url ? url : '',
    });
  };

  render() {
    const { 
      db, 
      fieldId,
      tagId,
      geoTagFilters,
      removedObservationIds,
    } = this.props;

    const {
      fullscreenImageUrl,
      showDeleteModal,
    } = this.state;

    let geoTagIds = db && db.observations && Object.keys(db.observations);
    geoTagIds = geoTagIds && geoTagIds.filter((x) => !removedObservationIds.includes(x));

    if (geoTagIds && geoTagIds.length > 1) {
      geoTagIds.sort(function(a, b) {
        if (db.observations[a] && db.observations[b] && db.observations[a].dateUpdated && db.observations[b].dateUpdated && 
          typeof db.observations[a].dateUpdated.toDate === 'function' && 
          typeof db.observations[b].dateUpdated.toDate === 'function'
        ) {
          return db.observations[b].dateUpdated.toDate() - db.observations[a].dateUpdated.toDate();
        }

        return null
      });
    };

    let geoTagListData = geoTagIds && geoTagIds.map(x => {
      if (fieldId !== 'none' && db && db.observations && db.observations[x] && db.observations[x].field_id !== fieldId) {
        return null
      };

      let type = db && db.observations && db.observations[x] && db.observations[x].tag_main_type;
      let subType = db && db.observations && db.observations[x] && db.observations[x].tag_sub_type;

      if (geoTagFilters && type && subType && !geoTagFilters[type][subType]) {
        return null
      }
      
      return this.getGeoTagOverview({id: x, ...db.observations[x]});
    });

    geoTagListData = geoTagListData && geoTagListData.filter((x) => x && x);

    geoTagListData = tagId ? this.getGeoTagOverview({id: tagId, ...db.observations[tagId]}, true) : geoTagListData;

    return (
      <React.Fragment>
        <Modal
          basic
          open={fullscreenImageUrl}
          size="fullscreen"
          onClose={() => this.toggleFullScreenImage()}
        >
          <Image centered style={{ maxHeight: "75vh", maxWidth: "75vw" }} src={fullscreenImageUrl} />
        </Modal>

        <Confirm
          open={showDeleteModal}
          header="Vill du ta bort observationen?"
          content="Denna handling går inte att ångra."
          confirmButton="Ta bort"
          cancelButton="Avbryt"
          onConfirm={() => this.deleteObservation(showDeleteModal)}
          onCancel={() => this.setState({ showDeleteModal: false })}
          size="tiny"
        />

        <div
          style={{
            marginTop: 60,
            marginLeft: "1em",
            marginRight: "1em",
          }}
        >
          {geoTagListData && geoTagListData}

          {(!geoTagListData || geoTagListData.length === 0) && 
            <React.Fragment>
              <div 
                style={{
                  fontWeight: 500,
                  marginBottom: 0,
                  textAlign: "center",
                  color: "grey",
                  marginTop: "40%",
                  marginLeft: "15%",
                  width: "70%",
                  whiteSpace: "pre-line",
                }}
              >
                Freja hittade inga observationer för detta skifte. 
                Klicka på knappen nedan för att lägga till en första observation.
              </div>

              <Button
                primary
                style={{
                  width: "50vw",
                  marginLeft: "25vw",
                  marginRight: "25vw",
                  marginTop: "4em",
                }}
                size="large"
                onClick={() => this.props.history.push("/add/addObservation/new/selectMapLocation")}
              >
                Lägg till observation
              </Button>
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(OverviewGeoTagList);

