import React, { Component } from 'react';

import {
  Icon,
} from "semantic-ui-react";

const INITIAL_STATE = {};

class MenuTop extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render () {

    const {
      onClickGoBack,
      caption,
      children,
    } = this.props;

    if (onClickGoBack) {
      return (
        <div 
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: 50,
            zIndex: 9,
            backgroundColor: "white",
            width: "100vw",        
            display: "flex",
            justifyContent: "space-between",
            padding: "1em",
          }}
        >
        
        <p
          style={{
            fontSize: "110%",
          }}
        > 
          {caption && caption}
        </p>    

          <div>
          {children && children}

            <Icon
              name="close"
              onClick={() => onClickGoBack()}
              style={{
                display: "inline-block",
                marginBottom: 2,
                marginRight: -2,
                marginLeft: 15,
              }}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div 
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: 50,
            zIndex: 9,
            backgroundColor: "white",
            width: "100vw",        
            display: "flex",
            justifyContent: "space-between",
            padding: "1em",
          }}
        >
          <p
            style={{
              fontSize: "110%",
            }}
          >
            {caption && caption}
          </p>

          {children && children}
        </div>
      )
    };
  };
}

export default MenuTop;

