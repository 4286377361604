import React from 'react';

import GeoTag from '@/components/geotags/GeoTag';

import { 
  geoPointToPosition, 
} from '@/helpers/db';

const GeoTagList = (props) => {

  const {  
    observations,
    onClickGeoTag,
    fieldId,
    geoTagFilters,
    onlyGeoTagId,
  } = props;

  let geoTagsArray = observations;

  if (geoTagFilters) {
    geoTagsArray = observations && 
      observations.filter(x => {
        if (x && x.tag_main_type && x.tag_main_type === 'custom') {
          return geoTagFilters[x.tag_main_type]
        } else {
          if (x.tag_main_type && x.tag_sub_type) {
            return geoTagFilters[x.tag_main_type][x.subType]
          } else {
            return false
          }
        };
    })
  };
  
  // TODO: Remove when custom tags are implemented
  geoTagsArray = geoTagsArray.filter((x) => x.tag_main_type !== 'custom');

  if (fieldId) {
    geoTagsArray = geoTagsArray.filter((x) => x.field_id === fieldId);
  };

  if (onlyGeoTagId) {
    geoTagsArray = geoTagsArray.filter((x) => x.id === onlyGeoTagId);
  }

  return (
    geoTagsArray && geoTagsArray.map(x => {
      if (x && x.position) {
        if (onClickGeoTag) {
          return (
            <GeoTag
              position={geoPointToPosition(x.position)}
              type={x.tag_main_type ? x.tag_main_type : ''}
              click={() => onClickGeoTag(x)}
              key={x.id}
            />
          )
        } else {
          return (
            <GeoTag
              position={geoPointToPosition(x.position)}
              type={x.tag_main_type ? x.tag_main_type : ''}
              key={x.id}
            />
          )
        }
      }

      return null
    })
  )
};

export default GeoTagList;