import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { 
  Segment,
  Icon,
  Image,
  Button,
  Dropdown,
  Modal,
} from "semantic-ui-react";

import moment from 'moment';
import 'moment/locale/sv';

import { CROP_CODES } from "@/constants/cropCodes";
import { FIELD_DATA_LAYERS } from "@/constants/viewLayersAnalysis";
import { CONTROL_GUIDES } from "@/constants/guides";

import MenuFieldList from "@/components/menus/MenuFieldList";
import MenuTop from "@/components/menus/MenuTop";

import imgFieldInfo from "@/assets/icons/menuPrimary/fields.svg";
import imgGreenMass from "@/assets/icons/dataTypes/green-mass-data.svg";
import imgSoil from "@/assets/icons/dataTypes/soil-data.svg";

import guidesIcon from "@/assets/icons/menuPrimary/guides.svg";
import insightsIcon from "@/assets/icons/menuSecondary/freja-insights.svg";
import observationIcon from "@/assets/icons/dataTypes/geotag.svg";
import yieldOverviewIcon from "@/assets/icons/yield-overview.svg";

import emptyIllustration from "@/assets/illustrations/onboarding_guide_2.png";

const INITIAL_STATE = {
  showMultipleFieldsModal: false,
  fieldIds: null,
  layer: null,
  layerId: null,
  showConfirmDeleteNotification: false,
  notificationIdToBeDeleted: null,
  notificationsRead: [],
  notificationsDeleted: [],
  noNotificationsToShow: 10,
  categoryFilter: 'all',
  categoryFilterArray: [],
  prevY: 0,
};

const NotificationDot = () => (
  <div 
    style={{ 
      position: "relative", 
      top: "-1.7em", 
      right: "-1.7em", 
      width: "0.6em", 
      height: "0.6em", 
      borderRadius: "50%", 
      backgroundColor: "#C36126" 
    }} 
  />
);

const NotificationSegment = ({id, onClick, icon, noIconBackground, header, description, markNotificationRead, deleteNotification, seen}) => {
  return (
    <Segment 
      key={"notification_segment_" + id}
      basic 
      className="backgroundColorOnHover"
      style={{
        margin: 7,
        padding: 10,
        cursor: "pointer",
        backgroundColor: "#ffffff",
        borderRadius: 5,
        width: "calc(100% - 20px)",
        marginLeft: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {noIconBackground &&
          <Image 
            style={{ 
              width: "2.4em", 
              height: "2.4em",
              marginRight: "1em",
            }}
            src={icon} 
          />
        }

        {!noIconBackground &&
          <div 
            style={{
              backgroundColor: "#F8EED0",
              borderRadius: 5,
              width: "2.4em",
              height: "2.4em",
              marginRight: "1em",
            }}
          >
            <Image 
              style={{ 
                marginTop: "0.5em",
                marginLeft: "0.5em",
                width: "1.4em", 
                height: "1.4em",
                filter: "invert(0%)",
              }}
              src={icon} 
            />

            {(typeof(seen) !== "boolean" || seen !== true) && <NotificationDot />}
          </div>
        }

        <div
          style={{
            marginBottom: 0,
            marginLeft: 10,
            width: "80%",
          }}
          onClick={() => onClick() }
        >
          <div style={{ fontWeight: 500, marginBottom: 0 }}>{header && header}</div>
          <div style={{ color: "grey", fontSize: "90%" }}>{description}</div>
        </div> 
        
        <div>
          <Dropdown
            style={{
              marginLeft: 5,
              marginRight: 0,
            }}
            icon='ellipsis vertical'
            floating
            direction="left" 
          >
            <Dropdown.Menu>
              <Dropdown.Item 
                icon='check' 
                text={(typeof(seen) === "boolean" && seen === true) ? 'Markera oläst' : 'Markera läst'} 
                onClick={() => markNotificationRead(id, (typeof(seen) === "boolean" && seen === true) ? true : false)}
              />
              <Dropdown.Item 
                icon='trash' 
                text='Ta bort' 
                onClick={() => deleteNotification()}
              />                    
            </Dropdown.Menu>
          </Dropdown> 
        </div>      
      </div>
    </Segment>
  )
};


class NotificationsList extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );

    this.observer.observe(this.bottomDivRef);
  };

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {
      this.setState({ noNotificationsToShow: this.state.noNotificationsToShow + 5 });
    };

    this.setState({ prevY: y });
  };

  componentDidUpdate = (prevProps) => {
    const {
      notificationId,
      db,
    } = this.props;

    if (prevProps.db === db && this.state.showMultipleFieldsModal === true) {
      return;
    };

    if (notificationId) {
      let notifications = db && db.notifications && db.notifications;
      let data = notifications && notificationId && notifications[notificationId];

      if (data && data.type && data.type === "newData") {
        if (data && data.data_type && data.data_type === "sentinel") {
          let layer = "greenmass";
          let layerIds = data && data.new_entries && data.new_entries.length > 0 && [...data.new_entries].sort();
          
          let fieldIds = data && data.field_names && data.field_names;
          fieldIds = fieldIds && [...new Set(fieldIds)];
          
          fieldIds = fieldIds && fieldIds.filter((x) => {
            let availableToUser = db && db.fields && db.fields[x] && db.fields[x].available_to_user;
            availableToUser = typeof(availableToUser) === "boolean" ? availableToUser : true;
      
            return availableToUser;
          });
      
          if (fieldIds.length === 0) {
            return null
          };

          this.openField(notificationId, fieldIds, layer, layerIds)
        };
      };
    };
  };

  downloadFile = (filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
            filename: filePath.split("/").slice(-1)[0],
            blob: await res.blob()
        }))
        .then(resObj => {
            // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
  
            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
  
                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();
  
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        })
        .catch((error) => {
            console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  openField = (notificationId, fieldIds, layer, layerIds) => {
    notificationId && this.markNotificationRead(notificationId, false);

    let layerId = layerIds && (Array.isArray(layerIds) ? layerIds.slice(-1)[0] : layerIds);

    if (fieldIds.length === 1) {
      this.props.history.push("/layers/" + fieldIds[0] + "/" + layer + (layerId ? ("/" + layerId) : ''))
    } else {
      this.setState({
        showMultipleFieldsModal: true,
        fieldIds: fieldIds,
        layer: layer,
        layerId: layerId,
      });
    };
  };

  openSelectedField = (fieldId) => {
    this.openField(null, [fieldId], this.state.layer, this.state.layerId);
  };

  markNotificationRead = (id, currentValue) => {
    const {
      getUser,
    } = this.props;

    // let currentValue = db && id && db.notifications && db.notifications[id] && db.notifications[id].seen;
    // currentValue = typeof(currentValue) === "boolean" ? currentValue : false;
    
    let dbRef = getUser().collection("notifications").doc(id);
    dbRef.update({ seen: !currentValue });

    let currentNotificationsRead = this.state.notificationsRead;

    if (currentNotificationsRead.includes(id)) {
      this.setState({ notificationsRead: currentNotificationsRead.filter((x) => x !== id) });
    } else {
      this.setState({ notificationsRead: [...currentNotificationsRead, id] });
    };
  };

  toggleDeleteNotification = (id) => {
    let currentValue = this.state.showConfirmDeleteNotification;
    this.setState({
      showConfirmDeleteNotification: !currentValue,
      notificationIdToBeDeleted: id ? id : null,
    })
  };

  deleteNotification = () => {
    const {
      getUser,
    } = this.props;

    let id = this.state.notificationIdToBeDeleted;
    getUser().collection("notifications").doc(id).delete();

    let currentValue = this.state.notificationsDeleted;
    this.setState({ notificationsDeleted: [...currentValue, id] });
    this.toggleDeleteNotification()
  };

  markNotificationsRead = () => {
    const {
      db,
      getUser,
    } = this.props;

    const {
      categoryFilterArray,
    } = this.state;

    let notifications = db && db.notifications && db.notifications;
    notifications = notifications && Object.keys(notifications).map(x => ({id: x, ...db.notifications[x]}));
    notifications = notifications && notifications.filter((x) => typeof(x.type) === "string");
    notifications = notifications && categoryFilterArray.length > 0 ? notifications.filter((x) => categoryFilterArray.includes(x.type)) : notifications;

    notifications.forEach((x) => {
      let dbRef = getUser().collection("notifications").doc(x.id);
      dbRef.update({
        seen: true,
      });
    })
  };

  getNotification = (data) => {
    if (data && data.type && data.type === "newFields") {
      return this.getNewFieldsSegment(data)
    };

    if (data && data.type && data.type === "newData") {
      return this.getUpdateSegment(data)
    };

    if (data && data.type && data.type === "newComment") {
      return this.getCommentSegment(data)
    };    

    if (data && data.type && data.type === "newFile") {
      return this.getFileSegment(data)
    };

    if (data && data.type && data.type === "newFileGuide") {
      return this.getFileGuideSegment(data)
    };      
  };

  getEmptySegment = () => {
    return (
      <Segment 
        basic 
        style={{
          display: "block",
          marginTop: "15vh",
          backgroundColor: "transparent",
          width: "100%",
        }}
      >
        <Image  
          centered 
          style={{
            height: "40vh",
          }}
          src={emptyIllustration}
        />

        <div 
          style={{ 
            fontWeight: 500, 
            marginBottom: 0, 
            textAlign: "center" ,
          }}
        >
          Inget här ännu.
        </div>
      </Segment>
    )
  };

  getUpdateSegment = (data) => {
    const {
      db,
    } = this.props;    

    let title = "Ny data tillgänglig";
    let layer = "greenmass";
    let icon = imgGreenMass;
    let hasDataToShow = true;

    let layerIds = data && data.new_entries && data.new_entries.length > 0 && [...data.new_entries].sort();

    if (data && data.data_type && data.data_type === "sentinel") {
      layer = "greenmass";
      title = layerIds.length === 1 ? "En ny grönmassamätning tillgänglig för " : layerIds && layerIds.length.toFixed(0) + " nya grönmassamätningar tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.greenmass.icon
    };

    if (data && data.data_type && data.data_type === "soilMapUploaded") {
      layer = "soilMapUploaded";
      title = layerIds.length === 1 ? "En ny markkartering tillgänglig för " : layerIds && layerIds.length.toFixed(0) + " nya markkarteringar tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.soilMapUploaded.icon;
    };

    if (data && data.data_type && data.data_type === "soilMapSweden") {
      layer = "soilMapSweden";
      title = layerIds.length === 1 ? "Digitala åkermarkskartan tillgänglig för " : layerIds.length.toFixed(0) + " digitala åkermarkskartor tillgängliga för ";
      icon = imgSoil;
      hasDataToShow = false;
    };    

    if (data && data.data_type && data.data_type === "yieldMap") {
      layer = "yieldMap";
      title = layerIds.length === 1 ? "En ny skördekarta tillgänglig för " : layerIds && layerIds.length.toFixed(0) + " nya skördekartor tillgängliga för ";
      icon = FIELD_DATA_LAYERS && FIELD_DATA_LAYERS.yieldMap.icon;
    };

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    
    let fieldNames = '';
    let fieldIds = data && data.field_names && data.field_names;
    fieldIds = fieldIds && [...new Set(fieldIds)];
    
    fieldIds = fieldIds && fieldIds.filter((x) => {
      let availableToUser = db && db.fields && db.fields[x] && db.fields[x].available_to_user;
      availableToUser = typeof(availableToUser) === "boolean" ? availableToUser : true;

      return availableToUser;
    });

    if (fieldIds.length === 0) {
      return null
    };

    if (fieldIds && fieldIds.length === 1) {
      let fieldId = fieldIds && fieldIds[0];
      fieldNames = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : 'Okänt skiftesnamn';
    } else {
      fieldNames = fieldIds && fieldIds.length.toFixed(0) + " skiften";
    };

    return (
      <NotificationSegment
        key={data.id}
        id={data.id}
        header={(title ? title : "") + " " + (fieldNames ? fieldNames : " ") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={() => hasDataToShow && this.openField(data.id, fieldIds, layer, layerIds)}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}

      />
    )
  };

  getCommentSegment = (data) => {
    const {
      db,
    } = this.props;

    let title = "Ny kommentar";
    title = title && data.comment ? title + ": " + data.comment : title;

    let icon = insightsIcon;
    icon = data && data.data_type === "observation" ? observationIcon : icon;
    
    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();

    let fieldId = data && data.field_name && data.field_name;
    let fieldIds = data && data.field_names && data.field_names;
    fieldIds = fieldIds && fieldIds.length > 0 ? fieldIds : [ fieldId ];

    let fieldNames = '';

    let isAvailableToUser = false;

    fieldIds && fieldIds.forEach((x) => {
      let availableToUser = db && db.fields && db.fields[x] && db.fields[x].available_to_user;
      availableToUser = typeof (availableToUser) === "boolean" ? availableToUser : true;

      if (availableToUser) {
        isAvailableToUser = true;
      }
    });

    if (fieldId) {
      fieldNames = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : '';
    };

    return (
      <NotificationSegment
        key={"update_" + data.id}
        widget={this.props.widget}
        id={data.id}
        header={(title ? title : "") + "."}
        description={(date ? date : "") + "." + (fieldNames ? "Skifte: " + fieldNames + "." : "")}
        icon={icon}
        onClick={isAvailableToUser ? () => this.openField(data.id, fieldIds, null, null) : null}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
        notificationsRead={this.state.notificationsRead}
        notificationsDeleted={this.state.notificationsDeleted}
      />
    )
  };  

  getNewFieldsSegment = (data) => {
    const {
      db,
    } = this.props;    

    let title = "Ny skiften tillgängliga";
    let icon = imgFieldInfo;
    let layerIds = data && data.field_names && data.field_names.length > 0 && [...data.field_names].sort();

    if (data && data.data_type && data.data_type === "unlocked") {
      title = layerIds.length === 1 ? "Grattis! Du har låst upp skiftet: " : "Grattis! Du har låst upp ";
    };

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();

    let fieldNames = '';
    let fieldIds = data && data.field_names && data.field_names;
    fieldIds = fieldIds && [...new Set(fieldIds)];
    
    fieldIds = fieldIds && fieldIds.filter((x) => {
      let availableToUser = db && db.fields && db.fields[x] && db.fields[x].available_to_user;
      availableToUser = typeof(availableToUser) === "boolean" ? availableToUser : true;

      return availableToUser;
    });

    if (fieldIds.length === 0) {
      return null
    };

    if (fieldIds && fieldIds.length === 1) {
      let fieldId = fieldIds && fieldIds[0];
      fieldNames = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : 'Okänt skiftesnamn';
    } else {
      fieldNames = fieldIds && fieldIds.length.toFixed(0) + " nya skiften";
    };

    return (
      <NotificationSegment
        key={data.id}
        id={data.id}
        header={(title ? title : "") + " " + (fieldNames ? fieldNames : " ") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={() => this.openField(data.id, fieldIds, 'greenmass', null)}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
      />
    )
  };  

  getFileGuideSegment = (data) => {
    const {
      db,
    } = this.props;

    let title = "Nya styrfiler skapade för ";

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    
    let guideId = data && data.guide_id;
    let guideData = db && guideId && db && db.guides && db.guides[guideId];
    let guideType = guideData && guideData.guide_type;

    if (!guideData) { 
      return (
        <div />
      )
    }
    
    let currentGuide = CONTROL_GUIDES && guideType && CONTROL_GUIDES.find((x) => x.name === guideType);
    let outputName = currentGuide && currentGuide.outputName;
    
    let noFields = guideData && guideData.field_ids && guideData.field_ids.length;
    noFields = noFields ? " på " + noFields.toFixed(0) + " skiften" : "";

    let crop = guideData && guideData.crop;
    crop = CROP_CODES && crop && CROP_CODES.find((x) => x.name === crop);
    crop = crop && crop.shorttext ? " med " + crop.shorttext : "";

    let onClick = () => guideId && this.props.history.push("/guides/downloadFiles/" + guideId)

    return (
      <NotificationSegment
        id={data.id}
        header={(title ? title : "") + (outputName ? outputName : "") + (noFields ? noFields : "") + (crop ? crop : "") + "."}
        description={(date ? date : "") + "."}
        icon={currentGuide && currentGuide.icon}
        noIconBackground={true}
        onClick={onClick}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
        notificationsRead={this.state.notificationsRead}
        notificationsDeleted={this.state.notificationsDeleted}        
      />
    )
  };

  openUrl = (data) => {
    data && data.id && this.markNotificationRead(data.id, false);
    
    if (data && data.url) {
      window.location = data.url;
    };

    if (data && data.file_path) {
      this.downloadFile(data.file_path);
    };
  };

  getFileSegment = (data) => {
    const {
      db,
    } = this.props;    

    let date = data && data.date && moment(data.date.toDate()).locale('sv').fromNow();
    let fieldId = data && data.field_name && data.field_name;
    let fieldName = db && db.fields && db.fields[fieldId] && db.fields[fieldId].name ? db.fields[fieldId].name : 'okänt skiftesnamn';
    fieldName = fieldId === "farm" ? null : fieldName;


    let title = "Ny fil skapad";
    let icon = yieldOverviewIcon;

    if (data && data.data_type && data.data_type === "backgroundMap") {
      title = "En ny bakgrundskarta är skapad för "
      icon = guidesIcon;
    };

    if (data && data.data_type && data.data_type === "farmReport") {
      title = "En ny gårdsöversikt är skapad för din gård"
      icon = yieldOverviewIcon;
    };

    if (data && data.data_type && data.data_type === "harvestReport") {
      title = "En ny skördeöversikt är skapad för "
      icon = yieldOverviewIcon;
    };       

    if (data && data.data_type && data.data_type === "MapPDF") {
      let pdfTitle = (data.file_path).split("/").pop().replace(".pdf", "").replaceAll("_", " ").replace("agriopt ", "").replace(fieldName.toLowerCase(), "").replace(fieldName, "")
      title = "Ny karta exporterad som PDF: " + pdfTitle
      icon = yieldOverviewIcon;
      if (title.length + fieldName.length > 58) title = title.substring(0, 53 - fieldName.length) + ".. "
    };

    return (
      <NotificationSegment
        key={data.id}
        id={data.id}
        header={(title ? title : "") + (fieldName ? fieldName : " ") + "."}
        description={(date ? date : "") + "."}
        icon={icon}
        onClick={() => this.openUrl(data)}
        markNotificationRead={this.markNotificationRead}
        deleteNotification={() => this.toggleDeleteNotification(data.id)}
        seen={data.seen}
      />
    )
  };

  render () {
    const {
      userId,
      db,
      caption,
      onClickGoBack,
      hideMarkAllRead,
    } = this.props;

    const {
      fieldIds,
      showMultipleFieldsModal,
      showConfirmDeleteNotification,
      noNotificationsToShow,
      categoryFilter,
      categoryFilterArray,
    } = this.state;

    let menuItems = [
      {
        key: 'all',
        value: 'all',
        onClick: () => this.setState({ categoryFilter: 'all', categoryFilterArray: []}),
        text: "Ingen filtrering",
      },      
      {
        key: 'newFields',
        value: 'newFields',
        onClick: () => this.setState({ categoryFilter: 'newFields', categoryFilterArray: ['newFields']}),
        text: "Nya skiften",
      },
      {
        key: 'newData',
        value: 'newData',
        onClick: () => this.setState({ categoryFilter: 'newData', categoryFilterArray: ['newData']}),
        text: "Ny data",
      },
      {
        key: 'newFiles',
        value: 'newFiles',
        onClick: () => this.setState({ categoryFilter: 'newFiles', categoryFilterArray: ["newFile", "newFiles", "newFileGuide"]}),
        text: "Nya filer",
      },
    ];

    let notifications = db && db.notifications && db.notifications;
    notifications = notifications && Object.keys(notifications).map(x => ({ id: x, ...db.notifications[x] }));
    notifications = notifications && notifications.filter((x) => typeof (x.type) === "string");
    notifications = notifications && notifications.sort((a, b) => b.date - a.date);

    notifications = notifications && categoryFilterArray.length > 0 ? notifications.filter((x) => categoryFilterArray.includes(x.type)) : notifications;
    let noNotifications = notifications && notifications.length;
    notifications = notifications && notifications.splice(0, noNotificationsToShow);

    let notificationSegments = notifications && notifications.map((x) => this.getNotification(x));
    notificationSegments = notificationSegments && notificationSegments.filter((x) => x !== null);
    notificationSegments = notificationSegments && notificationSegments.length > 0 ? notificationSegments : this.getEmptySegment();

    let notificationList = 
      <div
        style={{
          padding: 0,
          width: "100%",
        }}
      >
        <MenuTop caption={caption} onClickGoBack={onClickGoBack}>      
          <div>
            {!hideMarkAllRead &&
              <div
                style={{
                  display: "inline-block",
                  color: "#6B9D9F",
                  fontSize: "95%",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: 10,
                }}
                onClick={() => this.markNotificationsRead()}
              >
                Markera alla lästa
              </div>
            }

            &bull;

            <Dropdown
              style={{
                display: "inline",
                color: "#6B9D9F",
                fontSize: "95%",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: 10,
              }}
              options={menuItems}
              value={categoryFilter}
            />
          </div>             
        </MenuTop>

        <Segment.Group 
          style={{
            border: "none",
            boxShadow: "none",
            marginTop: 60,
            marginBottom: 0,
            width: "100%",
            overflowY: "auto",            
          }}
        >
          {notificationSegments && notificationSegments}

          {noNotifications >= noNotificationsToShow &&
            <Segment
              basic
              style={{
                color: "grey",
                textAlign: "center",
                padding: 0,
                margin: 0,
                marginBottom: 0,
                paddingBottom: 10,
                fontWeight: "normal",
                fontSize: "85%",
              }}
              onClick={() => this.setState({ noRemindersToShow: this.state.noRemindersToShow + 5 })}
            >
              Klicka här eller scrolla för att visa fler händelser
              <Icon name="caret down" />
            </Segment>
          }

          {notificationSegments && notificationSegments.length > 0 && noNotifications < noNotificationsToShow &&
            <Segment
              basic
              style={{
                color: "grey",
                textAlign: "center",
                padding: 0,
                margin: 0,
                marginBottom: 0,
                paddingBottom: 10,
                fontWeight: "normal",
                fontSize: "85%",
              }}
            >
              Inga fler händelser hittades
            </Segment>
          }

          <div ref={bottomDivRef => (this.bottomDivRef = bottomDivRef)} />
        </Segment.Group>  
      </div> 
    
    let selectField = 
      <div
        style={{
          padding: 0,
          width: "100%",
        }}
      >
        <MenuTop caption={"Välj skifte att öppna"}>            
          <p
            style={{
              color: "#6B9D9F",
              fontSize: "95%",
              cursor: "pointer",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            onClick={() => this.setState({ showMultipleFieldsModal: false })}
          >
            Avbryt
          </p>
        </MenuTop>

        <MenuFieldList
          db={db}
          userId={userId}
          fieldId={null}
          openField={this.openSelectedField}
          compact={true}
          hideFarmCard={true}
          hideLockedFields={true}
          showOnlyFieldIds={fieldIds}
        />  
      </div>     

    return (
      <React.Fragment>
        
        <Modal
          open={showConfirmDeleteNotification}
          dimmer="blurring"
          style={{
            position: 'absolute',
            top: '35%',
            bottom: '35%',
            left: '10%',
            right: '10%',
            height: 'auto',
            width: 'auto',
            backgroundColor: '#ffffff',
            zIndex: 9,
            color: "black",
          }}
        >
          <Modal.Header style={{ color: "black" }}>Vill du ta bort händelsen?</Modal.Header>
          <Modal.Content style={{ height: "100%" }}>
            <div
              style={{
                marginTop: "2em",
                color: "black",
                whiteSpace: "pre-line",
              }}
            >
              Denna handling går inte att ångra.
            </div>

            <div
              style={{
                position: "absolute",
                bottom: 25,
                marginLeft: 30,
                marginRight: 30,
                width: "calc(100% - 60px)",
                zIndex: 99,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{
                    marginLeft: -25,
                    backgroundColor: "#868D8E",
                    color: "white",
                  }}
                  onClick={() => this.toggleDeleteNotification()}
                  size="small"
                >
                  Tillbaka
                </Button>

                <Button
                  style={{
                    backgroundColor: "#6B9D9F",
                    color: "white",
                  }}
                  onClick={() => this.deleteNotification()}
                  size="small"
                >
                  Ta bort
                </Button>
              </div>
            </div>
          </Modal.Content>
        </Modal>
        
        {showMultipleFieldsModal && selectField}
        {!showMultipleFieldsModal && notificationList}
      </React.Fragment>  
    )
  }
};

export default withRouter(NotificationsList);