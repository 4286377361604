import React, { Component } from 'react';
import { compose } from 'redux';

import { 
  withFirebase, 
  withFirestore as 
  withFirestoreActions 
} from 'react-redux-firebase';

import { 
  Button, 
  Form, 
  Segment, 
  Loader, 
} from 'semantic-ui-react'

import { LogoColor } from '@/components/branding/Logo';
import Page from "@/components/misc/Page";

import Background from '@/assets/backgrounds/field1.jpg';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  processingLogin: false,
};

/* eslint-disable react/prefer-stateless-function */
class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { email, password } = this.state;
    const { firebase } = this.props;

    this.setState({processingLogin: true});

    firebase
      .login({email, password})
      .then(response => {
        console.log('response', response);
      })
      .catch(error => {
        this.setState({ error });
        this.setState({processingLogin: false});
      });

      event.preventDefault();
  };

  render() {
    const { email, password, error, processingLogin } = this.state;

    let errorMessage = error && error.message;

    errorMessage = errorMessage === "There is no user record corresponding to this identifier. The user may have been deleted." ? 
      "Inget konto hittades på denna mailadress." : errorMessage;

    errorMessage = errorMessage === "The password is invalid or the user does not have a password." ? 
      "Felaktigt lösenord." : errorMessage;      


    return (
      <Page
        style={{
          paddingBottom: 0,
        }}      
      >
        <section 
          style={{
            height: "100%",
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            overflow: 'hidden',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",          
          }}
        >
          <div>
            <LogoColor showImage adapted />

            <Segment
              style={{
                padding: "2em",
                marginLeft: "auto",
                marginRight: "auto",
                width: "90vw",
                height: processingLogin ? "15em" : null,
                transition: "height 0.3s",
              }}
            >
              {!processingLogin &&
                <React.Fragment>
                  <Form
                    size="large"
                    onSubmit={this.onSubmit}
                    style={{ 
                      width: "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Form.Input
                      autoFocus
                      className="inputNoBorders"
                      fluid
                      focus
                      size="large"
                      icon="user"
                      name="email"
                      iconPosition="right"
                      placeholder="Mailadress"
                      value={email}
                      onChange={this.onChange}
                      autofocus
                    />

                    <Form.Input
                      fluid
                      focus
                      size="large"
                      icon="lock"
                      name="password"
                      iconPosition="right"
                      placeholder="Lösenord"
                      type="password"
                      value={password}
                      onChange={this.onChange}
                    />

                    {errorMessage &&
                      <p
                        style={{ 
                          marginTop: "1em",
                          marginBottom: "1em",
                          color: "#D6946B", 
                        }}
                      >
                        {errorMessage}
                      </p>
                    }          

                    <Button
                      fluid
                      size="large"
                      onClick={this.onSubmit}
                      style={{ 
                        marginTop: "2em", 
                        backgroundColor: "#6B9D9F",
                        color: "white",                  
                      }}
                    >
                      Logga in
                    </Button>
                  </Form>
                </React.Fragment>
              }

              {processingLogin &&
                <Loader 
                  active 
                  indeterminate 
                  size="massive"
                >
                  <div
                      style={{
                        fontSize: "80%", 
                        marginTop: "0.5em",
                        color: "grey",
                      }}
                    >
                    Loggar in...
                  </div>
                </Loader>            
              }  

              </Segment>
            </div>
          </section>
        </Page>
      )
  }
}

export default compose(
  withFirebase,
  withFirestoreActions,
)(LoginPage);