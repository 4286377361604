import React, { Component, Fragment } from "react";

import {
  Segment,
} from "semantic-ui-react";

import { Line } from "react-chartjs-2";

import moment from 'moment';

const INITIAL_STATE = {};

class WeatherDailySummaryWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render() {
    const db = this.props.db;

    let tempData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather.temp_last24hrs;

    let precData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather.prec_last24hrs;

    let totalPrec = precData && precData.map(x => x.value).reduce((partial_sum, a) => partial_sum + a, 0);

    let meanTemp = tempData && tempData.map(x => x.value).reduce((partial_sum, a) => partial_sum + a, 0);
    meanTemp = meanTemp && meanTemp / 24.0;

    let maxTemp = tempData && Math.max(...tempData.map(x => x.value));
    let minTemp = tempData && Math.min(...tempData.map(x => x.value));

    let plotOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
          padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
          }
      },
      animation: {
        duration: 0
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          display: false
        },
        ],
        xAxes: [{
          display: false
        }],
      }
    };

    let weatherDates = tempData && tempData.map(x => moment(x.date.toDate()).format("HH")).reverse();

    let plotDataLast24hrs = {
      labels: weatherDates,
      datasets: [
        {
          label: "Temperatur (grader)",
          data: tempData && tempData.map(x => x.value).reverse(),
          fill: false,
          borderColor: "#D6946B",
          pointRadius: 0
        },
      ]
    };

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
      condition ? wrapper(children) : children;

    return (
      <ConditionalWrapper
        condition={!this.props.renderAsInnerComponent}
        wrapper={children => <Segment style={{ padding: "2em", paddingBottom: "0.5em" }}>{children}</Segment>}
      >
        <Fragment>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            <div style={{ width: "40%" }}>
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  textAlign: "center",
                  fontSize: "120%",
                  color: "grey",
                  width: "100%",
                }}
              >
                Medeltemperatur
            </p>
              <p
                style={{
                  fontWeight: 600,
                  margin: 0,
                  textAlign: "center",
                  fontSize: "300%",
                  width: "100%",
                }}
              >
                {meanTemp && meanTemp.toFixed(1).replace(".", ",")}
                <span
                  style={{
                    fontWeight: "normal",
                    color: "grey",
                    fontSize: "50%",
                    marginLeft: 5,
                  }}
                >
                  &deg;C
                </span>                    
              </p>
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  textAlign: "center",
                  color: "grey",
                  width: "100%",
                }}
              >
                under senaste dygnet
            </p>
            </div>

            <div style={{ width: "60%" }}>
              <article
                className="canvas-container"
                style={{
                  marginLeft: "2em",
                  alignContent: "center",
                  width: "80%",
                  height: "80%",
                }}
              >
                <Line
                  data={plotDataLast24hrs}
                  options={plotOptions}
                />
              </article>
            </div>


          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              marginTop: "1.5em",
              marginBottom: 0,
              fontWeight: 600,
              fontSize: "160%",
            }}
          >
            <div style={{ width: "33%" }}>
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  color: "grey",
                  width: "100%",
                  fontSize: "90%",
                }}
              >
                Lägst
            </p>
              <p style={{ marginBottom: 10, marginTop: 10, width: "100%", fontSize: "110%",}}>
                {minTemp && minTemp.toFixed(1).replace(".", ",")}
                  <span
                    style={{
                      fontWeight: "normal",
                      color: "grey",
                      fontSize: "80%",
                    }}
                  >
                    &deg;C
                  </span>                
              </p>
            </div>

            <div style={{ width: "33%" }}>
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  textAlign: "center",
                  color: "grey",
                  width: "100%",
                  fontSize: "90%",
                }}
              >
                Högst
            </p>
              <p style={{ marginBottom: 10, marginTop: 10, fontSize: "110%", }}>
                {maxTemp && maxTemp.toFixed(1).replace(".", ",")}
                <span
                  style={{
                    fontWeight: "normal",
                    color: "grey",
                    fontSize: "80%",
                  }}
                >
                  &deg;C
                </span>                
              </p>
            </div>

            <div style={{ width: "33%" }}>
              <p
                style={{
                  fontWeight: "normal",
                  margin: 0,
                  width: "100%",
                  color: "grey",
                  fontSize: "90%",
                }}
              >
                Nederbörd
            </p>
              <p style={{ marginBottom: 10, marginTop: 10, fontSize: "110%", }}>
                {totalPrec && totalPrec.toFixed(1).replace(".", ",")}

                <span
                  style={{
                    fontWeight: "normal",
                    color: "grey",
                    fontSize: "75%",
                  }}
                >
                {" mm"}
                </span>
              </p>
            </div>
          </div>
        </Fragment>

      </ConditionalWrapper>
    )
  }
}

export default WeatherDailySummaryWidget;
