import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import {
  firestoreConnect,
  isLoaded,
  isEmpty
} from "react-redux-firebase";

import LoaderModal from "@/components/modals/LoaderModal";
import NotificationsList from "@/components/notifications/NotificationsList";

const INITIAL_STATE = {};

class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  render() {
    const {
      match: {
        params: { notificationId }
      },
    } = this.props;

    const db = this.getDb();
    let hasLoadedData = isLoaded(db) && !isEmpty(db) && isLoaded(db.notifications);

    let fileBucketRef = this.props.firebase.storage().ref();

    if (hasLoadedData) {
      return (
        <Page>
          <div
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <NotificationsList 
              fileBucketRef={fileBucketRef}
              userId={this.props.userId}
              db={db}
              getUser={this.getUser}
              floating={true}
              caption={"Händelser"}
              view={["newData", "newFields"]}
              notificationId={notificationId}
              // toggleShowMultipleFieldsModal={this.toggleShowMultipleFieldsModal}          
            />
          </div>
        </Page>
      );
    } else {
      return (
        <LoaderModal caption="Laddar..." />
      );
    }
  }
}

const withFirestoreData = connect(store => {
  return {
    firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "fields" },
      ],
      storeAs: 'fields'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "guides" },
      ],
      storeAs: 'guides'
    },
    { 
      collection: 'users', 
      doc: `${props.userId}`, 
      subcollections: [
        { collection: "notifications" },
      ],
      storeAs: 'notifications'
    },
  ]),
  withFirestoreData,
  withRouter
)(NotificationPage);