import React, { Component } from 'react';

import { 
  Button,
} from "semantic-ui-react";

class Fab extends Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  };

  render () {
    return (
      <Button 
        style={{
          position: "absolute",
          left: this.props.position === "left" ? 10 : null,
          right: this.props.position === "right" ? 10 : null,
          margin: 0,
          bottom: 70,
          zIndex: 999,
          ...this.props.style
        }}
        size="massive"
        icon={this.props.icon}
        primary={this.props.primary}
        positive={this.props.positive}
        negative={this.props.negative}
        circular
        disabled={this.props.disabled}
        onClick={() => this.props.onClick()}
      />
    )
  };
};

export default Fab;