import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container, Header, Icon } from 'semantic-ui-react'

import privacyText from './user-terms.json';
import usertermsText from './user-terms.json';
import thirdPartyText from './third-party-licenses.json'

const INITIAL_STATE = {
  thirdPartyShowList: [-1],
};

class TermsList extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  onClickHeader = (key) => {
    if (this.props.mode !== 'third-party-licences') {
      return null;
    };

    const currentValue = this.state.thirdPartyShowList;
    let newValue = [];

    if (currentValue && currentValue.includes(key)) {
      newValue = currentValue.filter((x) => x !== key);
    } else {
      newValue = [...currentValue, key];
    };

    this.setState({thirdPartyShowList: newValue});
  };

  getLicenceDetails = (info) => (
    <div>
      <li>Publisher: {info.publisher}</li>
      <li>E-mail: {info.email}</li>
      <li>Homepage: {info.url}</li>
      <li>Licence: {info.licenses}</li>
      <br />
      {info.licenseText}
    </div>
  );

  render () {
    const {
      thirdPartyShowList 
    } = this.state;

    const {
      mode
    } = this.props;

    var modes = {
      'privacy':
      {
        header: 'Integritetspolicy',
        textObject: privacyText,
        showAll: true,
      },
      'user-terms':
      {
        header: 'Användarvillkor',
        textObject: usertermsText,
        showAll: true,
      },   
      'third-party-licences':
      {
        header: 'Tredjepartslicenser',
        textObject: thirdPartyText,
        showAll: false,
      },            
    }

    return (
      <Container style={{ marginTop: '75px', paddingBottom: '7em' }}>
        <React.Fragment>
        {Object.keys(modes[mode].textObject).map((key, index) => {
          return (
            <div 
              key={key} 
              style={{ marginTop: '2em' }}
            >
              <Header 
                icon
                as='h4'
                onClick={() => this.onClickHeader(index)}
                style={{
                  display: "block",
                  textAlign: "left",
                }}
              >
                {key}
              </Header>

              {modes[mode].showAll && modes[mode].textObject[key].content}
              {thirdPartyShowList.includes(index) && this.getLicenceDetails(modes[mode].textObject[key])}
              {(index !== 0 && mode === 'third-party-licences' && !thirdPartyShowList.includes(index)) &&
                <Icon 
                  name='chevron down' 
                  style={{
                    position: 'absolute',
                    right: '1em'
                  }}
                />
              }
            </div>
          )
        })}
        </React.Fragment>
      </Container>
    );
  }
}


TermsList.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default TermsList;
