import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import Fab from "@/components/misc/Fab";
import MenuTop from "@/components/menus/MenuTop";

import GetTagLocationMap from '@/components/geotags/GuideElements/Location';
import GetGeoTagType from '@/components/geotags/GuideElements/Type';
import GetGeoTagAmountRadius from '@/components/geotags/GuideElements/AmountRadius';
import GetGeoTagNotes from '@/components/geotags/GuideElements/Notes';
import GetGeoTagSummary from '@/components/geotags/GuideElements/Summary'

import { GEOTAG_TYPES } from "@/constants/geotags";
import { geoPointToPosition } from "@/helpers/db";

const STEP_TITLES = {
  selectMapLocation: 'välj position',
  selectMainType: 'välj typ',
  selectSubType: 'välj undertyp',
  selectAmount: 'välj mängd',
  selectRadius: 'välj radie',
  addNotesPhotos: 'ange information',
};

const INITIAL_STATE = {
  newTag: {},
};

export class AddGeoTagGuide extends Component {
  
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidUpdate = (prevProps) => {
    const {
      db,
      tagId,
      guideMode,
    } = this.props;

    if (guideMode === "editObservation" && db && prevProps.db && db.observations !== prevProps.db.observations ) {
      if (this.state.newTag && Object.keys(this.state.newTag).length === 0) {
        let currentValue = db && db.observations && db.observations[tagId];
        let currentEntry = currentValue && currentValue.entries && currentValue.entries.length > 0 && currentValue.entries[0];

        currentEntry && this.setState({
          newTag: {
            header: currentValue.header ? currentValue.header : '',
            amount: currentEntry.amount ? currentEntry.amount : '',
            note: currentEntry.note ? currentEntry.note : '',
            radius: currentEntry.radius ? currentEntry.radius : '',
            position: currentValue.position && geoPointToPosition(currentValue.position),
            mainType: currentValue.tag_main_type,
            subType: currentValue.tag_sub_type,
            photos: currentValue.photos ? currentValue.photos : [],
            reminders: currentValue.reminders ? currentValue.reminders : [],
          }
        });
      };
    };
  };

  updateTag = (payload, gotoNextStep=false) => {
    let currentNewTag = this.state.newTag;
    this.setState({ newTag: {...currentNewTag, ...payload} });
    
    gotoNextStep && this.onClickNextStep();
  };

  onSaveNewTag = (imagesToUpload, imagesToRemove) => {
    const {
      tagId,
      guideMode,
    } = this.props;

    const {
      newTag,
    } = this.state;

    if (guideMode === "editObservation") {
      this.props.updateGeoTag(tagId, newTag, imagesToUpload, imagesToRemove);
    } else {
      this.props.saveNewGeoTag(newTag, imagesToUpload);
    }
  };

  onClickPreviousStep = () => this.props.history.goBack();

  onClickNextStep = () => {
    const {
      addStep,
      guideMode,
      tagId,
    } = this.props;
    
    const {
      newTag,
    } = this.state;

    let selectedMainType = newTag && newTag.mainType;
    let selectedMainTypeInfo = selectedMainType && GEOTAG_TYPES && GEOTAG_TYPES[selectedMainType];

    let newStep;

    switch (addStep) {
      case 'selectMapLocation':
        newStep = "selectMainType";
        break;

      case 'selectMainType':
        if (selectedMainType === "custom") {
          newStep = "addNotesPhotos";
        } else {
          newStep = "selectSubType";
        };
        break;

      case 'selectSubType':
        newStep = "selectAmount";
        break;
      
      case 'selectAmount':
        let hasRadius = selectedMainTypeInfo.radius;
        newStep = hasRadius ? "selectRadius" : "addNotesPhotos";
        break;

      case 'selectRadius':
        newStep = "addNotesPhotos";
        break;

      default: 
        newStep = "selectMainType";
        break;
    };

    newStep && this.props.history.push("/add/" + guideMode + "/" + tagId + "/" + newStep);
  };

  render() {
    const {
      db,
      fileBucketRef,
      addStep,
      guideMode,
    } = this.props;
    
    const { 
      newTag,
    } = this.state;

    let showSummary = newTag && newTag.mainType;
    showSummary = (!addStep || addStep === "selectMapLocation") ? false : showSummary;
    showSummary = (addStep === "selectMainType" && newTag && !newTag.subType) ? false : showSummary;

    let forwardDisabled = true;

    let pageContent;

    switch (addStep) {
      case 'selectMapLocation':
        forwardDisabled= !newTag.position;

        pageContent = 
          <GetTagLocationMap 
            db={db}
            newTag={newTag}
            updateTag={this.updateTag}
          />;
        break;

      case 'selectMainType':
        forwardDisabled= !newTag.mainType;

        pageContent = 
          <GetGeoTagType
            newTag={newTag}
            updateTag={this.updateTag}
            marginTop={showSummary ? 180 : 70}
          />;
        break;

      case 'selectSubType':
        forwardDisabled= !newTag.subType;

        pageContent = 
          <GetGeoTagType
            isSelectingSubType={true}
            updateTag={this.updateTag}
            newTag={newTag}
            marginTop={180}
          />;
        break;
      
      case 'selectAmount':
        forwardDisabled= !newTag.amount;

        pageContent = 
          <GetGeoTagAmountRadius
            selectType="amount"
            updateTag={this.updateTag}
            newTag={newTag}
            marginTop={180}
          />;
        break;

      case 'selectRadius':
        forwardDisabled= !newTag.radius;

        pageContent = 
          <GetGeoTagAmountRadius 
            selectType="radius"
            updateTag={this.updateTag}
            newTag={newTag}
            marginTop={180}
          />;
        break;

      case 'addNotesPhotos':
        forwardDisabled= !newTag.mainType;

        pageContent = 
          <GetGeoTagNotes 
            marginTop={180}
            newTag={newTag}
            guideMode={guideMode}
            fileBucketRef={fileBucketRef}
            updateTag={this.updateTag}
            onSaveNewTag={this.onSaveNewTag}
        />;
        break;

      default: 
        forwardDisabled= !newTag.position;

        pageContent = 
          <GetTagLocationMap 
            db={db}
            newTag={newTag}
            updateTag={this.updateTag}
          />;
        break;
    };

    return (
      <React.Fragment>      
        <MenuTop 
          caption={(guideMode === "editObservation" ? "Redigera observation: " : "Spara observation: ") + (addStep ? STEP_TITLES[addStep] : STEP_TITLES['selectMapLocation'])} 
          onClickGoBack={() => this.props.history.replace("/")} 
        />

        {showSummary &&
          <GetGeoTagSummary
            newTag={newTag}
          />
        }

        {pageContent}

        {addStep && addStep !== "selectMapLocation" &&
          <Fab 
            position="left"
            icon="chevron left"
            onClick={() => this.onClickPreviousStep()}
          />
        }          

        <Fab 
          primary
          position="right"
          disabled={forwardDisabled}
          icon="chevron right"
          onClick={() => this.onClickNextStep()}
        />          
    </React.Fragment>
  );
}};

export default withRouter(AddGeoTagGuide);


