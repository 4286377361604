import React from "react";

import Page from "@/components/misc/Page";

import { 
  Dimmer, 
  Loader, 
} from "semantic-ui-react";

const LoaderModal = ({ caption }) => {
  return (
    <Page>
      <Dimmer active inverted>
        <Loader 
          active
          indeterminate
          size="massive"
          style={{ 
            paddingBottom: "8em" 
          }} 
        />
        <div
            style={{
              fontSize: "100%", 
              marginTop: "0.5em",
              color: "grey",
            }}
          >
          {caption}
        </div>
      </Dimmer>       
    </Page>
  )
};

export default LoaderModal;