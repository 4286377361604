import React, { Component } from "react";

import {
  Image,
} from "semantic-ui-react";

import moment from 'moment';

import sunandcloud from '@/assets/icons/weather/sunandcloud.png';
import cloud from '@/assets/icons/weather/cloud.png';
import cloudrain from '@/assets/icons/weather/cloudrain.png';
import heavyrain from '@/assets/icons/weather/heavyrain.png';
import snow from '@/assets/icons/weather/snow.png';
import sun from '@/assets/icons/weather/sun.png';
import bolt from '@/assets/icons/weather/bolt.png';


const INITIAL_STATE = {};

class ForecastData extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render() {
    const {
      db,
      forecastOffset
    } = this.props;


    const dayIntToString = {
      0: "Söndag",
      1: "Måndag",
      2: "Tisdag",
      3: "Onsdag",
      4: "Torsdag",
      5: "Fredag",
      6: "Lördag"
    }

    const dayIntToRelativeString = {
      0: "Idag",
      1: "Imorgon"
    }
    let forecastDay;

    if (this.props.relativeDate && forecastOffset < 2) {
      forecastDay = dayIntToRelativeString[forecastOffset]
    } else {
      const forecastDateRaw = moment().add(forecastOffset, 'days')
      forecastDay = dayIntToString[forecastDateRaw.day()]
    }
    const forecastDate = moment().add(forecastOffset, 'days').format('YYYY-MM-DD')


    let forecastData =
      db &&
      db.fieldsWeather &&
      db.fieldsWeather.forecasts &&
      db.fieldsWeather.forecasts[forecastDate];

    let totalPrec =
      forecastData &&
      forecastData.rain

    let maxTemp =
      forecastData &&
      forecastData.max_temp

    let minTemp =
      forecastData &&
      forecastData.min_temp

    let weatherSymbol =
      forecastData &&
      forecastData.symbol

    let weatherIcon = symbolToPicture[weatherSymbol]

    let forecastTempData =
      forecastData &&
      forecastData["interpolated_forecast_temps"]

    var items = forecastTempData && Object.keys(forecastTempData).map(function (date) {
      return [date, forecastTempData[date]];
    });
    if (forecastTempData) items.sort((a, b) => new Date(a[0]) - new Date(b[0]))

    return (
      <div 
        style={{
          textAlign: "center",
          marginTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
          fontWeight: 600,
          fontSize: "85%",
          display: "block",
        }}
      >
        <p
          style={{
            fontWeight: 500,
            color: "grey",
            marginBottom: "0.75em"
          }}
        >
          {forecastDay}
        </p>
        
        <div style={{ height: "4em"}} >
          {weatherIcon &&
            <Image 
              src={weatherIcon} 
              style={{
                width: "2.5em", 
                height: "auto", 
                filter: "brightness(0%) invert(75%) sepia(13%) saturate(895%) hue-rotate(353deg) brightness(102%) contrast(87%)",
                marginBottom: 5,
                marginLeft: "auto",
                marginRight: "auto",
              }} 
            />
          }
        </div>

          <div
            style={{
              fontWeight: "heavy",
              margin: 0,
              fontSize: "75%",
              color: "grey",
              width: "100%",
            }}
          >
            {maxTemp && Math.round(maxTemp)}°C / {minTemp && Math.round(minTemp)}°C
          </div>

          <div
            style={{
              fontWeight: "normal",
              margin: 0,
              marginTop: 5,
              fontSize: "75%",
              color: "grey",
              width: "100%",
            }}
          >
            {totalPrec && Math.round(totalPrec)} mm
          </div>

        {/* <div 
          style={{ 
            marginTop: "1em",
            marginBottom: 0, 
            paddingBottom: 0 
          }}
        >
          <article
            className="canvas-container"
            style={{
              alignContent: "center",
              width: "100%",
              height: "4.2em"
            }}
          >
            <Line
              data={plotData}
              options={plotOptions}
            />
          </article>
        </div> */}
      </div>
    )
  }
}

const symbolToPicture = {
  1: sun,
  2: sun,
  3: cloud,
  4: cloud,
  5: cloud,
  6: cloud,
  7: cloud,
  8: sunandcloud,
  9: cloudrain,
  10: heavyrain,
  11: bolt,
  12: cloud,
  13: cloudrain,
  14: heavyrain,
  15: snow,
  16: snow,
  17: snow,
  18: cloudrain,
  19: cloudrain,
  20: heavyrain,
  21: bolt,
  22: cloudrain,
  23: snow,
  24: snow,
  25: snow,
  26: snow,
  27: snow
}

export default ForecastData;
