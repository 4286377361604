import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import Page from "@/components/misc/Page";

import {
  firestoreConnect,
} from "react-redux-firebase";

import {
  Form,
  Image,
  Button,
  Header,
  Loader,
} from "semantic-ui-react";

import doneIllustration from "@/assets/illustrations/done.png";

import { cloudFunctions } from "@";

import MenuTop from "@/components/menus/MenuTop";
// import WidgetMenu from "@/components/menus/WidgetMenu";

import "./style.css";

class ContactSupportPage extends React.Component {
  state = {
  open: false,
  note: "",
  errorMessage: "",
  processingSubmit: false,
  activeView: "email",
  messageSent: false,
  };

  getDb = () => this.props.firestoreData;
  getUser = () => this.props.firestore.collection("users").doc(this.props.userId);

  sendMessage = message => {
  const {
    userId,
  } = this.props;

  let db = this.getDb();

  this.setState({ processingSubmit: true });
  
  let email =
    db &&
    db.profile &&
    db.profile.contact &&
    db.profile.contact.email &&
    db.profile.contact.email;

  let firstName =
    db &&
    db.profile &&
    db.profile.contact &&
    db.profile.contact.first_name &&
    db.profile.contact.first_name;

  let lastName =
    db &&
    db.profile &&
    db.profile.contact &&
    db.profile.contact.last_name &&
    db.profile.contact.last_name;

  let farmName = 
    db &&
    db.profile &&
    db.profile.farm &&
    db.profile.farm.name &&
    db.profile.farm.name;

  let emailAddress = (email && firstName && lastName) ? firstName + ' ' + lastName + ' <' + email + '>' : null;
  emailAddress = emailAddress ? emailAddress : this.props.userId + "@agriopt.se";
  
  let longerMessage = "Ett nytt supportärende har inkommit för användaren: " + userId + ".\n\n\n";
  longerMessage = longerMessage + "==============================\n";
  longerMessage = longerMessage + "Supportinformation:\n";
  longerMessage = longerMessage + "==============================\n\n";
  longerMessage = longerMessage + "Användare: " + firstName + " " + lastName + "\n";
  longerMessage = longerMessage + "Gård: " + farmName + "\n\n";
  longerMessage = longerMessage + "userId: " + userId + "\n";
  longerMessage = longerMessage + "\n\n==============================\n";
  longerMessage = longerMessage + "Meddelande från kund:\n";
  longerMessage = longerMessage + "==============================\n\n";
  longerMessage = longerMessage + message;

  cloudFunctions.httpsCallable('sendSupportEmail')(
    {
    email: emailAddress,
    subject: 'Supportärende från Freja',
    message: longerMessage
    }
  )
    .then(x => {
    console.log("Feedback sent");

    this.setState({ 
      processingSubmit: false, 
      messageSent: true,
      note: "",
    });
    
    })
    .catch(error => {
    console.log("Sending feedback failed...");
    console.log(error);

    this.setState({ 
      processingSubmit: false, 
    });
    });
  };

  onChangeText = (event) => {
  this.setState({ [event.target.name]: event.target.value });
  };

  onClickSend = () => {
  if (this.state.note) {
    this.setState({ errorMessage: "" });
    this.sendMessage( this.state.note );
  } else {
    this.setState({
    errorMessage: "Vänligen ange ett meddelande."
    });
  }
  };

  handleItemClick = (item) => this.setState({ activeView: item });

  onClickGoBack = () => {
    this.props.history.replace("/more");
  };

  render() {
  const { 
    note, 
    errorMessage,
    processingSubmit,
    activeView,
    messageSent,
  } = this.state;

  // let menuItems = [
  //   {
  //   id: 'email',
  //   caption: "E-post",
  //   active: activeView === 'email',
  //   onClick: this.handleItemClick,
  //   },
  //   {
  //   id: 'phone',
  //   caption: "Telefon",
  //   active: activeView === 'phone',
  //   onClick: this.handleItemClick,     
  //   disabled: true,
  //   },       
  // ];  

  if (messageSent) {
    return (  
    <Page>
      <MenuTop caption="Kontakta support" onClickGoBack={this.onClickGoBack} />

      <div
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          marginLeft: "auto",
          marginRight: "auto",
          color: "black",
        }}
      >
        <Image 
          style={{
            marginTop: "8em",            
            height: "150px",
            marginBottom: "1.5em",
          }}      
          centered 
          src={doneIllustration}       
        />

        <div
          style={{
            marginLeft: "15%",
            width: "70%",
            textAlign: "center",
            fontSize: "120%", 
            marginTop: "3em",
            color: "grey",
          }}
          >
          Tack, ditt meddelande har skickats till teamet bakom Freja. Vi kommer återkomma inom kort.
        </div>        
      </div>  
    </Page>  
    );
  }

  if (!messageSent) {
  return (  
    <Page>
    <MenuTop caption="Kontakta support" onClickGoBack={this.onClickGoBack} />

    <div style= {{ marginTop: "70px" }} />

    {/* <WidgetMenu menuItems={menuItems} /> */}

    <div
      style={{
        marginTop: "2em",
        marginBottom: "1em",
        marginLeft: "auto",
        marginRight: "auto",
        color: "black",
      }}
    >

      {!processingSubmit &&
        <p
          style={{
            width: "75%",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          Skriv ditt meddelande i rutan nedanför så kontaktar vi dig så snart vi kan via e-post eller telefon. 
        </p>
      }

      {!processingSubmit && activeView === "email" &&
        <React.Fragment>
          <Form
            size="large"
            style={{ 
              marginTop: "1em",
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Form.TextArea  
              rows={15}
              name="note"
              placeholder="Hur kan vi hjälpa dig?"
              onChange={e => this.onChangeText(e)}
              value={note}
            />

            {errorMessage &&
              <p
                style={{ 
                  marginTop: "1em",
                  marginBottom: "1em",
                  color: "#D6946B", 
                }}
              >
                {errorMessage}
              </p>
            }      
                        
            <Button
              fluid
              onClick={() => this.onClickSend()}
              style={{ 
              backgroundColor: "#6B9D9F",
              color: "white",       
              marginTop: "2em",     
              }}
              size="large"
            >
              Skicka meddelande
            </Button>           
          </Form>
        </React.Fragment>
      }

      {!processingSubmit && activeView === "phone" &&
        <React.Fragment>
          <Header>013 - 10 30 10 </Header>          

        </React.Fragment>
      }        

      {processingSubmit &&
        <Loader 
          active 
          indeterminate 
          size="massive"
          style={{
            marginTop: "4em",
          }}
        >
          <div
            style={{
              fontSize: "80%", 
              marginTop: "0.5em",
              color: "grey",
            }}
            >
              Skickar meddelande...
          </div>
        </Loader>      
        }       
      </div>  
    </Page>  
    );
  }
  }
}


const withFirestoreData = connect(store => {
  return {
  firestoreData: store.firestore.data
  };
});

export default compose(
  firestoreConnect(props => [
  { 
    collection: 'users', 
    doc: `${props.userId}`, 
    subcollections: [
    { collection: "profile" },
    ],
    storeAs: 'profile'
  },  
  ]),
  withFirestoreData,
  withRouter
)(ContactSupportPage);
