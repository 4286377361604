import React, { Component } from "react";

import { 
  Icon,
  Segment,
  Header,
  Button,
  Dropdown,
  Message,
} from "semantic-ui-react";

import moment from 'moment';

import { VRA_MAP_TYPES } from "./constants";

moment.locale("sv");

const INITIAL_STATE = {
  selectedVraMapType: '',
  tryingToGenerateAgain: [],
};
class GuideDownloadFiles extends Component {

  state = { ...INITIAL_STATE };

  downloadFile = (filePath) => {
    const {
      fileBucketRef,
    } = this.props;

    filePath && fileBucketRef.child(filePath).getDownloadURL().then((url) => {
      fetch(url)
        .then(async res => ({
            filename: filePath.split("/").slice(-1)[0],
            blob: await res.blob()
        }))
        .then(resObj => {
            // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
  
            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
  
                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();
  
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        })
        .catch((error) => {
            console.log('DOWNLOAD ERROR', error);
        });
    });
  };

  onChange = (data) => {
    this.setState({ [data.name]: data.value })
  };

  onChangeVraMapType = (d) => {
    this.onChange(d);

    let dbRef = this.props.getUser();
    dbRef.collection("settings").doc("guides").set({ terminal_file_type: d.value }, { merge: true });
  };

  render () {
    const {
      db,
      guide,
      currentRationNo,
      compoundUnit,
      skippedVraMapsForFields,
    } = this.props;

    let rationString = typeof(currentRationNo) === "number" ? currentRationNo.toFixed(0) : "1";

    let settingsGuides = db && db.settings && db.settings.guides;
    let selectedVraMapType = settingsGuides && settingsGuides.terminal_file_type ? settingsGuides.terminal_file_type : 'agco';
    selectedVraMapType = this.state.selectedVraMapType ? this.state.selectedVraMapType : selectedVraMapType;

    let selectedFieldId = guide && guide.field_id && guide.field_id;
    let selectedFieldIds = guide && guide.field_ids && guide.field_ids;
    selectedFieldIds = selectedFieldIds ? selectedFieldIds : [ selectedFieldId ];

    let downloadableFile = guide && guide.downloadable_file && guide.downloadable_file;  
    let downloadableFiles = guide && guide["downloadable_files_" + rationString] && guide["downloadable_files_" + rationString];
    downloadableFiles = downloadableFiles ? downloadableFiles : [ downloadableFile ];

    let vraFileStats = guide && guide["approved_files_stats_" + rationString];

    let errorMessages = guide && guide.errors_downloadable_files;
    let hasErrorMessages = errorMessages && Object.keys(errorMessages).find((x) => errorMessages[x]);

    let selectVraMapType = VRA_MAP_TYPES && VRA_MAP_TYPES.map((x) => ({
      key: x.key,
      value: x.key,
      text: x.caption,
    }));

    let selectedVraMapTypeData = VRA_MAP_TYPES && selectedVraMapType && VRA_MAP_TYPES.find((x) => x.key === selectedVraMapType);

    let preCaption = " för grundgiva";
    preCaption = currentRationNo && currentRationNo > 0 ? " för kompletteringsgiva " + currentRationNo : preCaption;
      
    return (
      <React.Fragment>
          <Header 
            as="h2"
            style={{
              fontWeight: 500,
            }}
          >
            Styrfiler {preCaption && preCaption}
          </Header>          

          <Segment
            secondary
            style={{
              marginTop: "2em",
              marginBottom: "2em",
              padding: "1em",            
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Header 
              as="h4"
              style={{
                height: "100%",
                width: "50%",
                marginTop: "auto",
                marginBottom: "auto",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Terminaltillverkare
            </Header>          

            <Dropdown
              style={{
                width: "30%",
                marginRight: "10%",
              }}
              name="selectedVraMapType"
              onChange={(e, d) => this.onChangeVraMapType(d)}
              value={selectedVraMapType}
              options={selectVraMapType}
            />  
          </Segment>    

          {/* {this.getAllFieldsRow(downloadableFiles, vraFileStats, selectedVraMapTypeData)} */}
              
            {selectedVraMapType && selectedFieldIds && selectedFieldIds
              .filter((y) => !skippedVraMapsForFields || !skippedVraMapsForFields.includes(y))
              .filter((y) => !errorMessages || !errorMessages[y])
              .map((y) => {

              let fieldName = db && y && db.fields && db.fields[y] && db.fields[y].name;

              let fieldDownloadableFiles = downloadableFiles && downloadableFiles[y];

              let fieldStats = vraFileStats && vraFileStats[y];
              let fieldMeanRation = fieldStats && fieldStats.mean && parseFloat(fieldStats.mean);

              let meanRation = '';
              meanRation = fieldMeanRation && fieldMeanRation >= 1000 ? (Math.floor(fieldMeanRation / 10) * 10).toLocaleString('sv') : meanRation;
              meanRation = fieldMeanRation && fieldMeanRation < 1000 ? fieldMeanRation.toFixed(0) : meanRation;

              let fieldTotalRation = fieldStats && fieldStats.totalRation && parseFloat(fieldStats.totalRation);

              let totalRation = ''
              totalRation = (fieldTotalRation && fieldTotalRation >= 1000.0) ? (Math.floor(fieldTotalRation / 10) * 10).toLocaleString('sv') : totalRation;
              totalRation = (fieldTotalRation && fieldTotalRation < 1000.0) ? fieldTotalRation.toFixed(0) : totalRation;
              
              let fieldSavedTotalRation = fieldStats && fieldStats.savedTotalRation && parseFloat(fieldStats.savedTotalRation);
              
              let savedRation = '';
              savedRation = (typeof(fieldSavedTotalRation) === "number" && fieldSavedTotalRation >= 1000.0) ? (Math.floor(fieldSavedTotalRation / 10) * 10).toLocaleString('sv') : savedRation;
              savedRation = (typeof(fieldSavedTotalRation) === "number" && fieldSavedTotalRation < 1000.0) ? fieldSavedTotalRation.toFixed(0) : savedRation;

              return (
                <Segment
                  style={{
                    display: "block",
                  }}
                  key={"vraMapTypeTable_" + y}
                >
                  <Header 
                    as="h2"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {fieldName && fieldName}
                  </Header>

                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    <strong>{selectedVraMapTypeData.key === "yara" ? "Målgiva: " : "Snittgiva: "}</strong>
                    {meanRation && meanRation + " " + compoundUnit}
                  </p>
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    <strong>Total giva: </strong>
                    {totalRation && totalRation} kg
                  </p>
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    <strong>Sparad giva: </strong>
                    {savedRation && savedRation} kg
                  </p>                                    
                  

                  {selectedVraMapTypeData && selectedVraMapTypeData.files && selectedVraMapTypeData.files.map((x) => {

                    let filePath = fieldDownloadableFiles && fieldDownloadableFiles[x.url_name];

                    return (
                      <div
                        key={"vraMapTypeTableCell_" + y + "_" + x.url_name}
                      >
                        {filePath &&
                            <Button
                              primary
                              fluid
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "1em",
                              }}
                              onClick={() => this.downloadFile(filePath)}
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              Hämta {x.caption}
                            </Button>                              
                        }
                        {!filePath && <Icon loading name="circle notched" />}
                      </div>
                    )
                  })}                    
                </Segment>
              )
            })
          }

        {selectVraMapType && hasErrorMessages &&
          <Segment
            style={{
              padding: "2em",            
            }}
          >
            <Message
              style={{
                padding: "2em",
                whiteSpace: "pre-line",
                backgroundColor: "#C36126",
                color: "white",
              }}
              icon='exclamation triangle'
              header='Styrfilerna kunde inte skapas för ett eller fler skiften'
              content={"Freja hade tyvärr problem med att skapa styrfiler för ett eller fler skiften. Supporten har redan meddelats detta problem och kommer kolla upp och höra av sig till dig. Om du vill så kan du prova att skapa ett förslag igen."}
            />

            {selectedFieldIds && selectedFieldIds
              .filter((y) => !skippedVraMapsForFields || !skippedVraMapsForFields.includes(y))
              .filter((y) => errorMessages && errorMessages[y])
              .map((y) => {

                let fieldName = db && y && db.fields && db.fields[y] && db.fields[y].name;
                
                return (
                  <Message
                    style={{
                      padding: "1.5em",
                      whiteSpace: "pre-line",
                    }}
                    size="tiny"
                  >
                    <Message.Header>{'Felmeddelande för skiftet ' + fieldName}</Message.Header>
                    <Message.Content>
                      <p>{errorMessages && errorMessages[y]}</p>
                      <Button
                        primary
                        loading={this.state.tryingToGenerateAgain.includes(y)}
                        onClick={() => this.onClickTryGenerateAgain(y)}
                      >
                        Försök igen
                      </Button>
                    </Message.Content>
                  </Message>
                )
              })
            }
          </Segment>
        }
      </React.Fragment>
    )
  }
}

export default GuideDownloadFiles;