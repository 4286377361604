import React, { Component } from "react";

import { 
  Button,
  Modal,
} from "semantic-ui-react";

import { DateInput } from 'semantic-ui-calendar-react';

const INITIAL_STATE = {
  modalOpen: false,
  selectedDate: null,
};

class AddReminderButton extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  saveReminder = () => {
    const {
      type,
      reminderType,
    } = this.props;

    let payload = {
      complete: false,
      condition: {
        type: 'date',
        first_date: this.state.selectedDate,
      },
      type: type ? type : '',
      reminder_type: reminderType ? reminderType : '',
      field_id: '',
      field_name: '',
      observation_type: '',
      observation_id: '',
      date: new Date(),
    };

    this.props.updateTag({ reminder: payload });
    this.setState({ modalOpen: false });
  };

  render() {
    const {
      bigButton,
      inputIcon,
      disabled, 
    } = this.props;
    
    return (
      <React.Fragment>
        <Modal
          open={this.state.modalOpen}
        >
          <Modal.Header
            style={{
              fontWeight: 500,
            }}
          >
            Välj ett datum för din påminnelse
          </Modal.Header>

          <Modal.Content>
            <DateInput
              inline
              name='date'
              dateFormat="YYYY-MM-DD"
              value={this.state.selectedDate}
              markColor="yellow"
              onChange={(e, d) => this.setState({ selectedDate: d.value })}
              localization='sv'
            />
          </Modal.Content>

          <Modal.Actions
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button 
              onClick={() => this.setState({ modalOpen: true })}
            >
              Avbryt
            </Button>

            <Button 
              primary 
              onClick={() => this.saveReminder()} 
              disabled={!this.state.selectedDate}
            >
              Spara
            </Button>
          </Modal.Actions>
        </Modal>

        {bigButton &&
          <Button 
            primary
            fluid
            size="large"
            icon="calendar plus outline"
            labelPosition="left"
            content="Lägg till en påminnelse"
            disabled={disabled}
            onClick={() => this.setState({ modalOpen: true })}
          />
        }

        {inputIcon &&
          <Button
            basic
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: 7,
            }}
            icon='calendar plus outline' 
            disabled={disabled}
            onClick={() => this.setState({ modalOpen: true })}
          />
        }
      </React.Fragment>
    );
  };
};



export default AddReminderButton;