/**
 *
 * Logo
 *
 */

import React from 'react';
import styled from 'styled-components';

import LogoColorImage from '@/assets/logos/color.png';
import LogoColorNoTextImage from '@/assets/logos/color_notext.png';
import LogoWhiteImage from '@/assets/logos/white.png';
import LogoWhiteNoTextImage from '@/assets/logos/white_notext.png';

const Img = styled.img`
  margin-top: -4px;
  margin-right: 5px;
  width: 24px;
`;

const LogoContainer = styled.div`
  &.large {
    text-align: center;
    padding: 10%;
    font-size: 20px;
    img {
      width: auto;
      display: block;
      margin: 20px auto 10px;
    }
  }

  &.adapted {
    text-align: center;
    padding: 5%;
    img {
      width: 12em;
      display: block;
      margin: 5% auto 0px;
    }
  }

  &.small{
    padding: 2%;
    img {
      width: 7em;
    }
  }

`;

function LogoColor({ showImage, adapted }) {
  return (
    // css
    <LogoContainer className={adapted && 'adapted'}>
      {showImage && <Img src={LogoColorImage} alt="logo" />}
    </LogoContainer>
  );
}

function LogoColorNoText({ showImage, adapted }) {
  return (
    // css
    <LogoContainer className={adapted && 'adapted'}>
      {showImage && <Img src={LogoColorNoTextImage} alt="logo" />}
    </LogoContainer>
  );
}


function LogoWhite({ showImage, adapted }) {
  return (
    // css
    <LogoContainer className={adapted && 'adapted'}>
      {showImage && <Img src={LogoWhiteImage} alt="logo" />}
    </LogoContainer>
  );
}

function LogoWhiteNoText({showImage, adapted}){
  return(
    // css
    <LogoContainer className={adapted && 'adapted'}>
      {showImage && <Img src={LogoWhiteNoTextImage} alt="logo" />}
    </LogoContainer>
  );
}

export default LogoColor;
export { LogoColor, LogoColorNoText, LogoWhite, LogoWhiteNoText };
