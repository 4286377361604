import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { 
  Form, 
  TextArea,
  Input,
  Icon,
  Header,
  Segment,
} from 'semantic-ui-react';

import moment from 'moment';

import Fab from "@/components/misc/Fab";
import MenuTop from "@/components/menus/MenuTop";

import AddReminderButton from "@/components/reminders/AddReminderButton";

const INITIAL_STATE = {
  header: '',
  note: '',
};

export class AddGeoTagGuide extends Component {
  
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };

  componentDidUpdate = (prevProps) => {
    const {
      db,
      reminderId,
      guideMode,
    } = this.props;

    if (guideMode === "editReminder" && db && prevProps.db && db.reminders !== prevProps.db.reminders ) {
      if (!this.state.header) {
        let currentValue = db && db.reminders && db.reminders[reminderId];
        currentValue && this.setState(currentValue);
      };
    };
  };
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSaveNewReminder = () => {
    const {
      reminderId,
      guideMode,
    } = this.props;

    if (guideMode === "editReminder") {
      this.props.updateReminder(this.state, reminderId);
    } else {
      this.props.saveNewReminder(this.state);
    }
  };

  onClickPreviousStep = () => this.props.history.goBack();

  getNewReminderSegment = () => {
    const {
      condition,
    } = this.state;

    let reminderDate = condition && condition.first_date && moment(condition.first_date).format("YYYY-MM-DD");
  
    return (
      <Segment
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        Freja kommer att påminna dig den {reminderDate && reminderDate}.

        <div
          style={{
            width: "1em",
            fontSize: "80%"
          }}
        >
          <Icon 
            style={{
              cursor: "pointer",
            }}
            name="close"
            onClick={() => this.setState({ condition: null })}
          />
        </div>
      </Segment>
    );
  };    

  render() {
    const {
      guideMode,
    } = this.props;
    
    const { 
      header,
      note,
      condition,
    } = this.state;

    return (
      <React.Fragment>      
        <MenuTop 
          caption={guideMode === "editReminder" ? "Redigera en aktivitet" : "Lägg till en aktivitet"} 
          onClickGoBack={() => this.props.history.goBack()} 
        />

        <div 
          style={{ 
            padding: 0,
            margin: 0,
            marginTop: 70,
          }} 
        >
          <Header
            as="h3"
            style={{
              fontWeight: 500,
            }}
          >
            Rubrik
          </Header>

          <Form  
            style={{
              width: '100%',
            }}
          >
            <Input
              fluid
              name='header'
              placeholder='Skriv en valfri rubrik...'
              onChange={e => this.onChange(e)}
              value={header ? header : ''}
            />
          </Form>

          <Header
            as="h3"
            style={{
              fontWeight: 500,
            }}
          >
            Anteckning (frivilligt)
          </Header>

          <Form  
            style={{
              width: '100%',
            }}
          >
            <TextArea
              style={{
                fontSize: '12pt',
              }}
              rows={4}
              name='note'
              placeholder='Skriv en valfri anteckning...'
              onChange={e => this.onChange(e)}
              value={note ? note : ''}
            />
          </Form>        
              
          <Header
            as="h3"
            style={{
              fontWeight: 500,
            }}          
          >
            Datum för påminnelse
          </Header>

          {condition && condition.first_date && this.getNewReminderSegment()}

          {(!condition || !condition.first_date) &&
            <AddReminderButton 
              bigButton
              type='userReminder'
              reminderType='user'
              updateTag={(newState) => this.setState({...newState.reminder})}
            />
          }
        </div>

        <Fab 
          primary
          position="right"
          disabled={!header || !condition || !condition.first_date}
          icon="chevron right"
          onClick={() => this.onSaveNewReminder()}
        />          
    </React.Fragment>
  );
}};

export default withRouter(AddGeoTagGuide);


